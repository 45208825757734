import { Grid, TextField, Typography } from '@material-ui/core'

import { ClinicalHistoryData } from '../interfaces'
import React from 'react'
import ValidatedTextField from '../components/ValidatedInput'
import { useTranslation } from 'react-i18next'

interface PatientDataProps {
  data: ClinicalHistoryData
  handleFieldChange: (field: keyof ClinicalHistoryData, value: any) => void
  disabled: boolean
}

const PatientData: React.FC<PatientDataProps> = ({
  data,
  handleFieldChange,
  disabled,
}) => {
  const { t } = useTranslation();

  const unitWeight = 'kg'; // @TODO: get from data service

  const translations = {
    title: t('clinicalHistory.patientTitle'),
    labelSize: t('clinicalHistory.labelSize'),
    placeholderSize: t('clinicalHistory.placeholderSize'),
    labelWeight: t('clinicalHistory.labelWeight', { unit: unitWeight }),
    placeholderWeight: t('clinicalHistory.placeholderWeight'),
    IMC: t('clinicalHistory.IMC'),
    labelPulseMin: t('technicalfile.pulse'),
    placeholderPulse: t('schedule.finish.system.dialog.placeHolder.pulse'),
    labelATMin: t('schedule.finish.system.dialog.arterialTensionMin'),
    placeholderATMin: t('schedule.finish.system.dialog.placeHolder.arterialTensionMin'),
    labelATMax: t('schedule.finish.system.dialog.arterialTensionMax'),
    placeholderATMax: t('schedule.finish.system.dialog.placeHolder.arterialTensionMax'),
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} className="step-title">
        <Typography variant="h6">
          {translations?.title}
        </Typography>
      </Grid>
      <Grid item container xs={12} spacing={3} className="content-form">
        <Grid item xs={12} sm={6} md={6}>
          <ValidatedTextField
            label={translations?.labelWeight}
            placeholder={translations?.placeholderWeight}
            value={data.weightValue ?? ''}
            onChange={(value) => handleFieldChange('weightValue', value)}
            type="number"
            min={0}
            max={600}
            variant="outlined"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <ValidatedTextField
            label={translations?.labelSize}
            placeholder={translations?.placeholderSize}
            value={data.heightValue ?? ''}
            onChange={(value) => handleFieldChange('heightValue', value)}
            type="number"
            min={0}
            max={2500}
            variant="outlined"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={translations?.IMC}
            fullWidth
            placeholder={translations?.IMC}
            disabled={true}
            error={false}
            value={data.imc ?? ''}
            margin="none"
            variant="outlined"
            inputProps={{
              style: {
                fontSize: 16,
                touchAction: 'manipulation',
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ValidatedTextField
            label={translations?.labelPulseMin}
            placeholder={translations?.placeholderPulse}
            value={data.pulse}
            onChange={(value) => handleFieldChange('pulse', value)}
            type="number"
            min={45}
            max={200}
            variant="outlined"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <ValidatedTextField
            label={translations?.labelATMax}
            placeholder={translations?.placeholderATMax}
            value={data.bloodPressureMax}
            onChange={(value) => handleFieldChange('bloodPressureMax', value)}
            type="number"
            min={80}
            max={250}
            variant="outlined"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <ValidatedTextField
            label={translations?.labelATMin}
            placeholder={translations?.placeholderATMin}
            value={data.bloodPressureMin}
            onChange={(value) => handleFieldChange('bloodPressureMin', value)}
            type="number"
            min={40}
            max={170}
            variant="outlined"
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PatientData

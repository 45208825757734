import './HospitalizationStyles.css'

import React from 'react'

import FormDischarge from './Forms/FormDischarge'
import FormFinancial from './Forms/FormFinancial'
import FormHospitalization from './Forms/FormHospitalization'
import FormPatients from './Forms/FormPatients'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import RegularButton from '../CustomButtons/Button.jsx'
import Snackbar from '../Snackbar/Snackbar'

import { useHospitalizationFormView } from './hooks/useHospitalizationFormView'

const HospitalizationFormView = (props) => {
  const {validateForm,dataHospitalization,goToAdmissionList,dataDischargeEdit,setValidateFrequencyError,setDataDischargeForm,setIsValidatedDischargeForm,showDischargeForm,dataHospitalizationEdit,setIsValidatedHospitalizationForm, setDataHospitalizationtForm,validationShowDischargeForm,personId,setIsValidatedFinanceForm,setDataFinanceForm,colorSnackBar, messageSnackBar, showSnackBar, setDataPatientForm, setIsValidatedPatientForm, setPersonId, dataPatientEdit, id} = useHospitalizationFormView(props)
  return (
    <form>
      <Snackbar
        color={colorSnackBar}
        message={messageSnackBar}
        open={showSnackBar}
        place="tc"
      />
      <GridContainer>
        <GridItem md={6}>
          <FormPatients
            setDataPatientForm={setDataPatientForm}
            setIsValidatedPatientForm={setIsValidatedPatientForm}
            setPersonId={setPersonId}
            editData={dataPatientEdit}
            onlyView={true}
            id={id}
          ></FormPatients>
        </GridItem>
        <GridItem md={6}>
          <FormFinancial
            setDataFinanceForm={setDataFinanceForm}
            setIsValidatedFinanceForm={setIsValidatedFinanceForm}
            personId={personId}
            onlyView={true}
          ></FormFinancial>
        </GridItem>
        <GridItem md={12}>
          <FormHospitalization
            validationShowDischargeForm={validationShowDischargeForm}
            setDataHospitalizationForm={setDataHospitalizationtForm}
            setIsValidatedHospitalizationForm={
              setIsValidatedHospitalizationForm
            }
            editData={dataHospitalizationEdit}
            onlyView={true}
          ></FormHospitalization>
        </GridItem>
        {showDischargeForm && (
          <GridItem md={12}>
            <FormDischarge
              setIsValidatedDischargeForm={setIsValidatedDischargeForm}
              SetDataDischargeForm={setDataDischargeForm}
              setFrequencyError={setValidateFrequencyError}
              editData={dataDischargeEdit}
              onlyView={true}
            ></FormDischarge>
          </GridItem>
        )}
      </GridContainer>
      <GridContainer md={12}>
        <div className="buttons-container">
          <div>
            <RegularButton onClick={goToAdmissionList}>Volver</RegularButton>
          </div>
          <div className="button-submit-container">   
            <RegularButton
              authority="hospitalization_new"
              disabled={
                dataHospitalization &&
                !dataHospitalization.hospitalizationNewsList
              }
              onClick={validateForm}
              color="info"
            >
              GUARDAR CAMBIOS
            </RegularButton>
          </div>
        </div>
      </GridContainer>
    </form>
  )
}

export default HospitalizationFormView

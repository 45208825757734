import { useEffect, useState } from 'react'
import HozpitalizationApiInvoker from '../../../api/HospitalizationApiInvoker'
import { browserHistory } from 'react-router'
import React from 'react'
import { Tooltip } from '@material-ui/core'
import Button from '../../CustomButtons/Button'
import { Assignment, Edit } from '@material-ui/icons'
import { DeleteDocument } from '../../../icons'

export const useHospitalizationList = () => {
  const [data, setData] = useState([])
  const [axaInternmentId, setAxaInternmentId] = useState<any>(null)
  const [axaId, setAxaId] = useState(null)
  const [state, setState] = useState(null)
  const [admissionDate, setAdmissionDate] = useState(null)
  const [egressDate, setEgressDate] = useState(null)
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [colorSnackBar, setColorSnackBar] = useState<string | null>(null)
  const [messageSnackBar, setMessageSnackBar] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [filterable, setFilterable] = useState(true)

  const addInternment = () => browserHistory.push('/admission/new-form')

  useEffect(() => {
    getDataAxaInternment()
    window.scrollTo(0, 0)
  }, [axaInternmentId, axaId, state, egressDate, admissionDate, filterable])

  const filterSearchClear = () => {
    setAxaInternmentId(null)
    setAxaId(null)
    setState(null)
    setAdmissionDate(null)
    setEgressDate(null)
    setFilterable(false)
    getDataAxaInternment()
  }

  const filterSearch = () => {
    let internmentId
    let affiliateId
    let internmentStatus
    let egress_date
    let admission_date

    if (axaInternmentId) {
      internmentId = `internmentId=${axaInternmentId}`
    }
    if (axaId) {
      affiliateId = `&&affiliateId=${axaId}`
    }
    if (state) {
      internmentStatus = `&&internmentStatus=${state}`
    }
    if (egressDate) {
      egress_date = `&&egress_date=${egressDate}`
    }
    if (admissionDate) {
      admission_date = `&&admission_date=${admissionDate}`
    }
    let params = `${internmentId ? internmentId : ''}${
      affiliateId ? affiliateId : ''
    }${internmentStatus ? internmentStatus : ''}${
      egress_date ? egress_date : ''
    }${admission_date ? admission_date : ''}`

    HozpitalizationApiInvoker.filterListData(params, (response: any) => {
      setData(formatDataToTable(response))
    })
  }

  const optionState = [
    {
      id: 'CREATED',
      value: 'En Proceso',
    },
    {
      id: 'CANCELLED',
      value: 'Cancelada',
    },
    {
      id: 'ENDED',
      value: 'Finalizada',
    },
  ]

  const formatDataToTable = (response: any) => {
    let data: any = []
    response.map((internment: any) => {
      let dataInternment = {
        axa_internment_id: internment.axaInternmentId,
        axa_id: internment.affiliateId ? internment.affiliateId : '',
        admission_date: internment.admission_date,
        patient: `${internment.patientId.firstName} ${internment.patientId.lastName}`,
        egress_date: internment.egress_date,
        state:
          internment.internmentStatus == 'CREATED'
            ? 'En Proceso'
            : internment.internmentStatus == 'CANCELLED'
            ? 'Cancelada'
            : internment.internmentStatus == 'ENDED'
            ? 'Finalizada'
            : 'Sin Estado',
        actions: renderActionComponent(
          internment.axaInternmentId,
          internment.internmentStatus
        ),
      }
      data.push(dataInternment)
    })
    return data
  }

  const renderActionComponent = (id: any, state: any) => {
    return (
      <div className="appointments-actions">
        <Tooltip title={'Ver'}>
          <span>
            <Button
              simple
              justIcon
              color="info"
              authority="hospitalization_view"
              data-testid="ver-button"
              onClick={() => goToView(id)}
            >
              <Assignment />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={'Editar'}>
          <div>
            <Button
              simple
              justIcon
              disabled={state === 'CANCELLED' || state === 'ENDED'}
              color="success"
              authority="hospitalization_edit"
              onClick={() => goToEdit(id)}
              data-testid="edit-button"
            >
              <Edit />
            </Button>
          </div>
        </Tooltip>
        <Tooltip title={'Cancelar'}>
          <div>
            <Button
              simple
              justIcon
              disabled={state === 'CANCELLED' || state === 'ENDED'}
              color="danger"
              authority="hospitalization_cancel"
              onClick={() => goToCancel(id)}
              data-testid="cancel-button"
            >
              <DeleteDocument className="svg-cancel-agenda" />
            </Button>
          </div>
        </Tooltip>
      </div>
    )
  }

  const goToEdit = (id: any) => {
    browserHistory.push(`/admission/edit-form/${id}`)
  }

  const goToView = (id: any) => {
    browserHistory.push(`/admission/view/${id}`)
  }

  const setDataToCancel = (internment: any) => {
    let dataToSend = {
      userId: window.localStorage.getItem('user_id'),
      axaInternmentId: internment.axaInternmentId,
      internmentStatus: 'CANCELLED',
      patientId: {
        personId: parseInt(internment.patientId.personId),
      },
      axaServicesCenterId: {
        axaServicesCenterId: internment.axaServicesCenterId
          ? internment.axaServicesCenterId.axaServicesCenterId
          : null,
      },
      room: internment.room ? internment.room : null,
      therapy: internment.therapy ? internment.therapy : null,
      admission_date: internment.admission_date
        ? internment.admission_date
        : null,
      egress_date: internment.egress_date ? internment.egress_date : null,
      treatingMedicalsId: {
        treatingMedicalsId: internment.treatingMedicalsId
          ? internment.treatingMedicalsId.treatingMedicalsId
          : null,
      },
      historyPathologyList: internment.historyPathologyList
        ? internment.historyPathologyList
        : null,
      admissionPathologyList: internment.admissionPathologyList
        ? internment.admissionPathologyList
        : null,
      dischargePathologyList: internment.dischargePathologyList
        ? internment.dischargePathologyList
        : null,
      barthelIndexId: internment.barthelIndexId
        ? internment.barthelIndexId.recordId
        : null,
      hospitalizationNewsList: internment.hospitalizationNewsList
        ? internment.hospitalizationNewsList
        : null,
      dischargePlaceId: internment.dischargePlaceId
        ? internment.dischargePlaceId.dischargePlaceId
        : null,
      nursing: internment.nursing ? internment.nursing : null,
      nursingFrequency: internment.nursingFrequency
        ? internment.nursingFrequency
        : null,
      kinesiology: internment.kinesiology ? internment.kinesiology : null,
      kinesiology_frequency: internment.kinesiology_frequency
        ? internment.kinesiology_frequency
        : null,
      speechTherapy: internment.speechTherapy ? internment.speechTherapy : null,
      speechTherapy_frequency: internment.speechTherapy_frequency
        ? internment.speechTherapy_frequency
        : null,
      occupationalTherapy: internment.occupationalTherapy
        ? internment.occupationalTherapy
        : null,
      occupationalTherapy_frequency: internment.occupationalTherapy
        ? internment.occupationalTherapy
        : null,
      healing: internment.healing ? internment.healing : null,
      healing_frequency: internment.healing_frequency
        ? internment.healing_frequency
        : null,
      dressingsTypeId: internment.dressingsTypeId
        ? internment.dressingsTypeId.dressingsTypeId
        : null,
      wheelchair: internment.wheelchair ? internment.wheelchair : null,
      orthopedic_bed: internment.orthopedic_bed
        ? internment.orthopedic_bed
        : null,
      walkerno: internment.walkerno ? internment.walkerno : null,
      oxygen_tube: internment.oxygen_tube ? internment.oxygen_tube : null,
      oxygen_concentrator: internment.oxygen_concentrator
        ? internment.oxygen_concentrator
        : null,
      medicineList: internment.medicineList ? internment.medicineList : null,
    }
    return dataToSend
  }

  const goToCancel = (id: any) => {
    HozpitalizationApiInvoker.getAxaInternmentById(id, (response: any) => {
      HozpitalizationApiInvoker.updateAxaInternment(
        setDataToCancel(response[0]),
        (response: any) => {
          setShowSnackBar(true)
          setColorSnackBar('success')
          setMessageSnackBar('La hospitalización se canceló exitosamente')
          setLoading(false)
          setTimeout(function () {
            setShowSnackBar(false)
            getDataAxaInternment()
          }, 2000)
        },
        (error: any) => {
          setShowSnackBar(true)
          setColorSnackBar('danger')
          setMessageSnackBar(
            'Hubo un error al intentar cancelar la internación.'
          )
          setLoading(false)
          setTimeout(function () {
            setShowSnackBar(false)
          }, 5000)
        }
      )
    })
  }

  const getDataAxaInternment = () => {
    HozpitalizationApiInvoker.getAxaInternment((response: any) => {
      setData(formatDataToTable(response))
      setFilterable(true)
    })
  }

  return {
    filterSearchClear,
    filterSearch,
    setEgressDate,
    setAdmissionDate,
    setState,
    setAxaId,
    setAxaInternmentId,
    addInternment,
    renderActionComponent,
    egressDate,
    data,
    loading,
    filterable,
    admissionDate,
    state,
    optionState,
    axaId,
    colorSnackBar,
    messageSnackBar,
    showSnackBar,
    axaInternmentId,
  }
}

import { Theme, makeStyles } from '@material-ui/core/styles';
import { grayColor, primaryColor, whiteColor } from '../../assets/components/material-dashboard-pro-react';

export const useStyles = makeStyles<Theme>((theme) => ({
  gridContainer: {
    padding: theme.spacing(2),
    margin: 'auto',
    color: grayColor[6],
    maxWidth: 500,
    minHeight: 'calc(100vh - 15vh)',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: whiteColor,
    '& .step-title': {
      marginBottom: theme.spacing(4),
      textAlign: 'center',
      width: '100%',
    },
    '& .content-form': {
      paddingBottom: theme.spacing(2),
      '& .step': {
        padding: theme.spacing(2),
        borderRadius: 5,
        border: `1px solid ${grayColor[3]}`,
        marginBottom: theme.spacing(2),
        '& .content-collapse': {
          width: '100%',
        },
        '& .collapse-item': {
          borderTop: `1px solid ${grayColor[3]}`,
          marginTop: theme.spacing(2),
          paddingTop: theme.spacing(1),
        },
      },
      '& .button-edit-medicine': {
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  closeButton: {
    backgroundColor: 'transparent',
    border: 'none',
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(6),
    cursor: 'pointer',
  },
  closeIcon: () => ({
    color: primaryColor[0],
    fontSize: 24,
  }),
  header: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(7),
    textAlign: 'center',
  },
  title: {
    fontSize: '1.7rem',
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    color: grayColor[6],
  },
  gridItemCentered: {
    flex: 1,
    paddingBottom: theme.spacing(12),

  },
  contentCircularProgress: {
    paddingTop: theme.spacing(5),
  },
  modalMessage: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  buttonContainerModal: {
    backgroundColor: whiteColor,
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    zIndex: 10,
  },

  buttonAccept: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    '&:hover, &:focus': {
      backgroundColor: primaryColor[0],
      boxShadow: '0 14px 26px -12px rgba(93, 196, 185, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(93, 196, 185, 0.2)',
    },
    padding: theme.spacing(1.5, 3),
    borderRadius: '5px',
    fontWeight: 600,
    fontSize: '1rem',
  },
  buttonCancel: {
    color: primaryColor[0],
    fontWeight: 500,
    '&:hover, &:focus': {
      opacity: 0.8,
    },
    padding: theme.spacing(1.5, 3),
    borderRadius: '5px',
    fontSize: '1rem',
  },
}));
import '../../../DiabeticProgramStyles.css'
import CustomInput from '../../../../CustomInput/CustomInput'
import DateInput from '../../../../DateInput'
import GridItem from '../../../../Grid/GridItem'
import RadioInput from '../../../../RadioInput'
import React from 'react'
import { useEffect } from 'react'

const Genitourinary = (props) => {
  const { setData, setValidateRGenitourinarySection, editData, onlyView } =
    props

  const [incontinence, setIncontinence] = React.useState('no')
  const [neurogenicBladder, setNeurogenicBladder] = React.useState('no')
  const [acuteRenalFailure, setAcuteRenalFailure] = React.useState('no')
  const [acuteRenalFailureDate, setAcuteRenalFailureDate] = React.useState(null)
  const [chronicRenalInsufficiency, setChronicRenalInsufficiency] =
    React.useState('no')
  const [chronicRenalInsufficiencyDate, setChronicRenalInsufficiencyDate] =
    React.useState(null)
  const [urinaryInfections, setUrinaryInfections] = React.useState('no')
  const [urinaryInfectionsDate, setUrinaryInfectionsDate] = React.useState(null)
  const [prostateCancer, setProstateCancer] = React.useState('no')
  const [prostateCancerDate, setProstateCancerDate] = React.useState(null)
  const [breastCancer, setBreastCancer] = React.useState('no')
  const [breastCancerDate, setBreastCancerDate] = React.useState(null)
  const [genitourinaryComments, setGenitourinaryComments] = React.useState(null)

  const FormatData = () => {
    let data = {
      incontinence: incontinence === 'yes' ? true : false,
      neurogenicBladder: neurogenicBladder === 'yes' ? true : false,
      acuteRenalFailure: acuteRenalFailure === 'yes' ? true : false,
      acuteRenalFailureDate: acuteRenalFailureDate,
      chronicRenalInsufficiency:
        chronicRenalInsufficiency === 'yes' ? true : false,
      chronicRenalInsufficiencyDate: chronicRenalInsufficiencyDate,
      urinaryInfections: urinaryInfections === 'yes' ? true : false,
      urinaryInfectionsDate: urinaryInfectionsDate,
      prostateCancer: prostateCancer === 'yes' ? true : false,
      prostateCancerDate: prostateCancerDate,
      breastCancer: breastCancer === 'yes' ? true : false,
      breastCancerDate: breastCancerDate,
      genitourinaryComments: genitourinaryComments,
    }
    setData(data)
  }

  const validateSection = () => {
    if (
      (acuteRenalFailure === 'yes' && !acuteRenalFailureDate) ||
      (chronicRenalInsufficiency === 'yes' && !chronicRenalInsufficiencyDate) ||
      (urinaryInfections === 'yes' && !urinaryInfectionsDate) ||
      (prostateCancer === 'yes' && !prostateCancerDate) ||
      (breastCancer === 'yes' && !breastCancerDate)
    ) {
      setValidateRGenitourinarySection(false)
    } else {
      setValidateRGenitourinarySection(true)
    }
  }

  let genitourinaryCommentsLenth = genitourinaryComments
    ? genitourinaryComments.length
    : 0

  const setEditData = (data) => {
    setIncontinence(data.incontinence === true ? 'yes' : 'no')
    setNeurogenicBladder(data.neurogenicBladder === true ? 'yes' : 'no')
    setAcuteRenalFailure(data.acuteRenalFailure === true ? 'yes' : 'no')
    setAcuteRenalFailureDate(data.acuteRenalFailureDate)
    setChronicRenalInsufficiency(
      data.chronicRenalInsufficiency === true ? 'yes' : 'no'
    )
    setChronicRenalInsufficiencyDate(data.chronicRenalInsufficiencyDate)
    setUrinaryInfections(data.urinaryInfections === true ? 'yes' : 'no')
    setUrinaryInfectionsDate(data.urinaryInfectionsDate)
    setProstateCancer(data.prostateCancer === true ? 'yes' : 'no')
    setProstateCancerDate(data.prostateCancerDate)
    setBreastCancer(data.breastCancer === true ? 'yes' : 'no')
    setBreastCancerDate(data.breastCancerDate)
    setGenitourinaryComments(data.genitourinaryComments)
  }

  useEffect(() => {
    if (editData) {
      setEditData(editData);
    }
  }, [editData]); 
  
  useEffect(() => {
    validateSection();
    FormatData();
  }, [
    incontinence,
    neurogenicBladder,
    acuteRenalFailure,
    acuteRenalFailureDate,
    chronicRenalInsufficiency,
    chronicRenalInsufficiencyDate,
    urinaryInfections,
    urinaryInfectionsDate,
    prostateCancer,
    prostateCancerDate,
    breastCancer,
    breastCancerDate,
    genitourinaryComments,
  ]);

  return (
    <>
      <GridItem md={12}>
        <h4>Genito urinario</h4>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Incontinencia:</p>
          <RadioInput
            value={incontinence}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setIncontinence(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Vejiga neurogénica:</p>
          <RadioInput
            value={neurogenicBladder}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setNeurogenicBladder(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <h5>Antecedentes</h5>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Insuficiencia renal aguda:</p>
          <RadioInput
            value={acuteRenalFailure}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setAcuteRenalFailureDate(null)
                setAcuteRenalFailure(value)
              } else {
                setAcuteRenalFailure(value)
              }
            }}
          />
          {acuteRenalFailure === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setAcuteRenalFailureDate(value)}
              value={acuteRenalFailureDate}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
              disabled={onlyView}
              error={acuteRenalFailure === 'yes' && !acuteRenalFailureDate}
              errorText={
                acuteRenalFailure === 'yes' && !acuteRenalFailureDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">
            Insuficiencia renal crónica:
          </p>
          <RadioInput
            value={chronicRenalInsufficiency}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setChronicRenalInsufficiencyDate(null)
                setChronicRenalInsufficiency(value)
              } else {
                setChronicRenalInsufficiency(value)
              }
            }}
          />
          {chronicRenalInsufficiency === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setChronicRenalInsufficiencyDate(value)}
              value={chronicRenalInsufficiencyDate}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
              disabled={onlyView}
              error={
                chronicRenalInsufficiency === 'yes' &&
                !chronicRenalInsufficiencyDate
              }
              errorText={
                chronicRenalInsufficiency === 'yes' &&
                !chronicRenalInsufficiencyDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Infecciones urinarias:</p>
          <RadioInput
            value={urinaryInfections}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setUrinaryInfectionsDate(null)
                setUrinaryInfections(value)
              } else {
                setUrinaryInfections(value)
              }
            }}
          />
          {urinaryInfections === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setUrinaryInfectionsDate(value)}
              value={urinaryInfectionsDate}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
              disabled={onlyView}
              error={urinaryInfections === 'yes' && !urinaryInfectionsDate}
              errorText={
                urinaryInfections === 'yes' && !urinaryInfectionsDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Cáncer de próstata:</p>
          <RadioInput
            value={prostateCancer}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setProstateCancerDate(null)
                setProstateCancer(value)
              } else {
                setProstateCancer(value)
              }
            }}
          />
          {prostateCancer === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setProstateCancerDate(value)}
              value={prostateCancerDate}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
              disabled={onlyView}
              error={prostateCancer === 'yes' && !prostateCancerDate}
              errorText={
                prostateCancer === 'yes' && !prostateCancerDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Cáncer de mama:</p>
          <RadioInput
            value={breastCancer}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setBreastCancerDate(null)
                setBreastCancer(value)
              } else {
                setBreastCancer(value)
              }
            }}
          />
          {breastCancer === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setBreastCancerDate(value)}
              value={breastCancerDate}
              isRequired={true}
              inputProps={{ disabled: false }}
              disabled={onlyView}
              error={breastCancer === 'yes' && !breastCancerDate}
              errorText={
                breastCancer === 'yes' && !breastCancerDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <CustomInput
          value={genitourinaryComments ? genitourinaryComments : ''}
          labelText="Comentarios:"
          multiline={true}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            maxlength: '500',
            disabled: onlyView,
          }}
          helpText={`Hasta 500 caracteres. Te quedan ${
            500 - genitourinaryCommentsLenth
          } `}
          onChange={(e) => setGenitourinaryComments(e.target.value)}
        />
      </GridItem>
    </>
  )
}

export default Genitourinary

import { useEffect, useState } from 'react'
import DiabeticApiInvoker from '../../../api/DiabeticApiIvoker'
import { browserHistory } from 'react-router'


export const useDiabeticProgramForm = (props) => {
  const [personId, setPersonId] = useState(null)
  const [dataFinance, setDataFinance] = useState('')
  const [isValidatedAllForm, setIsValidatedAllForm] = useState(false)
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [loading, setLoading] = useState(false)
  const [colorSnackBar, setColorSnackBar] = useState(null)
  const [messageSnackBar, setMessageSnackBar] = useState(null)

  const [dataPatientEdit, setDataPatientEdit] = useState(null)
  const [patientDataEdit, setPatientDataEdit] = useState(null)
  const [physicalExamEdit, setPhysicalExamEdit] = useState(null)
  const [complementaryStudiesEdit, setComplementaryStudiesEdit] = useState(null)

  const [dataPatient, setDataPatient] = useState(null)
  const [dataPatientPhysical, setDataPatientPhysical] = useState(null)
  const [dataPhysicalExam, setDataPhysicalExam] = useState(null)
  const [dataComplementaryStudies, setDataComplementaryStudies] = useState(null)

  const [diabeticId, setDiabeticId] = useState(null)

  const [isValidatedPatientForm, setIsValidatedPatientForm] = useState(false)
  const [isValidatedFinanceForm, setIsValidatedFinanceForm] = useState(false)
  const [validateFormPatientData, setValidateFormPatientData] = useState(false)
  const [validatePhysicalExamForm, setvalidatePhysicalExamForm] =
    useState(false)
  const [
    validateComplementaryStudiesForm,
    setValidateComplementaryStudiesForm,
  ] = useState(false)

  const [save, setSave] = useState(false)

  let id = props.params.id

  const onSubmit = () => {
    setSave(true)
    if (isValidatedAllForm) {
      setSave(false)
      setLoading(true)
      let dataForm = setData()
      if (id) {
        DiabeticApiInvoker.updateDiabeticProgram(
          dataForm,
          (response) => {
            if (response) {
              setShowSnackBar(true)
              setColorSnackBar('success')
              setMessageSnackBar('Los cambios se guardaron exitosamente')
              setLoading(false)
              setTimeout(function () {
                setShowSnackBar(false)
                goToAdmissionList()
              }, 2000)
            }
          },
          (error) => {
            setShowSnackBar(true)
            setColorSnackBar('danger')
            setMessageSnackBar('Hubo un error al intentar guardar los cambios.')
            setLoading(false)
            setTimeout(function () {
              setShowSnackBar(false)
            }, 5000)
          }
        )
      } else {
        DiabeticApiInvoker.newDiabeticProgram(
          dataForm,
          (response) => {
            if (response) {
              setShowSnackBar(true)
              setColorSnackBar('success')
              setMessageSnackBar('Los cambios se guardaron exitosamente')
              setLoading(false)
              setTimeout(function () {
                setShowSnackBar(false)
                goToAdmissionList()
              }, 2000)
            }
          },
          (error) => {
            setShowSnackBar(true)
            setColorSnackBar('danger')
            setMessageSnackBar('Hubo un error al intentar guardar los cambios.')
            setLoading(false)
            setTimeout(function () {
              setShowSnackBar(false)
            }, 5000)
          }
        )
      }
    } else {
      setShowSnackBar(true)
      setColorSnackBar('warning')
      setMessageSnackBar(
        'Hay campos requeridos sin información. Complete todos los datos requeridos.'
      )
      setLoading(false)
      setTimeout(function () {
        setShowSnackBar(false)
      }, 3000)
    }
  }

  const goToAdmissionList = () => browserHistory.push('/diabetic-program')

  const setData = () => {
    let data = {
      diabeticsId: id ? diabeticId : '',
      createdUserId: parseInt(localStorage.getItem('user_id')),
      patientId: parseInt(dataPatient ? dataPatient.patient : null),
      height: dataPatientPhysical ? dataPatientPhysical.height : null,
      weight: dataPatientPhysical ? dataPatientPhysical.weight : null,
      imcId: dataPatientPhysical ? dataPatientPhysical.imcId : null,
      walkDaily: dataPatientPhysical ? dataPatientPhysical.walkDaily : null,
      eatSalt: dataPatientPhysical ? dataPatientPhysical.eatSalt : null,
      eatVegetables: dataPatientPhysical
        ? dataPatientPhysical.eatVegetables
        : null,
      eatFruits: dataPatientPhysical ? dataPatientPhysical.eatFruits : null,
      fluVaccine: dataPatientPhysical ? dataPatientPhysical.fluVaccine : null,
      fluVaccineDate: dataPatientPhysical
        ? dataPatientPhysical.fluVaccineDate
        : null,
      pneumococcusVaccine: dataPatientPhysical
        ? dataPatientPhysical.pneumococcusVaccine
        : null,
      pneumococcusVaccineDate: dataPatientPhysical
        ? dataPatientPhysical.pneumococcalVaccineDate
        : null,
      skinColorId: dataPhysicalExam ? dataPhysicalExam.skinColorId : null,
      skinInfections: dataPhysicalExam ? dataPhysicalExam.skinInfections : null,
      skinInfectionsDetail: dataPhysicalExam
        ? dataPhysicalExam.skinInfectionsDetail
        : null,
      skinLesions: dataPhysicalExam ? dataPhysicalExam.skinLesions : null,
      skinLesionsDetail: dataPhysicalExam
        ? dataPhysicalExam.skinLesionsDetail
        : null,
      skinUlcers: dataPhysicalExam ? dataPhysicalExam.skinUlcers : null,
      skinUlcersDetail: dataPhysicalExam
        ? dataPhysicalExam.skinUlcersDetail
        : null,
      dentalInfections: dataPhysicalExam
        ? dataPhysicalExam.dentalInfections
        : null,
      missingTeeth: dataPhysicalExam ? dataPhysicalExam.missingTeeth : null,
      dentures: dataPhysicalExam ? dataPhysicalExam.dentures : null,
      visualAcuityDisorder: dataPhysicalExam
        ? dataPhysicalExam.visualAcuityDisorder
        : null,
      glaucoma: dataPhysicalExam ? dataPhysicalExam.glaucoma : null,
      waterfalls: dataPhysicalExam ? dataPhysicalExam.waterfalls : null,
      retinopathy: dataPhysicalExam ? dataPhysicalExam.retinopathy : null,
      heartRate: dataPhysicalExam ? dataPhysicalExam.heartRate : null,
      bloodPressureA: dataPhysicalExam ? dataPhysicalExam.bloodPressureA : null,
      bloodPressureB: dataPhysicalExam ? dataPhysicalExam.bloodPressureB : null,
      peripheralPulses: dataPhysicalExam
        ? dataPhysicalExam.peripheralPulses
        : null,
      angina: dataPhysicalExam ? dataPhysicalExam.angina : null,
      anginaDate: dataPhysicalExam ? dataPhysicalExam.anginaDate : null,
      heartAttack: dataPhysicalExam ? dataPhysicalExam.heartAttack : null,
      heartAttackDate: dataPhysicalExam
        ? dataPhysicalExam.heartAttackDate
        : null,
      heartDisease: dataPhysicalExam ? dataPhysicalExam.heartDisease : null,
      heartDiseaseDate: dataPhysicalExam
        ? dataPhysicalExam.heartDiseaseDate
        : null,
      cardiovascularComments: dataPhysicalExam
        ? dataPhysicalExam.cardiovascularComments
        : null,
      paresis: dataPhysicalExam ? dataPhysicalExam.paresis : null,
      plegia: dataPhysicalExam ? dataPhysicalExam.plegia : null,
      atrophy: dataPhysicalExam ? dataPhysicalExam.atrophy : null,
      ataxia: dataPhysicalExam ? dataPhysicalExam.ataxia : null,
      amputation: dataPhysicalExam ? dataPhysicalExam.amputation : null,
      muscularStrength: dataPhysicalExam
        ? dataPhysicalExam.muscularStrength
        : null,
      hypesthesia: dataPhysicalExam ? dataPhysicalExam.hypesthesia : null,
      dementia: dataPhysicalExam ? dataPhysicalExam.dementia : null,
      apathy: dataPhysicalExam ? dataPhysicalExam.apathy : null,
      lessInterestOrPleasureId: dataPhysicalExam
        ? dataPhysicalExam.lessInterestOrPleasureId
        : null,
      feelingDepressedOrHopelessId: dataPhysicalExam
        ? dataPhysicalExam.feelingDepressedOrHopelessId
        : null,
      acv: dataPhysicalExam ? dataPhysicalExam.acv : null,
      acvDate: dataPhysicalExam ? dataPhysicalExam.acvDate : null,
      alzheimer: dataPhysicalExam ? dataPhysicalExam.alzheimer : null,
      alzheimerDate: dataPhysicalExam ? dataPhysicalExam.alzheimerDate : null,
      parkinson: dataPhysicalExam ? dataPhysicalExam.parkinson : null,
      parkinsonDate: dataPhysicalExam ? dataPhysicalExam.parkinsonDate : null,
      epilepsy: dataPhysicalExam ? dataPhysicalExam.epilepsy : null,
      epilepsyDate: dataPhysicalExam ? dataPhysicalExam.epilepsyDate : null,
      neurologyComments: dataPhysicalExam
        ? dataPhysicalExam.neurologyComments
        : null,
      respiratoryRate: dataPhysicalExam
        ? dataPhysicalExam.respiratoryRate
        : null,
      hemoptysis: dataPhysicalExam ? dataPhysicalExam.hemoptysis : null,
      cough: dataPhysicalExam ? dataPhysicalExam.cough : null,
      smoking: dataPhysicalExam ? dataPhysicalExam.smoking : null,
      asthma: dataPhysicalExam ? dataPhysicalExam.asthma : null,
      asthmaDate: dataPhysicalExam ? dataPhysicalExam.asthmaDate : null,
      pneumonia: dataPhysicalExam ? dataPhysicalExam.pneumonia : null,
      pneumoniaDate: dataPhysicalExam ? dataPhysicalExam.pneumoniaDate : null,
      chronicBronchitis: dataPhysicalExam
        ? dataPhysicalExam.chronicBronchitis
        : null,
      chronicBronchitisDate: dataPhysicalExam
        ? dataPhysicalExam.chronicBronchitisDate
        : null,
      lungCancer: dataPhysicalExam ? dataPhysicalExam.lungCancer : null,
      lungCancerDate: dataPhysicalExam ? dataPhysicalExam.lungCancerDate : null,
      commentsBreathing: dataPhysicalExam
        ? dataPhysicalExam.commentsBreathing
        : null,
      reflux: dataPhysicalExam ? dataPhysicalExam.reflux : null,
      esophagitis: dataPhysicalExam ? dataPhysicalExam.esophagitis : null,
      ulcers: dataPhysicalExam ? dataPhysicalExam.ulcers : null,
      irritableBowel: dataPhysicalExam ? dataPhysicalExam.irritableBowel : null,
      malabsorptionSyndrome: dataPhysicalExam
        ? dataPhysicalExam.malabsorptionSyndrome
        : null,
      colonCancer: dataPhysicalExam ? dataPhysicalExam.colonCancer : null,
      commentsDigestion: dataPhysicalExam
        ? dataPhysicalExam.commentsDigestion
        : null,
      incontinence: dataPhysicalExam ? dataPhysicalExam.incontinence : null,
      neurogenicBladder: dataPhysicalExam
        ? dataPhysicalExam.neurogenicBladder
        : null,
      acuteRenalFailure: dataPhysicalExam
        ? dataPhysicalExam.acuteRenalFailure
        : null,
      acuteRenalFailureDate: dataPhysicalExam
        ? dataPhysicalExam.acuteRenalFailureDate
        : null,
      chronicRenalInsufficiency: dataPhysicalExam
        ? dataPhysicalExam.chronicRenalInsufficiency
        : null,
      chronicRenalInsufficiencyDate: dataPhysicalExam
        ? dataPhysicalExam.chronicRenalInsufficiencyDate
        : null,
      urinaryInfections: dataPhysicalExam
        ? dataPhysicalExam.urinaryInfections
        : null,
      urinaryInfectionsDate: dataPhysicalExam
        ? dataPhysicalExam.urinaryInfectionsDate
        : null,
      prostateCancer: dataPhysicalExam ? dataPhysicalExam.prostateCancer : null,
      prostateCancerDate: dataPhysicalExam
        ? dataPhysicalExam.prostateCancerDate
        : null,
      breastCancer: dataPhysicalExam ? dataPhysicalExam.breastCancer : null,
      breastCancerDate: dataPhysicalExam
        ? dataPhysicalExam.breastCancerDate
        : null,
      genitourinaryComments: dataPhysicalExam
        ? dataPhysicalExam.genitourinaryComments
        : null,
      hypothyroidism: dataPhysicalExam ? dataPhysicalExam.hypothyroidism : null,
      hypothyroidismDate: dataPhysicalExam
        ? dataPhysicalExam.hypothyroidismDate
        : null,
      glycemiaId: dataComplementaryStudies
        ? dataComplementaryStudies.glycemiaId
        : null,
      glycosylatedHemoglobinId: dataComplementaryStudies
        ? dataComplementaryStudies.glycosylatedHemoglobinId
        : null,
      cholesterolId: dataComplementaryStudies
        ? dataComplementaryStudies.cholesterolId
        : null,
      triglyceridesId: dataComplementaryStudies
        ? dataComplementaryStudies.triglyceridesId
        : null,
      ureaId: dataComplementaryStudies ? dataComplementaryStudies.ureaId : null,
      creatinineId: dataComplementaryStudies
        ? dataComplementaryStudies.creatinineId
        : null,
      xrayThorax: dataComplementaryStudies
        ? dataComplementaryStudies.xRayThorax
        : null,
      xrayThoraxDate: dataComplementaryStudies
        ? dataComplementaryStudies.xRayThoraxDate
        : null,
      electrocardiogram: dataComplementaryStudies
        ? dataComplementaryStudies.electrocardiogram
        : null,
      electrocardiogramDate: dataComplementaryStudies
        ? dataComplementaryStudies.electrocardiogramDate
        : null,
      echocardiogram: dataComplementaryStudies
        ? dataComplementaryStudies.echocardiogram
        : null,
      echocardiogramDate: dataComplementaryStudies
        ? dataComplementaryStudies.echocardiogramDate
        : null,
      medicineList: dataComplementaryStudies
        ? dataComplementaryStudies.medicineList
        : null,
    }
    return data
  }

  const setEditData = (id) => {
    DiabeticApiInvoker.getDiabeticById(id, (response) => {
      setDiabeticId(response[0].diabeticsId)
      setDataPatientEdit({
        patient: response[0].patientId.personId,
        identification: response[0].patientId.id,
        direction: response[0].patientId.address.length
          ? response[0].patientId.address[0].addressId
          : null,
        zone: response[0].patientId.address.length
          ? response[0].patientId.address[0].geographicZone.detail
          : null,
        location: response[0].patientId.address.length
          ? response[0].patientId.address[0].location.name
          : null,
        province: response[0].patientId.address.length
          ? response[0].patientId.address[0].geographicZone.location.name
          : null,
        doctor: response[0].patientId.generalPractitionerId
          ? response[0].patientId.generalPractitionerId.generalPractitionerId
          : '',
        medicalRecord: response[0].patientId.generalPractitionerId
          ? response[0].patientId.generalPractitionerId
              .medicalRegistrationNumber
          : '',
      })
      setPatientDataEdit({
        height: response[0].height,
        weight: response[0].weight,
        imcId: response[0].imcId,
        walkDaily: response[0].walkDaily,
        eatSalt: response[0].eatSalt,
        eatVegetables: response[0].eatVegetables,
        eatFruits: response[0].eatFruits,
        fluVaccine: response[0].fluVaccine,
        fluVaccineDate: response[0].fluVaccineDate,
        pneumococcusVaccine: response[0].pneumococcusVaccine,
        pneumococcusVaccineDate: response[0].pneumococcusVaccineDate,
      })
      setPhysicalExamEdit({
        skinColorId: response[0].skinColorId,
        skinInfections: response[0].skinInfections,
        skinInfectionsDetail: response[0].skinInfectionsDetail,
        skinLesions: response[0].skinLesions,
        skinLesionsDetail: response[0].skinLesionsDetail,
        skinUlcers: response[0].skinUlcers,
        skinUlcersDetail: response[0].skinUlcersDetail,
        dentalInfections: response[0].dentalInfections,
        missingTeeth: response[0].missingTeeth,
        dentures: response[0].dentures,
        visualAcuityDisorder: response[0].visualAcuityDisorder,
        glaucoma: response[0].glaucoma,
        waterfalls: response[0].waterfalls,
        retinopathy: response[0].retinopathy,
        heartRate: response[0].heartRate,
        bloodPressureA: response[0].bloodPressureA,
        bloodPressureB: response[0].bloodPressureB,
        peripheralPulses: response[0].peripheralPulses,
        angina: response[0].angina,
        anginaDate: response[0].anginaDate,
        heartAttack: response[0].heartAttack,
        heartAttackDate: response[0].heartAttackDate,
        heartDisease: response[0].heartDisease,
        heartDiseaseDate: response[0].heartDiseaseDate,
        cardiovascularComments: response[0].cardiovascularComments,
        paresis: response[0].paresis,
        plegia: response[0].plegia,
        atrophy: response[0].atrophy,
        ataxia: response[0].ataxia,
        amputation: response[0].amputation,
        muscularStrength: response[0].muscularStrength,
        hypesthesia: response[0].hypesthesia,
        dementia: response[0].dementia,
        apathy: response[0].apathy,
        lessInterestOrPleasureId: response[0].lessInterestOrPleasureId,
        feelingDepressedOrHopelessId: response[0].feelingDepressedOrHopelessId,
        acv: response[0].acv,
        acvDate: response[0].acvDate,
        alzheimer: response[0].alzheimer,
        alzheimerDate: response[0].alzheimerDate,
        parkinson: response[0].parkinson,
        parkinsonDate: response[0].parkinsonDate,
        epilepsy: response[0].epilepsy,
        epilepsyDate: response[0].epilepsyDate,
        neurologyComments: response[0].neurologyComments,
        respiratoryRate: response[0].respiratoryRate,
        hemoptysis: response[0].hemoptysis,
        cough: response[0].cough,
        smoking: response[0].smoking,
        asthma: response[0].asthma,
        asthmaDate: response[0].asthmaDate,
        pneumonia: response[0].pneumonia,
        pneumoniaDate: response[0].pneumoniaDate,
        chronicBronchitis: response[0].chronicBronchitis,
        chronicBronchitisDate: response[0].chronicBronchitisDate,
        lungCancer: response[0].lungCancer,
        lungCancerDate: response[0].lungCancerDate,
        commentsBreathing: response[0].commentsBreathing,
        reflux: response[0].reflux,
        esophagitis: response[0].esophagitis,
        ulcers: response[0].ulcers,
        irritableBowel: response[0].irritableBowel,
        malabsorptionSyndrome: response[0].malabsorptionSyndrome,
        colonCancer: response[0].colonCancer,
        commentsDigestion: response[0].commentsDigestion,
        incontinence: response[0].incontinence,
        neurogenicBladder: response[0].neurogenicBladder,
        acuteRenalFailure: response[0].acuteRenalFailure,
        acuteRenalFailureDate: response[0].acuteRenalFailureDate,
        chronicRenalInsufficiency: response[0].chronicRenalInsufficiency,
        chronicRenalInsufficiencyDate:
          response[0].chronicRenalInsufficiencyDate,
        urinaryInfections: response[0].urinaryInfections,
        urinaryInfectionsDate: response[0].urinaryInfectionsDate,
        prostateCancer: response[0].prostateCancer,
        prostateCancerDate: response[0].prostateCancerDate,
        breastCancer: response[0].breastCancer,
        breastCancerDate: response[0].breastCancerDate,
        genitourinaryComments: response[0].genitourinaryComments,
        hypothyroidism: response[0].hypothyroidism,
        hypothyroidismDate: response[0].hypothyroidismDate,
      })
      setComplementaryStudiesEdit({
        glycemiaId: response[0].glycemiaId
          ? response[0].glycemiaId.laboratoryResultsId
          : null,
        glycosylatedHemoglobinId: response[0].glycosylatedHemoglobinId
          ? response[0].glycosylatedHemoglobinId.laboratoryResultsId
          : null,
        cholesterolId: response[0].cholesterolId
          ? response[0].cholesterolId.laboratoryResultsId
          : null,
        triglyceridesId: response[0].triglyceridesId
          ? response[0].triglyceridesId.laboratoryResultsId
          : null,
        ureaId: response[0].ureaId
          ? response[0].ureaId.laboratoryResultsId
          : null,
        creatinineId: response[0].creatinineId
          ? response[0].creatinineId.laboratoryResultsId
          : null,
        xRayThorax: response[0].xrayThorax,
        xRayThoraxDate: response[0].xrayThoraxDate,
        electrocardiogram: response[0].electrocardiogram,
        electrocardiogramDate: response[0].electrocardiogramDate,
        echocardiogram: response[0].echocardiogram,
        echocardiogramDate: response[0].echocardiogramDate,
        medicineList: response[0].medicineList,
      })
    })
  }

  const validateForm = () => {
    // console.log('validateFormPatientData::: ', validateFormPatientData)
    // console.log('validatePhysicalExamForm::: ', validatePhysicalExamForm)
    // console.log('isValidatedPatientForm::: ', isValidatedPatientForm)
    // console.log('isValidatedFinanceForm::: ', isValidatedFinanceForm)
    // console.log(
    //   'validateComplementaryStudiesForm::: ',
    //   validateComplementaryStudiesForm
    // )
    // console.log(
    //   '____________________________________________________________________ '
    // )
    if (
      validateFormPatientData &&
      validatePhysicalExamForm &&
      isValidatedPatientForm &&
      isValidatedFinanceForm &&
      validateComplementaryStudiesForm
    ) {
      setIsValidatedAllForm(true)
    } else {
      setIsValidatedAllForm(false)
    }
  }

  const setDataPatientForm = (data) => {
    setDataPatient(data)
  }

  const setDataFinanceForm = (data) => {
    setDataFinance(data)
  }

  useEffect(() => {
    if (id) {
      setEditData(id)
    }
  }, [])

  useEffect(() => {
    validateForm()
    setData()
  }, [
    isValidatedPatientForm,
    isValidatedFinanceForm,
    validatePhysicalExamForm,
    validateComplementaryStudiesForm,
    validateFormPatientData,
    dataFinance,
    showSnackBar,
    loading,
  ])
  return {
    loading, onSubmit, setIsValidatedAllForm, goToAdmissionList, complementaryStudiesEdit, setValidateComplementaryStudiesForm, setDataComplementaryStudies, physicalExamEdit, setvalidatePhysicalExamForm, setDataPhysicalExam, patientDataEdit, setValidateFormPatientData, setDataPatientPhysical, setPersonId, personId, setIsValidatedPatientForm,setDataPatientForm,showSnackBar,messageSnackBar,colorSnackBar, setIsValidatedFinanceForm, setDataFinanceForm, save, dataPatientEdit
  }
}

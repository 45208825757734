import BaseInvoker from "./BaseInvoker"
import { Component } from 'react'

const urlInvoke = `/api`;
const urlInvokeMobility = `/api/mobility`;
const baseInvoker = new BaseInvoker();

export default class ApiInvoker extends Component {

	static getCountries(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/countries`, callback, callerror);
	}

	static getProvinces(countryId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/countries/${countryId}/provinces`, callback, callerror);
	}

	static getLocations(provinceId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/provinces/${provinceId}/locations`, callback, callerror);
	}

	static getAddressTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/address-type`, callback, callerror);
	}

	static getGeographicZoneFromLocation(locationId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/geographic-zone/location/${locationId}`, callback, callerror);
	}

	static getTools(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/tools-state`, callback, callerror);
	}

	static getToolType(toolTypeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/tool-types/${toolTypeId}`, callback, callerror);
	}

	static postToolType(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/tool-types`, body, callback, callerror);
	}

	static postTool(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/tools`, body, callback, callerror);
	}

	// SupplyCatalog
	static getSupplyCatalog(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/supplyCatalogs`, callback, callerror);
	}

	static getSupplyCatalogHistory(scheduleId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/schedule/${scheduleId}/supply-catalog-history`, callback, callerror);
	}

	static getSupplySuppl(supplyId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/supplyCatalog/${supplyId}`, callback, callerror);
	}

	static postSupplyCatalog(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/supplyCatalog`, body, callback, callerror);
	}

	// ToolTypes
	static getToolTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/tool-types`, callback, callerror);
	}

	static getToolSuppliers(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/tool-supplier`, callback, callerror);
}

	static getTool(toolId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/tools/${toolId}`, callback, callerror);
	}

	static getAllProvinces(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/provinces`, callback, callerror);
	}

	static getPracticeTypesByName(value, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/practicesType/get?name=${value}`, callback, callerror);
	}

	static getSpecialitiesByName(value, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/specialities/get?name=${value}`, callback, callerror);
	}

	static getFinancersByName(value, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/financiers/get?name=${value}`, callback, callerror);
	}
	// Reports
	static getReportByCompanyAndPartnetship(company, partnetship, reportType, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/report/config?company=${company}&partnetship=${partnetship}&reportType=${reportType}`, callback, callerror);
	}

	//FEES
	static getFeesByEmployeeIdAndEmployeeTypeId({ employeeId, employeeTypeId }, callback, callerror) {
		const url = `${urlInvoke}/practice-fees/employee/${employeeId}?employeeTypeId=${employeeTypeId}`;
		baseInvoker.get(url, callback, callerror);
	}
	static getSchedulesFeesByPracticeType(practiceFeesId, callback, callerror) {
		const url = `${urlInvoke}/schedule-fees?practiceFeesId=${practiceFeesId}`;
		baseInvoker.get(url, callback, callerror);
	}
	static createFee(body, callback, callerror) {
		const url = `${urlInvoke}/practice-fees/save`;
		baseInvoker.post(url, body, callback, callerror);
	}
	static updateFee(practiceFeesId, body, callback, callerror) {
		const url = `${urlInvoke}/practice-fees/update/${practiceFeesId}`;
		baseInvoker.put(url, body, callback, callerror);
	}
	static updateScheduleFee(body, callback, callerror) {
		const url = `${urlInvoke}/schedule-fees/recalculate`;
		baseInvoker.put(url, body, callback, callerror);
	}
	static deleteFee(practiceFeesId, callback, callerror) {
		const url = `${urlInvoke}/practice-fees/${practiceFeesId}`;
		baseInvoker.delete(url, {}, callback, callerror);
	}
	//drivers
	static getDrivers(params, callback, callerror) {
		baseInvoker.get(`${urlInvokeMobility}/drivervehicle/all?${params}`, callback, callerror);
	}
	
	static getDriver(id, callback, callerror) {
		baseInvoker.get(`${urlInvokeMobility}/driverData/${id}`, callback, callerror);
	}
	
	static getDriverVehicles(id, callback, callerror) {
		baseInvoker.get(`${urlInvokeMobility}/${id}/cars`, callback, callerror);
	}
	
	static getDriverGeoZones(id, callback, callerror) {
		baseInvoker.get(`${urlInvokeMobility}/${id}/geographicZone`, callback, callerror);
	}

	static getDriverPhones(id, callback, callerror) {
		baseInvoker.get(`${urlInvokeMobility}/driver-phone/${id}`, callback, callerror);
	}

	static getPersonAddress(id, callback, callerror) {
		baseInvoker.get(`${urlInvokeMobility}/address-data/${id}`, callback, callerror);
	}
	//Vehicles
	static getVehicles(callback, callerror) {
		baseInvoker.get(`${urlInvokeMobility}/company/vehicle/list/get`, callback, callerror);
	}
	//Vehicles
	static getVehiclesBrand(callback, callerror) {
		baseInvoker.get(`${urlInvokeMobility}/vehicle/brand/get`, callback, callerror);
	}
	static getVehiclesModel(callback, callerror) {
		baseInvoker.get(`${urlInvokeMobility}/vehicle/model/get`, callback, callerror);
	}

	static getQuantityItem(startDate,endDate,recurrence,every,weekDays, callback, callerror) {
		const url = `${urlInvokeMobility}/transfer-item-request/quantity?startDate=${startDate}&endDate=${endDate}&recurence=${recurrence}&every=${every}&monthDate&weekDays=${weekDays}`;
		baseInvoker.get(url, callback, callerror);
	}

}

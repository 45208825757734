import React, { useEffect } from 'react'
import { TextField, Typography } from '@material-ui/core'

import { ClinicalHistoryData } from '../interfaces'
import GridContainer from '../../Grid/GridContainer'
import GridItem from '../../Grid/GridItem'
import { SelectOutlined } from '../../SelectOutlined/SelectOutlined'
import { useClinicalHistory } from '../hooks/useClinicalHistory'
import ValidatedTextField from '../components/ValidatedInput'

interface LaboratoryResultsProps {
  data: ClinicalHistoryData
  handleFieldChange: (field: keyof ClinicalHistoryData, value: any) => void
  disabled: boolean
}

const Diagnostic: React.FC<LaboratoryResultsProps> = ({
  data,
  handleFieldChange,
  disabled,
}) => {
  const { getPathologiesData, pathologyOptions } = useClinicalHistory()

  useEffect(() => {
    getPathologiesData()
  }, [])

  return (
    <GridContainer>
      <GridItem md={12} className="step-title">
        <Typography variant="h6">Diagnóstico</Typography>
      </GridItem>

      <GridItem md={12} xs={12} style={{ marginBottom: 24 }}>
        <SelectOutlined
          label="Diagnóstico"
          options={pathologyOptions ?? []}
          select={data?.diagnosticId?.recordId ?? ''}
          onChange={(value) =>
            handleFieldChange('diagnosticId', { recordId: value })
          }
          disabled={disabled}
        />
      </GridItem>

      <GridItem xs={12} style={{ marginBottom: 24 }}>
        <ValidatedTextField
          label="Tratamiento"
          placeholder="Ingresar tratamiento aquí"
          minRows={4}
          value={data?.treatment ?? ''}
          onChange={(value) => handleFieldChange('treatment', value)}
          type="string"
          maxLength={200}
          multiline
          variant="outlined"
          disabled={disabled}
        />
      </GridItem>
      <GridItem xs={12} style={{ marginBottom: 24 }}>
        <ValidatedTextField
          variant="outlined"
          label="Estudios Solicitados"
          placeholder="Ingresar estudios solicitados aquí"
          minRows={4}
          value={data?.requestedStudies ?? ''}
          onChange={(value) => handleFieldChange('requestedStudies', value)}
          type="string"
          maxLength={200}
          multiline
          disabled={disabled}
        />
      </GridItem>
    </GridContainer>
  )
}

export default Diagnostic

import { useEffect, useState } from 'react'

const auditorInitialState = {
  name: '',
  licenseNumber: '',
  telephone: '',
}
const requestorInitialState = {
  name: '',
  licenseNumber: '',
  telephone: '',
  homeMedicalCenter: '',
}
const familyInitialState = {
  name: '',
  telephone: '',
  observations: '',
}
export const useAuthorizerForm = (onFilledAuthorizer: any) => {
  const [hasInCharge, setHasInCharge] = useState('2')
  const [auditor, setAuditor] = useState(auditorInitialState)
  const [requestor, setRequestor] = useState(requestorInitialState)
  const [family, setFamily] = useState(familyInitialState)

  useEffect(() => {
    if (hasInCharge === '2') {
      setFamily(familyInitialState)
      onFilledAuthorizer({
        requestor,
        family: {
          name: '',
          telephone: '',
          observations: '',
        },
        auditor,
      })
    }
  }, [hasInCharge])
  const handleRequestorChange = (field: any, v: any) => {
    onFilledAuthorizer({
      requestor: {
        ...requestor,
        [field]: v,
      },
      family,
      auditor,
    })
    setRequestor({ ...requestor, [field]: v })
  }

  const handleFamilyChange = (field: any, v: any) => {
    onFilledAuthorizer({
      family: {
        ...family,
        [field]: v,
      },
      requestor,
      auditor,
    })
    setFamily({ ...family, [field]: v })
  }

  const handleAuditorChange = (field: any, v: any) => {
    onFilledAuthorizer({
      auditor: {
        ...auditor,
        [field]: v,
      },
      requestor,
      family,
    })
    setAuditor({ ...auditor, [field]: v })
  }

  return {
    setRequestor,
    setHasInCharge,
    setAuditor,
    setFamily,
    handleAuditorChange,
    handleFamilyChange,
    handleRequestorChange,
    requestor,
    auditor,
    hasInCharge,
    family,
  }
}

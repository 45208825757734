import '../../../DiabeticProgramStyles.css'
import React, { useEffect } from 'react'
import DateInput from '../../../../DateInput'
import GridItem from '../../../../Grid/GridItem'
import RadioInput from '../../../../RadioInput'

const Endocrine = (props) => {
  const { setData, setValidateEndocrineSection, editData, onlyView } = props
  const [hypothyroidism, setHypothyroidism] = React.useState('no')
  const [hypothyroidismDate, setHypothyroidismDate] = React.useState(null)

  const FormatData = () => {
    let data = {
      hypothyroidism: hypothyroidism === 'yes' ? true : false,
      hypothyroidismDate: hypothyroidismDate,
    }
    setData(data)
  }

  const validateSection = () => {
    if (hypothyroidism === 'yes' && !hypothyroidismDate) {
      setValidateEndocrineSection(false)
    } else {
      setValidateEndocrineSection(true)
    }
  }

  const setEditData = (data) => {
    setHypothyroidism(data.hypothyroidism === true ? 'yes' : 'no')
    setHypothyroidismDate(data.hypothyroidismDate)
  }

  useEffect(() => {
    if (editData) {
      setEditData(editData);
    }
  }, [editData]); 
  
  useEffect(() => {
    FormatData();
    validateSection();
  }, [hypothyroidism, hypothyroidismDate]);

  return (
    <>
      <GridItem md={12}>
        <h4>Endocrinológico</h4>
      </GridItem>

      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Hipotiroidismo:</p>
          <RadioInput
            value={hypothyroidism}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setHypothyroidismDate(null)
                setHypothyroidism(value)
              } else {
                setHypothyroidism(value)
              }
            }}
          />
          {hypothyroidism === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setHypothyroidismDate(value)}
              value={hypothyroidismDate}
              isRequired={true}
              inputProps={{ disabled: false }}
              disabled={onlyView}
              error={hypothyroidism === 'yes' && !hypothyroidismDate}
              errorText={
                hypothyroidism === 'yes' && !hypothyroidismDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
    </>
  )
}

export default Endocrine

import { Component } from 'react'
import BaseInvoker from "./BaseInvoker"

const urlInvoke = `/api`;
const baseInvoker = new BaseInvoker();

export default class HistoriaClinicaApi extends Component {

	static getHistoriaClinicaList(pacienteId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/customer/${pacienteId}/appointment`, callback, callerror);
	}
	static getIntegralClinicalHistoryIntegral(customerId, dateFrom, dateTo, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/customers/${customerId}/clinical-history?date-from=${dateFrom}&date-to=${dateTo}`, callback, callerror);
	}

	static getMedicineResources( callback, callerror) {
		baseInvoker.get(`${urlInvoke}/schedule-information-types/`, callback, callerror);
	}

	static getClinicalHistory(scheduleId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/patient/history-clinical/form/HC/${scheduleId}`, callback, callerror);
	}

	static postClinicalHistory(scheduleId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/patient/history-clinical/form/HC/${scheduleId}`, body, callback, callerror);
	}
	static getClinicalHistories(customerId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/schedule/history-clinical/customer/${customerId}`, callback, callerror);
	}
}
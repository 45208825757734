import '../../../DiabeticProgramStyles.css'

import React, { useEffect } from 'react'

import CustomInput from '../../../../CustomInput/CustomInput'
import DiabeticApiInvoker from '../../../../../api/DiabeticApiIvoker'
import GridItem from '../../../../Grid/GridItem'
import RadioInput from '../../../../RadioInput'
import SelectInput from '../../../../SelectInput'

const SkinFaneras = (props) => {
  const { setData, save, setValidateSkinSection, editData, onlyView } = props

  const [skinColorId, setSkinColorId] = React.useState(null)
  const [skinColorOptions, setSkinColorOptions] = React.useState([])
  const [skinInfections, setSkinInfections] = React.useState('no')
  const [skinInfectionsDetail, setSkinInfectionsDetail] = React.useState(null)
  const [skinlesions, setSkinlesions] = React.useState('no')
  const [skinlesionsDetail, setSkinlesionsDetail] = React.useState(null)
  const [skinUlcers, setSkinUlcers] = React.useState('no')
  const [skinUlcersDetail, setSkinUlcersDetail] = React.useState(null)

  const formatOptions = (index) => {
    return index.map((e) => ({
      id: e.id,
      value: e.label,
    }))
  }

  const getSkinOptions = () => {
    DiabeticApiInvoker.getAllSkinOptions((data) => {
      let skinOptions = formatOptions(data)
      setSkinColorOptions(skinOptions)
    })
  }

  const FormatData = () => {
    const data = {
      skinColorId: skinColorId,
      skinInfections: skinInfections === 'yes' ? true : false,
      skinInfectionsDetail: skinInfectionsDetail,
      skinlesions: skinlesions === 'yes' ? true : false,
      skinlesionsDetail: skinlesionsDetail,
      skinUlcers: skinUlcers === 'yes' ? true : false,
      skinUlcersDetail: skinUlcersDetail,
    }
    setData(data)
  }

  const setEditData = (data) => {
    setSkinColorId(data.skinColorId.skinId)
    setSkinInfections(data.skinInfections === true ? 'yes' : 'no')
    setSkinInfectionsDetail(data.skinInfectionsDetail)
    setSkinlesions(data.skinLesions === true ? 'yes' : 'no')
    setSkinlesionsDetail(data.skinLesionsDetail)
    setSkinUlcers(data.skinUlcers === true ? 'yes' : 'no')
    setSkinUlcersDetail(data.skinUlcersDetail)
  }

  const validateSection = () => {
    if (!skinColorId) {
      setValidateSkinSection(false)
    } else {
      setValidateSkinSection(true)
    }
  }

  useEffect(() =>  {
    getSkinOptions()
    if (editData) {
      setEditData(editData)
    }
  }, [editData])

  useEffect(() => {
    validateSection()
    FormatData()
  }, [
    skinColorId,
    skinInfections,
    skinInfectionsDetail,
    skinlesions,
    skinlesionsDetail,
    skinUlcers,
    skinUlcersDetail,
  ])

  return (
    <>
      <GridItem md={12}>
        <h4>Piel y faneras</h4>
      </GridItem>
      <GridItem md={4}>
        <SelectInput
          label="Color *"
          onSelectedValue={(value) => setSkinColorId(value)}
          elements={skinColorOptions}
          value={skinColorId}
          invalid={!skinColorId && save}
          disabled={onlyView}
        ></SelectInput>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Infección en la piel:</p>
          <RadioInput
            value={skinInfections}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setSkinInfectionsDetail(null)
                setSkinInfections(value)
              } else {
                setSkinInfections(value)
              }
            }}
          />
          {skinInfections === 'yes' ? (
            <CustomInput
              value={skinInfectionsDetail ? skinInfectionsDetail : ''}
              labelText="Detalle"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                disabled: onlyView,
              }}
              onChange={(value) => setSkinInfectionsDetail(value.target.value)}
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Lesiones en la piel:</p>
          <RadioInput
            value={skinlesions}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setSkinlesionsDetail(null)
                setSkinlesions(value)
              } else {
                setSkinlesions(value)
              }
            }}
          />
          {skinlesions === 'yes' ? (
            <CustomInput
              value={skinlesionsDetail ? skinlesionsDetail : ''}
              labelText="Detalle"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                disabled: onlyView,
              }}
              onChange={(value) => setSkinlesionsDetail(value.target.value)}
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Úlceras por decúbito:</p>
          <RadioInput
            value={skinUlcers}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setSkinUlcersDetail(null)
                setSkinUlcers(value)
              } else {
                setSkinUlcers(value)
              }
            }}
          />
          {skinUlcers === 'yes' ? (
            <CustomInput
              value={skinUlcersDetail ? skinUlcersDetail : ''}
              labelText="Detalle"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                disabled: onlyView,
              }}
              onChange={(value) => setSkinUlcersDetail(value.target.value)}
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
    </>
  )
}

export default SkinFaneras

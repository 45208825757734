import React, { FC, useEffect, useMemo, useRef, useState } from 'react'

import { ActionTypes } from './interfaces'
import Button from '../CustomButtons/Button'
import ButtonSpinner from '../ButtonSpinner'
import CircularProgress from '@material-ui/core/CircularProgress'
import CustomDialog from '../CustomDialog'
import Diagnostic from './Steps/Diagnostic'
import FollowUp from './Steps/FollowUp'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import LaboratoryResults from './Steps/LaboratoryResults'
import MedicinesPage from './Steps/MedicinesPage'
import PatientData from './Steps/PatientData'
import { Size } from '../../util'
import Snackbar from '@material-ui/core/Snackbar'
import { useClinicalHistory } from './hooks/useClinicalHistory'
import { useStyles } from './useStyle'
import { useTranslation } from 'react-i18next'

export interface HCProps {
  params?: {
    id: number
    action: ActionTypes
  }
  location?: {
    query: {
      companyId: string
      lang: string
      color?: string
    }
  }
  scheduleId?: number
  action: ActionTypes
  closeModal: () => void
}

const ClinicalHistory: FC<HCProps> = (props) => {
  const {
    currentStep,
    formState,
    loading,
    isFormVisible,
    showModal,
    currentMedicine,
    error,
    itemsDisabled,
    snackbar,
    goPreviousStep,
    setShowModal,
    handleSubmit,
    handleSaveOrNext,
    handleFieldChange,
    handleSaveMedicine,
    handleAddMedicine,
    setIsFormVisible,
    handleMedicineFieldChange,
    handleEditMedicine,
    handleCloseError,
  } = useClinicalHistory(props, true)
  const { t } = useTranslation()
  const classes = useStyles()
  const [isOverflowing, setIsOverflowing] = useState(false)
  const contentRef = useRef<HTMLDivElement | null>(null)

  const currentStepContent = useMemo(() => {
    switch (currentStep) {
      case 1:
        return (
          <FollowUp
            data={formState?.patientClinicalHistory}
            handleFieldChange={handleFieldChange}
            disabled={itemsDisabled}
          />
        )
      case 2:
        return (
          <PatientData
            data={formState?.patientClinicalHistory}
            handleFieldChange={handleFieldChange}
            disabled={itemsDisabled}
          />
        )
      case 3:
        return (
          <LaboratoryResults
            data={formState?.patientClinicalHistory}
            handleFieldChange={handleFieldChange}
            disabled={itemsDisabled}
          />
        )
      case 4:
        return (
          <Diagnostic
            data={formState?.patientClinicalHistory}
            handleFieldChange={handleFieldChange}
            disabled={itemsDisabled}
          />
        )
      case 5:
        return (
          <MedicinesPage
            data={formState?.medicineList}
            isFormVisible={isFormVisible}
            handleSaveMedicine={handleSaveMedicine}
            handleAddMedicine={handleAddMedicine}
            setIsFormVisible={setIsFormVisible}
            handleMedicineFieldChange={handleMedicineFieldChange}
            handleEditMedicine={handleEditMedicine}
            currentMedicine={currentMedicine}
            disabled={itemsDisabled}
          />
        )
      default:
        return null
    }
  }, [
    loading,
    currentStep,
    formState,
    handleFieldChange,
    classes.contentCircularProgress,
  ])

  useEffect(() => {
    if (contentRef.current) {
      const isContentOverflowing =
        contentRef.current.scrollHeight > window.innerHeight
      setIsOverflowing(isContentOverflowing)
    }
  }, [currentStepContent])

  const translations = {
    messageConfirm: t('clinicalHistory.messageConfirm'),
  }

  return (
    <GridContainer className={classes.gridContainer}>
      <GridItem xs={12} className="no-padding">
        <GridItem
          xs={12}
          className={classes.gridItemCentered}
          ref={contentRef}
          style={{ overflowY: isOverflowing ? 'auto' : 'visible' }}
        >
          {loading ? (
            <div className={classes.contentCircularProgress}>
              <CircularProgress />
            </div>
          ) : (
            currentStepContent
          )}
        </GridItem>
      </GridItem>

      {!isFormVisible && (
        <div className={classes.buttonContainerModal}>
          <Button
            simple
            size="lg"
            onClick={goPreviousStep}
            disabled={currentStep === 1}
            color="primary"
          >
            {t('appointment.new.backStep')}
          </Button>
          {!(itemsDisabled && currentStep === 5) && (
            <Button simple size="lg" onClick={handleSaveOrNext} color="primary">
              {currentStep === 5 ? t('common.save') : t('common.next')}
            </Button>
          )}
        </div>
      )}
      {showModal && (
        <CustomDialog
          maxWidth="sm"
          open={showModal}
          title={t('clinic_history.title')}
          onClose={() => setShowModal(false)}
        >
          <p className={classes.modalMessage}>{translations?.messageConfirm}</p>
          <div className={classes.buttonContainerModal}>
            <Button
              simple
              size={Size.sm}
              onClick={() => setShowModal(false)}
              {...(false
                ? { muiClasses: { root: classes.buttonCancel } }
                : { color: 'primary' })}
            >
              {t('common.cancel')}
            </Button>
            <ButtonSpinner
              onClick={() => {
                handleSubmit()
              }}
              size={Size.sm}
              labelLoading={t('common.loading')}
              loading={loading}
              label={t('common.confirm')}
              typeButton="submit"
              id="button-update"
              {...(false ? { customColor: 'red' } : {})}
            />
          </div>
        </CustomDialog>
      )}
      <Snackbar
        color={snackbar.color}
        message={snackbar.message}
        open={snackbar.open}
      />
      {error?.error && (
        <CustomDialog
          maxWidth="sm"
          open={error.error}
          title={error.title}
          onClose={() => {
            handleCloseError()
          }}
        >
          <p className={classes.modalMessage}>{error.description}</p>
          <div className={classes.buttonContainerModal}>
            <Button
              simple
              size={Size.sm}
              onClick={() => handleCloseError()}
              {...(false
                ? { muiClasses: { root: classes.buttonCancel } }
                : { color: 'primary' })}
            >
              {t('appointment.tools.exit.tittle')}
            </Button>
            <Button
              onClick={() => {
                handleCloseError()
              }}
              {...(false
                ? { muiClasses: { root: classes.buttonAccept } }
                : { color: 'primary' })}
            >
              {t('common.accept')}
            </Button>
          </div>
        </CustomDialog>
      )}
    </GridContainer>
  )
}

export default ClinicalHistory

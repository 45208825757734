import { TextField } from '@material-ui/core'
import React, { useState } from 'react'

interface ValidatedTextFieldProps {
  label: string
  placeholder: string
  value: string | number | null | undefined
  onChange: (value: string) => void
  type: 'number' | 'string'
  min?: number
  max?: number
  minLength?: number
  maxLength?: number
  variant?: 'outlined' | 'standard'
  disabled?: boolean
  multiline?: boolean
  minRows?: number
}

const ValidatedTextField: React.FC<ValidatedTextFieldProps> = ({
  label,
  placeholder,
  value,
  onChange,
  type,
  min,
  max,
  minLength,
  maxLength,
  variant,
  disabled,
  multiline = false,
  minRows = 1,
}) => {
  const [helperText, setHelperText] = useState('')
  const [error, setError] = useState(false)

  const handleBlur = () => {
    if (type === 'number') {
      const numericValue = parseInt((value || '').toString(), 10)
      if (numericValue < 0 && min === 0) {
        setHelperText('Por favor ingrese un valor positivo.')
        setError(true)
        onChange('')
        return
      }
      if (
        isNaN(numericValue) ||
        numericValue < (min || -Infinity) ||
        numericValue > (max || Infinity)
      ) {
        setHelperText(`Por favor ingrese un valor entre ${min} y ${max}.`)
        setError(true)
        onChange('')
      } else {
        setHelperText('')
        setError(false)
      }
    } else if (type === 'string' && value?.toString()) {
      const stringValue = value?.toString()
      if (
        (minLength && stringValue.length < minLength) ||
        (maxLength && stringValue.length > maxLength)
      ) {
        setHelperText(
          `Ingrese un texto entre ${minLength} y ${maxLength} caracteres.`
        )
        setError(true)
      } else {
        setHelperText('')
        setError(false)
      }
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value
    if (maxLength && inputValue.length >= maxLength) {
      setHelperText(`El texto no puede exceder ${maxLength} caracteres.`)
      setError(true)
      inputValue = inputValue.slice(0, maxLength)
    } else {
      setHelperText('')
      setError(false)
    }
    onChange(inputValue)
  }

  return (
    <TextField
      label={label}
      fullWidth
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      margin="none"
      variant={variant}
      error={error}
      helperText={helperText}
      inputProps={{
        type: type === 'number' ? 'number' : 'text',
        min,
        max,
        maxLength,
        style: {
          fontSize: '16px',
          touchAction: 'manipulation',
        },
      }}
      multiline={multiline}
      minRows={minRows}
      InputLabelProps={{
        shrink: true,
      }}
    />
  )
}

export default ValidatedTextField

import '../../../DiabeticProgramStyles.css'
import React, { useEffect } from 'react'
import DateInput from '../../../../DateInput'
import GridItem from '../../../../Grid/GridItem'
import RadioInput from '../../../../RadioInput'

const Vaccination = (props) => {
  const { setData, save, setIsValidatedVacination, editData, onlyView } = props

  const [fluVaccine, setFluVaccine] = React.useState('no')
  const [fluVaccineDate, setFluVaccineDate] = React.useState(null)
  const [pneumococcalVaccine, setPneumococcalVaccine] = React.useState('no')
  const [pneumococcalVaccineDate, setPneumococcalVaccineDate] =
    React.useState(null)

  const FormatData = () => {
    let data = {
      fluVaccine: fluVaccine == 'yes' ? true : false,
      fluVaccineDate: fluVaccineDate,
      pneumococcalVaccine: pneumococcalVaccine == 'yes' ? true : false,
      pneumococcalVaccineDate: pneumococcalVaccineDate,
    }
    setData(data)
  }

  const validateSection = () => {
    if (
      (fluVaccine === 'yes' && fluVaccineDate == null) ||
      (pneumococcalVaccine === 'yes' && pneumococcalVaccineDate == null)
    ) {
      setIsValidatedVacination(false)
    } else {
      setIsValidatedVacination(true)
    }
  }

  const setEditData = (data) => {
    if (data) {
      setFluVaccine(data.fluVaccine === true ? 'yes' : 'no')
      setFluVaccineDate(data.fluVaccineDate)
      setPneumococcalVaccine(data.pneumococcusVaccine === true ? 'yes' : 'no')
      setPneumococcalVaccineDate(data.pneumococcusVaccineDate)
    }
  }

  useEffect(() => {
    if (editData) {
      setEditData(editData);
    }
  }, [editData]); 
  
  useEffect(() => {
    FormatData();
    validateSection();
  }, [fluVaccine, fluVaccineDate, pneumococcalVaccine, pneumococcalVaccineDate]);

  return (
    <>
      <GridItem md={12}>
        <h4>Vacunación</h4>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">
            ¿El paciente posee vacunación antigripal?:
          </p>
          <RadioInput
            value={fluVaccine}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setFluVaccineDate(null)
                setFluVaccine(value)
              } else {
                setFluVaccine(value)
              }
            }}
          />
          {fluVaccine === 'yes' ? (
            <DateInput
              text="Fecha de vacunación antigripal *"
              onChangeValue={(value) => setFluVaccineDate(value)}
              value={fluVaccineDate}
              isRequired={true}
              inputProps={{ disabled: false }}
              error={fluVaccine === 'yes'}
              errorText={
                fluVaccine === 'yes' && !fluVaccineDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
              disabled={onlyView}
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">
            ¿El paciente posee vacunación contra el neumococo?:
          </p>
          <RadioInput
            value={pneumococcalVaccine}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setPneumococcalVaccineDate(null)
                setPneumococcalVaccine(value)
              } else {
                setPneumococcalVaccine(value)
              }
            }}
          />
          {pneumococcalVaccine === 'yes' ? (
            <DateInput
              text="Fecha de vacunación *"
              onChangeValue={(value) => setPneumococcalVaccineDate(value)}
              value={pneumococcalVaccineDate}
              isRequired={true}
              inputProps={{ disabled: false }}
              error={pneumococcalVaccine === 'yes'}
              errorText={
                pneumococcalVaccine === 'yes' && !pneumococcalVaccineDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
              disabled={onlyView}
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
    </>
  )
}

export default Vaccination

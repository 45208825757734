import { connect } from 'react-redux';
import DashboardDiabetic from '../components/DashboardsPB/DashboardDiabeticindex';

const mapStateToProps = (state) => {
    return {
        
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}

const DashboardDiabeticService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardDiabetic);

export default DashboardDiabeticService;
import './AuthorizerForm.css';

import React, { useEffect } from 'react';

import CustomInput from '../../CustomInput/CustomInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import PropTypes from 'prop-types';
import RadioInput from '../../RadioInput';
import { withTranslation } from 'react-i18next';
import { useAuthorizerForm } from './hooks/useAuthorizerForm';


const AuthorizerForm = ({ disabled, onFilledAuthorizer, t, value }) => {
  const {setRequestor, setHasInCharge, setAuditor, setFamily, handleAuditorChange, handleFamilyChange, handleRequestorChange, requestor, auditor,hasInCharge, family } = useAuthorizerForm(onFilledAuthorizer)


  useEffect(() => {
    const { metadata } = value;
    if (metadata?.requestor) {
      setRequestor({
        name: metadata.requestor.name,
        licenseNumber: metadata.requestor.licenseNumber,
        telephone: metadata.requestor.telephone,
        homeMedicalCenter: metadata.requestor.homeMedicalCenter,
      });
    }
    if (metadata?.auditor) {
      setAuditor({
        name: metadata.auditor.name,
        licenseNumber: metadata.auditor.licenseNumber,
        telephone: metadata.auditor.telephone,
      });
    }
    if (metadata?.family) {
      setFamily({
        name: metadata.family.name,
        telephone: metadata.family.telephone,
        observations: metadata.family.observations,
      });
      const isData = metadata.family.name || metadata.family.telephone || metadata.family.observations;
      if (isData) {
        setHasInCharge('1');
      }
    }
  }, []);

 
  return (
    <div className="authorizer-form">
        <GridContainer className="authorizer">
          <GridItem className="authorizer-medical-name" sm={4}>
            <CustomInput
              id="input-doctor-name"
              labelText={t('appointment.new.authorizer.doctor_name')}
              onChange={e => handleRequestorChange('name', e.target.value)}
              formControlProps={{
                fullWidth: true,
                disabled: disabled,
              }}
              value={requestor?.name ?? ''}
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
          <GridItem className="authorizer-medical-registration" sm={2}>
            <CustomInput
              id="input-license-number"
              labelText={t('appointment.new.authorizer.medical_registration_number')}
              formControlProps={{
                fullWidth: true,
                disabled,
              }}
              onChange={e => handleRequestorChange('licenseNumber', e.target.value)}
              value={requestor?.licenseNumber ?? ''}
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
          <GridItem className="authorizer-medical-registration" sm={2}>
            <CustomInput
              id="input-phone-number"
              labelText={t('appointment.new.authorizer.medical_phone_number')}
              formControlProps={{
                fullWidth: true,
              }}
              onChange={e => handleRequestorChange('telephone', e.target.value)}
              value={requestor?.telephone ?? ''}
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
          <GridItem className="authorizer-medical-registration" sm={4}>
            <CustomInput
              id="input-medical-center"
              labelText={t('appointment.new.authorizer.medical_center')}
              formControlProps={{
                fullWidth: true,
              }}
              onChange={e => handleRequestorChange('homeMedicalCenter', e.target.value)}
              value={requestor?.homeMedicalCenter ?? ''}
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer className="authorizer">
          <GridItem className="authorizer-medical-name" sm={4}>
            <CustomInput
              id="input-auditor-name"
              labelText={t('appointment.new.authorizer.doctor_auditor_name')}
              onChange={e => handleAuditorChange('name', e.target.value)}
              formControlProps={{
                fullWidth: true,
              }}
              value={auditor?.name ?? ''}
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
          <GridItem className="authorizer-medical-registration" sm={2}>
            <CustomInput
              id="input-registration-number"
              labelText={t('appointment.new.authorizer.medical_registration_number')}
              formControlProps={{
                fullWidth: true,
              }}
              onChange={e => handleAuditorChange('licenseNumber', e.target.value)}
              value={auditor?.licenseNumber ?? ''}
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
          <GridItem className="authorizer-medical-registration" sm={2}>
            <CustomInput
              id="input-authorizer-phone"
              labelText={t('appointment.new.authorizer.medical_phone_number')}
              formControlProps={{
                fullWidth: true,
              }}
              onChange={e => handleAuditorChange('telephone', e.target.value)}
              value={auditor?.telephone ?? ''}
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
          <GridItem sm={4}/>
        </GridContainer>
        <GridContainer className="authorizer margin-top" justify="flex-start">
          <GridItem xs={12} sm={8} className="radio-input-inline">
            <p className="radio-legend">{t('technicalfile.hasFamily')}</p>
            <RadioInput
              id="radio-hasFamily"
              value={hasInCharge}
              onChangeValue={v => setHasInCharge(v)}
              elements={[
                { id: '1', value: t('form.option.yes'), component: true },
                { id: '2', value: t('form.option.no'), component: false }
              ]}
              inputProps={{
                disabled: disabled || false,
              }}
            />
          </GridItem>
        </GridContainer>

        {hasInCharge === '1' && (
          <GridContainer className="authorizer">
            <GridItem className="authorizer-contact-family-name" sm={6}>
              <CustomInput
                id="input-contact-name"
                labelText={t('appointment.new.authorizer.contactFamilyName')}
                value={family?.name ?? ''}
                onChange={e => handleFamilyChange('name', e.target.value)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: disabled || false,
                }}
              />
            </GridItem>
            <GridItem className="authorizer-contact-family-phone" sm={3}>
              <CustomInput
                id="input-contact-phone"
                labelText={t('appointment.new.authorizer.contactFamilyPhoneNumber')}
                onChange={e => handleFamilyChange('telephone', e.target.value)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: disabled || false,
                }}
                value={family?.telephone ?? ''}
              />
            </GridItem>
            <GridItem className="authorizer-contact-family-phone" sm={3}>
              <CustomInput
                id="input-observations"
                labelText={t('label.observations')}
                onChange={e => handleFamilyChange('observations', e.target.value)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: disabled || false,
                }}
                value={family?.observations ?? ''}
              />
            </GridItem>
          </GridContainer>
        )}
    </div>
  )
}

AuthorizerForm.propTypes = {
  t: PropTypes.func.isRequired,
  onFilledAuthorizer: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.shape({
    metadata: PropTypes.object,
  }),
}

export default withTranslation()(AuthorizerForm);

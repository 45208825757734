import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import CardBody from '../../Card/CardBody';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import SelectInput from '../../SelectInput';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import store from '../../../store';
import '../NewSchedule.css';
import Car from "../../../icons/Drivers";

class CustomerForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            customers: [],
            address: '',
            zone: '',
            customer: '',
            customerId: '',
            addresses: [],
            location: '',
            province: '',
            
        }
    }

    componentDidMount() {
        this.getCustomers();
        const { value } = this.props;
        if (value) {
            this.handleValue(value.customer, 'customer');
            this.handleValue(value.address, 'address');
        }
    }

    componentWillReceiveProps(next) {
        const { value } = this.props;
        const { customers } = this.state;
        if (next.customers) {
            const newCustomers = this.formatCustomers(next.customers);
            this.setState({ customers: newCustomers })
        }
        
        if (value?.customer && customers) {
            const customerIndex = customers.findIndex(item => item.id === value.customer);
            
            if (customerIndex !== -1) {
                const customerId = customers[customerIndex].customerId;
                this.setState({ customerId })
            }
        }
    }

    formatCustomers = (customers) => customers.map(c => ({
        id: c.personId,
        customerId: c.id,
        value: `${c.lastName} ${c.firstName}`,
    }));

    formatAddresses = (addresses) => addresses.map(a => ({
        id: a.addressId,
        value: `${a.street} ${a.number}`,
        geographicZoneId: a.geographicZone  ? a.geographicZone.geographicZoneId : 0,
        location: a.location.name,
        zone: a.geographicZone ? a.geographicZone.detail : '',
        province: a.provinceName,
    }));

    getCustomers() {
        if (store.getState().customers.customers &&
            store.getState().customers.customers.length > 0) {
            const customers = this.formatCustomers(store.getState().customers.customers);
            this.setState({ customers })
            return;
        }
        PersonApiInvoker.getCustomers(true, data => {
            const customers = this.formatCustomers(data);
            this.setState({ customers })
            this.props.onGetCustomers(data)
        }, _ => {
            this.setState({ loading: false })
        })
    }

    propsOnFilledCustomer() {
        const { address, customer, customerId, geographicZoneId, location, province, zone } = this.state;
        this.props.onFilledCustomer({
            customer,
            geographicZoneId,
            customerId,
            address,
            zone,
            location,
            province,
        });
    }

    setAddress() {
        const { addresses } = this.state;
        let address = {
            address: '',
            zone: '',
            province: '',
            geographicZoneId: '',
            location: '',
        };
        
        if (addresses.length === 1) {
            const selectedAddress = addresses[0];
            address = {
                address: selectedAddress.id,
                zone: selectedAddress.zone,
                province: selectedAddress.province,
                geographicZoneId: selectedAddress.geographicZoneId,
                location: selectedAddress.location,
            };
        }
        if (addresses.length > 1) {
            const addressIndex = addresses.findIndex(item => item.id === this.state.address)
            if (addressIndex !== -1) {
                const selectedAddress = addresses[addressIndex];
                address = {
                    address: selectedAddress.id,
                    zone: selectedAddress.zone,
                    province: selectedAddress.province,
                    geographicZoneId: selectedAddress.geographicZoneId,
                    location: selectedAddress.location,
                };
            }
        }
        this.setState({
            address: address.address,
            location: address.location,
            zone: address.zone,
            province: address.province,
            geographicZoneId: address.geographicZoneId
        }, () => { this.propsOnFilledCustomer() });
    }

    getAddresses(personId) {
        PersonApiInvoker.getPersonsAddress(personId,false, data => {
            if (data?.length) {
                this.setState({ addresses: this.formatAddresses(data)}, () => {
                    this.setAddress();
                });
            } else {
                this.setState({
                    addresses: [],
                    address: '',
                    location: '',
                    province: '',
                    zone: '',
                    geographicZoneId: '',
                }, () => {   
                    this.propsOnFilledCustomer();
                });
            }
        }, null);
    }

    handleValue(value, state) {
        this.setState({ [state]: value });
        const { addresses, customer, customerId, customers } = this.state;

        if (state === 'customer') {
            this.getAddresses(value);
            const customerIndex = customers.findIndex(item => item.id === value);
            if (customerIndex !== -1) {
                this.setState({ customerId: customers[customerIndex].customerId }, () => {
                        this.props.onFilledCustomer({ customer, customerId })
                    }
                )
            }
        }
        if (state === 'address') {
            const addressIndex = addresses.findIndex(item => item.id === value)
            if (addressIndex !== -1) {
                this.setState({
                    address: addresses[addressIndex].id,
                    geographicZoneId: addresses[addressIndex].geographicZoneId,
                    zone: addresses[addressIndex].zone || '',
                    location: addresses[addressIndex].location || '',
                    province: addresses[addressIndex].province || ''
                }, () => {
                    this.propsOnFilledCustomer();
                });
            }
        }
    }

    render() {
        const { customers, customer, address, zone, } = this.state;
        const { disabled, checkInvalid, t, type  } = this.props;
        let hora_real_fin = "-";
        return (
            <Card id="card-customer" className="card-customer-form">
                <CardHeader icon>
                    <CardIcon color="primary">
                        <Car />
                    </CardIcon>
                    <h3 className="card-icon-title">{t("service.configuration")}</h3>
                </CardHeader>
                <CardBody>
                    
                    <GridContainer className="card-customer-container">
                        {type ==="ida" ?
                        <GridContainer  xs={12} sm={6}>
                        <h2>{t('service.oneWay.label')}</h2>
                        <GridContainer>
                            <GridItem className="fullWidth-input" xs={12} sm={12}>
                                <SelectInput
                                    id="select-customer"
                                    disabled={disabled}
                                    label={t("service.traslado")}
                                    elements={customers}
                                    onSelectedValue={value => this.handleValue(value, 'customer')}
                                    value={customer}
                                    invalid={!customer && checkInvalid}
                                    errorText={!customer && checkInvalid ? t('error.required') : ''}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer className="fullWidth-input" xs={12} sm={12}>
                        {t("service.origin")}
                            <GridItem className="fullWidth-input" xs={12} sm={12}>
                                <SelectInput
                                    id="select-customer"
                                    disabled={disabled}
                                    label={t("address.title")}
                                    elements={customers}
                                    onSelectedValue={value => this.handleValue(value, 'customer')}
                                    value={customer}
                                    invalid={!customer && checkInvalid}
                                    errorText={!customer && checkInvalid ? t('error.required') : ''}
                                />
                            </GridItem>
                            <GridItem className="fullWidth-input" xs={12} sm={6}>
                                <TextField
                                    id="input-zone"
                                    label={t("address.floor")}
                                    fullWidth
                                    value={((customer && address) && zone) || ''}
                                />
                            </GridItem>
                            <GridItem className="fullWidth-input" xs={12} sm={6}>
                                <TextField
                                    id="input-zone"
                                    fullWidth
                                    value={((customer && address) && zone) || ''}
                                    label={t("address.apartment")}
                                />
                            </GridItem>
                            <GridItem>
                            <SelectInput
                                id="select-plan-repeat"
                                elements={this.recurrenceTypes}
                                label={`${t('schedule.plan.repeat.type')} *`}
                                value={this.recurrenceType}

                                onSelectedValue={(value) => this.handleValue(value, 'recurrenceType')}
                                />
                            </GridItem>
                            
                            <GridItem className="fullWidth-input timeInput" xs={6} sm={6} >
                                <TextField
                                label={t("medical.record.detail.withdrawal")}
                                
                                fullWidth
                                labelProps={{ style: { width: "100%", marginTop: "-10px" } }}
                                
                                />
                            </GridItem>
                            <GridItem className="fullWidth-input " xs={6} sm={6} md={6}>
                                <TextField
                                type="text"
                                fullWidth
                                label={t("service.stimated.pickup.time")}
                                value={hora_real_fin}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                        {t("service.destination")}
                            <GridItem className="fullWidth-input" xs={12} sm={12}>
                                <SelectInput
                                    id="select-customer"
                                    disabled={disabled}
                                    label={t("service.medical.provider")}
                                    elements={customers}
                                    onSelectedValue={value => this.handleValue(value, 'customer')}
                                    value={customer}
                                    invalid={!customer && checkInvalid}
                                    errorText={!customer && checkInvalid ? t('error.required') : ''}
                                />
                            </GridItem>
                            <GridItem className="fullWidth-input" xs={12} sm={6}>
                                <SelectInput
                                    id="select-customer"
                                    disabled={disabled}
                                    label={t("provider.type")}
                                    elements={customers}
                                    onSelectedValue={value => this.handleValue(value, 'customer')}
                                    value={customer}
                                    invalid={!customer && checkInvalid}
                                    errorText={!customer && checkInvalid ? t('error.required') : ''}
                                />
                            </GridItem>
                            <GridItem className="fullWidth-input" xs={12} sm={6}>
                                <SelectInput
                                    id="select-customer"
                                    disabled={disabled}
                                    label={t("service.address")}
                                    elements={customers}
                                    onSelectedValue={value => this.handleValue(value, 'customer')}
                                    value={customer}
                                    invalid={!customer && checkInvalid}
                                    errorText={!customer && checkInvalid ? t('error.required') : ''}
                                />
                            </GridItem>

                            <GridItem xs={6} sm={6} md={6} className="timeInput">
                                <TextField
                                fullWidth
                                label={t("common.date.turn")}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <TextField
                                type="text"
                                label={t("service.time_end_destination.requested")}
                                value={hora_real_fin}
                                fullWidth
                                />
                            </GridItem>
                        </GridContainer>
                    </GridContainer>
                    :
                    <GridContainer xs={12} sm={6}>
                            <h2>{t('service.returnTrip.label')}</h2>
                            <GridContainer>
                                <GridItem className="fullWidth-input" xs={12} sm={12}>
                                    <SelectInput
                                        id="select-customer"
                                        disabled={disabled}
                                        label={t("service.traslado")}
                                        elements={customers}
                                        onSelectedValue={value => this.handleValue(value, 'customer')}
                                        value={customer}
                                        invalid={!customer && checkInvalid}
                                        errorText={!customer && checkInvalid ? t('error.required') : ''}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer className="fullWidth-input" xs={12} sm={12}>
                            {t("service.origin")}
                                <GridItem className="fullWidth-input" xs={12} sm={12}>
                                    <SelectInput
                                        id="select-customer"
                                        disabled={disabled}
                                        label={t("service.medical.provider")}
                                        elements={customers}
                                        onSelectedValue={value => this.handleValue(value, 'customer')}
                                        value={customer}
                                        invalid={!customer && checkInvalid}
                                        errorText={!customer && checkInvalid ? t('error.required') : ''}
                                    />
                                </GridItem>
                                <GridItem className="fullWidth-input" xs={12} sm={12}>
                                    <SelectInput
                                        id="select-customer"
                                        disabled={disabled}
                                        label={t("provider.type")}
                                        elements={customers}
                                        invalid={!customer && checkInvalid}
                                        onSelectedValue={value => this.handleValue(value, 'customer')}
                                        value={customer}
                                        errorText={!customer && checkInvalid ? t('error.required') : ''}
                                    />
                                </GridItem>
                               
                                <GridItem xs={6} sm={6} md={6} className="timeInput">
                                    <TextField
                                        fullWidth                              
                                        label={t("common.date.turn")}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <TextField
                                        type="text"
                                        fullWidth
                                        label={t("service.time_end_destination.requested")}
                                        value={hora_real_fin}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                            {t("service.destination")}
                                <GridItem className="fullWidth-input" xs={12} sm={12}>
                                    <SelectInput
                                        id="select-customer"
                                        disabled={disabled}
                                        label={t("address.title")}
                                        elements={customers}
                                        onSelectedValue={value => this.handleValue(value, 'customer')}
                                        value={customer}
                                        invalid={!customer && checkInvalid}
                                        errorText={!customer && checkInvalid ? t('error.required') : ''}
                                    />
                                </GridItem>
                                <GridItem className="fullWidth-input" xs={6} sm={6}>
                                    <TextField
                                        id="input-zone"
                                        label={t("address.floor")}
                                        value={((customer && address) && zone) || ''}
                                        fullWidth
                                    />
                                </GridItem>
                                <GridItem className="fullWidth-input" xs={6} sm={6}>
                                    <TextField
                                        id="input-zone"  
                                        label={t("address.apartment")}
                                        value={((customer && address) && zone) || ''}
                                        fullWidth
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6} className="timeInput">
                                    <TextField
                                    label={t("service.estimated.date.arriving")}
                                   fullWidth
                                    
                                    
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <TextField
                                    type="text"
                                    fullWidth
                                    label={t("service.stimated.arrival.time")}
                                    value={hora_real_fin}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridContainer>

                        }
                        

                        

                    
                    </GridContainer>
                </CardBody>
            </Card>
        )
    }
}

CustomerForm.propTypes = {
    t: PropTypes.func,
    onGetCustomers: PropTypes.func,
    onFilledCustomer: PropTypes.func,
    value: PropTypes.object,
    disabled: PropTypes.bool,
    checkInvalid: PropTypes.bool,
}

export default withTranslation()(CustomerForm);

import { Medicine, RootClinicalHistory } from "../interfaces";

export const initialFormState: RootClinicalHistory = {
    patientClinicalHistory: {
      patientClinicalHistoryId: undefined,
      registerDate: undefined,
      temperature: null,
      pulse: null,
      oxigenSaturation: null,
      bloodPressureMin: null,
      bloodPressureMax: null,
      unitOfMeasurement: null,
      valueGlucose: null,
      clasification: null,
      weightUnit: '',
      weightValue: null,
      breatheRate: null,
      stress: null,
      hrvsdnn: null,
      scheduleId: undefined,
      source: '',
      heightValue: null,
      imc: 0,
      glycemiaId: null,
      glycosylatedHemoglobinId: null,
      cholesterolId: null,
      triglyceridesId: null,
      diagnosticId: null,
      treatment: '',
      requestedStudies: '',
      nextConsultationDate: '',
      dischargeDate: '',
      specialty1: {
        companyId: 0,
        companyPracticeTypeId: 0,
        practiceType: 0,
        practiceTypeGroup: 0,
        practiceTypeCode: '',
        practiceTypeName: '',
        active: false,
      },
      specialty2: {
        companyId: 0,
        companyPracticeTypeId: 0,
        practiceType: 0,
        practiceTypeGroup: 0,
        practiceTypeCode: '',
        practiceTypeName: '',
        active: false,
      },
      specialty3: {
        companyId: 0,
        companyPracticeTypeId: 0,
        practiceType: 0,
        practiceTypeGroup: 0,
        practiceTypeCode: '',
        practiceTypeName: '',
        active: false,
      },
      others: '',
      observations: '',
    },
    medicineList: [],
  }
  

 export const initialMedicine: Medicine = {
    scheduleMedicineId: 0,
    medicineType: { medicineTypeId: null, name: '' },
    medicineUnitType: { medicineUnitTypeId: null, name: '' },
    medicinePresentationType: { medicinePresentationTypeId: null, name: '' },
    medicineQuantity: null,
    medicineDose: null,
    medicineViaType: { medicineViaTypeId: null, name: '' },
    medicineLotNumber: '',
    medicineExpireDate: '',
    medicineDateTime: new Date().toISOString(),
  }
import '../../../DiabeticProgramStyles.css'
import React, { useEffect } from 'react'
import CustomInput from '../../../../CustomInput/CustomInput'
import DateInput from '../../../../DateInput'
import DiabeticApiInvoker from '../../../../../api/DiabeticApiIvoker'
import GridItem from '../../../../Grid/GridItem'
import RadioInput from '../../../../RadioInput'
import SelectInput from '../../../../SelectInput'

const Neurological = (props) => {
  const { setData, setValidateNeuroSection, editData, onlyView, save } = props

  const [paresis, setParesis] = React.useState('no')
  const [plegia, setPlegia] = React.useState('no')
  const [atrophy, setAtrophy] = React.useState('no')
  const [amputation, setAmputation] = React.useState('no')
  const [ataxia, setAtaxia] = React.useState('no')
  const [muscularStrength, setMuscularStrength] = React.useState(null)
  const [hypesthesia, setHypesthesia] = React.useState('no')
  const [dementia, setDementia] = React.useState('no')
  const [apathy, setApathy] = React.useState('no')
  const [lessInterestOrPleasureId, setLessInterestOrPleasureId] =
    React.useState(null)
  const [feelingDepressedOrHopelessId, setFeelingDepressedOrHopelessId] =
    React.useState(null)
  const [acv, setAcv] = React.useState('no')
  const [acvDate, setAcvDate] = React.useState(null)
  const [alzheimer, setAlzheimer] = React.useState('no')
  const [alzheimerDate, setAlzheimerDate] = React.useState(null)
  const [parkinson, setParkinson] = React.useState('no')
  const [parkinsonDate, setParkinsonDate] = React.useState(null)
  const [epilepsy, setEpilepsy] = React.useState('no')
  const [epilepsyDate, setEpilepsyDate] = React.useState(null)
  const [depressedOptions, setDepressedOptions] = React.useState([])

  const [neurologicalComments, setNeurologicalComments] = React.useState(null)

  let neurologicalCommentsLenth = neurologicalComments
    ? neurologicalComments.length
    : 0

  const FormatData = () => {
    let data = {
      paresis: paresis === 'yes' ? true : false,
      plegia: plegia === 'yes' ? true : false,
      atrophy: atrophy === 'yes' ? true : false,
      amputation: amputation === 'yes' ? true : false,
      ataxia: ataxia === 'yes' ? true : false,
      muscularStrength: muscularStrength,
      hypesthesia: hypesthesia === 'yes' ? true : false,
      dementia: dementia === 'yes' ? true : false,
      apathy: apathy === 'yes' ? true : false,
      acv: acv === 'yes' ? true : false,
      acvDate: acvDate,
      alzheimer: alzheimer === 'yes' ? true : false,
      alzheimerDate: alzheimerDate,
      parkinson: parkinson === 'yes' ? true : false,
      parkinsonDate: parkinsonDate,
      epilepsy: epilepsy === 'yes' ? true : false,
      epilepsyDate: epilepsyDate,
      neurologicalComments: neurologicalComments,
      lessInterestOrPleasureId: lessInterestOrPleasureId,
      feelingDepressedOrHopelessId: feelingDepressedOrHopelessId,
    }
    setData(data)
  }

  const setEditData = (data) => {
    setParesis(data.paresis === true ? 'yes' : 'no')
    setPlegia(data.plegia === true ? 'yes' : 'no')
    setAtrophy(data.atrophy === true ? 'yes' : 'no')
    setAmputation(data.amputation === true ? 'yes' : 'no')
    setAtaxia(data.ataxia === true ? 'yes' : 'no')
    setMuscularStrength(data.muscularStrength)
    setHypesthesia(data.hypesthesia === true ? 'yes' : 'no')
    setDementia(data.dementia === true ? 'yes' : 'no')
    setApathy(data.apathy === true ? 'yes' : 'no')
    setAcv(data.acv === true ? 'yes' : 'no')
    setAcvDate(data.acvDate)
    setAlzheimer(data.alzheimer === true ? 'yes' : 'no')
    setAlzheimerDate(data.alzheimerDate)
    setParkinson(data.parkinson === true ? 'yes' : 'no')
    setParkinsonDate(data.parkinsonDate)
    setEpilepsy(data.epilepsy === true ? 'yes' : 'no')
    setEpilepsyDate(data.epilepsyDate)
    setNeurologicalComments(data.neurologyComments)
    setLessInterestOrPleasureId(
      data.lessInterestOrPleasureId
        ? data.lessInterestOrPleasureId.depressionValuesId
        : null
    )
    setFeelingDepressedOrHopelessId(
      data.feelingDepressedOrHopelessId
        ? data.feelingDepressedOrHopelessId.depressionValuesId
        : null
    )
  }

  const validateSection = () => {
    if (
      (acv === 'yes' && !acvDate) ||
      (alzheimer === 'yes' && !alzheimerDate) ||
      (parkinson === 'yes' && !parkinsonDate) ||
      (epilepsy === 'yes' && !epilepsyDate)
    ) {
      setValidateNeuroSection(false)
    } else {
      setValidateNeuroSection(true)
    }
  }

  const formatOptions = (index, variable) =>
    index.map((e) => ({
      id: e.id,
      value: e.label,
    }))

  const getDressingsType = () => {
    DiabeticApiInvoker.getAllDepressionValuesOptions((data) => {
      let imcOptions = formatOptions(data)
      setDepressedOptions(imcOptions)
    })
  }

  useEffect(() => {
    getDressingsType();
    if (editData) {
      setEditData(editData);
    }
  }, [editData]); 
  
  useEffect(() => {
    validateSection();
    FormatData();
  }, [
    paresis,
    plegia,
    atrophy,
    amputation,
    ataxia,
    muscularStrength,
    hypesthesia,
    dementia,
    apathy,
    acv,
    acvDate,
    alzheimer,
    alzheimerDate,
    parkinson,
    parkinsonDate,
    epilepsy,
    epilepsyDate,
    neurologicalComments,
  ]);

  return (
    <>
      <GridItem md={12}>
        <h4>Neurológico</h4>
      </GridItem>
      <GridItem md={12}>
        <h5>Motor:</h5>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Paresia:</p>
          <RadioInput
            value={paresis}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setParesis(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Plejia:</p>
          <RadioInput
            value={plegia}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setPlegia(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Atrofia:</p>
          <RadioInput
            value={atrophy}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setAtrophy(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Amputación:</p>
          <RadioInput
            value={amputation}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setAmputation(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Ataxia:</p>
          <RadioInput
            value={ataxia}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setAtaxia(value)}
          />
        </div>
      </GridItem>
      <GridItem md={3}>
        <div className="contaiter-muscular-strength">
          <p className="label-radiobox-strong">Fuerza muscular:</p>
          <SelectInput
            label="Selecciona"
            onSelectedValue={(value) => setMuscularStrength(value)}
            elements={[
              { value: '1', id: 1 },
              { value: '2', id: 2 },
              { value: '3', id: 3 },
              { value: '4', id: 4 },
              { value: '5', id: 5 },
            ]}
            inputProps={{ width: '60%' }}
            value={muscularStrength}
            disabled={onlyView}
          ></SelectInput>
        </div>
      </GridItem>
      <GridItem md={12}>
        <h5>Sensitivo:</h5>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Hipoestesia:</p>
          <RadioInput
            value={hypesthesia}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setHypesthesia(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <h5>Cognitivo emocional:</h5>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Demencia:</p>
          <RadioInput
            value={dementia}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setDementia(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Apatía:</p>
          <RadioInput
            value={apathy}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setApathy(value)}
          />
        </div>
      </GridItem>
      <GridItem md={9}>
        <p className="depression-section">
          <strong>Depresión:</strong> Durante las últimas 2(dos) semanas ¿con
          que frecuencia le ha molestado los siguientes problemas?
        </p>
      </GridItem>
      <GridItem md={3}></GridItem>
      <GridItem md={5}>
        <SelectInput
          label="Poco interés o placer en hacer cosas"
          onSelectedValue={(value) => setLessInterestOrPleasureId(value)}
          elements={depressedOptions}
          value={lessInterestOrPleasureId}
          disabled={onlyView}
        ></SelectInput>
      </GridItem>
      <GridItem md={5}>
        <SelectInput
          label="Sentirse deprimido o desesperado"
          onSelectedValue={(value) => setFeelingDepressedOrHopelessId(value)}
          elements={depressedOptions}
          value={feelingDepressedOrHopelessId}
          disabled={onlyView}
        ></SelectInput>
      </GridItem>
      <GridItem md={2}></GridItem>
      <GridItem md={12}>
        <h5>Antecedentes:</h5>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">ACV:</p>
          <RadioInput
            value={acv}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setAcvDate(null)
                setAcv(value)
              } else {
                setAcv(value)
              }
            }}
          />
          {acv === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setAcvDate(value)}
              value={acvDate}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
              disabled={onlyView}
              error={acv === 'yes'}
              errorText={
                acv === 'yes' && !acvDate ? 'Debe ingresar una fecha' : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Alzheimer:</p>
          <RadioInput
            value={alzheimer}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setAlzheimerDate(null)
                setAlzheimer(value)
              } else {
                setAlzheimer(value)
              }
            }}
          />
          {alzheimer === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setAlzheimerDate(value)}
              value={alzheimerDate}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
              disabled={onlyView}
              error={alzheimer === 'yes'}
              errorText={
                alzheimer === 'yes' && !alzheimerDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Parkinson:</p>
          <RadioInput
            value={parkinson}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setParkinsonDate(null)
                setParkinson(value)
              } else {
                setParkinson(value)
              }
            }}
          />
          {parkinson === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setParkinsonDate(value)}
              value={parkinsonDate}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
              disabled={onlyView}
              error={parkinson === 'yes'}
              errorText={
                parkinson === 'yes' && !parkinsonDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Epilepsia:</p>
          <RadioInput
            value={epilepsy}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setEpilepsyDate(null)
                setEpilepsy(value)
              } else {
                setEpilepsy(value)
              }
            }}
          />
          {epilepsy === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setEpilepsyDate(value)}
              value={epilepsyDate}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
              disabled={onlyView}
              error={epilepsy === 'yes'}
              errorText={
                epilepsy === 'yes' && !epilepsyDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <CustomInput
          value={neurologicalComments ? neurologicalComments : ''}
          labelText="Comentarios:"
          multiline={true}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            maxlength: '500',
            disabled: onlyView,
          }}
          helpText={`Hasta 500 caracteres. Te quedan ${
            500 - neurologicalCommentsLenth
          } `}
          onChange={(e) => setNeurologicalComments(e.target.value)}
        />
      </GridItem>
    </>
  )
}

export default Neurological

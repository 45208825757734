import '../../../DiabeticProgramStyles.css'
import React, { useEffect } from 'react'
import CustomInput from '../../../../CustomInput/CustomInput'
import DiabeticApiInvoker from '../../../../../api/DiabeticApiIvoker'
import GridItem from '../../../../Grid/GridItem'

const Measures = (props) => {
  const { setData, save, setIsValidatedMeasure, editData, onlyView } = props

  const [height, setHeight] = React.useState(null)
  const [weight, setWeight] = React.useState(null)
  const [imcId, setImcId] = React.useState(null)
  const [imcOptions, setImcOptions] = React.useState([])

  const validateSection = () => {
    if (!height || !weight || !imcId) {
      setIsValidatedMeasure(false)
    } else {
      setIsValidatedMeasure(true)
    }
  }

  const formatOptions = (index) =>
    index.map((e) => ({
      id: e.id,
      value: e.label,
    }))

  const getIMCOptions = () => {
    DiabeticApiInvoker.getAllIMCOptions((data) => {
      let imcOptions = formatOptions(data)
      setImcOptions(imcOptions)
    })
  }

  const FormatData = () => {
    let data = {
      height: height,
      weight: weight,
      imcId: imcId,
    }
    setData(data)
  }

  const setDataEdit = (data) => {
    setHeight(data.height)
    setWeight(data.weight)
    setImcId(data.imcId)
  }

  const CalculateIMC = () => {
    if (height && weight && imcOptions.length > 0) {
      let heightMts = height / 100
      let imc = weight / (heightMts * heightMts)

      let imcId = null
      if (imc < 18.5) {
        imcId = `${imc.toFixed(1)} - ${imcOptions[0].value}`
      } else if (imc >= 18.5 && imc <= 24.9) {
        imcId = `${imc.toFixed(1)} - ${imcOptions[1].value}`
      } else if (imc >= 25 && imc <= 29.9) {
        imcId = `${imc.toFixed(1)} - ${imcOptions[2].value}`
      } else if (imc >= 30) {
        imcId = `${imc.toFixed(1)} - ${imcOptions[3].value}`
      }
      setImcId(imcId)
    }
  }

  useEffect(() => {
    getIMCOptions();
  
    if (editData) {
      setDataEdit(editData);
    }
  }, [editData]); // Se ejecuta solo cuando cambia `editData`
  
  useEffect(() => {
    FormatData();
    CalculateIMC();
    validateSection();
  }, [height, weight, imcId]);

  return (
    <>
      <GridItem md={4}>
        <CustomInput
          value={height ? height : ''}
          labelText="Talla (en cm) *"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'number',
            disabled: onlyView,
          }}
          onChange={(value) => setHeight(value.target.value)}
          error={!height && save}
        />
      </GridItem>
      <GridItem md={4}>
        <CustomInput
          value={weight ? weight : ''}
          labelText="Peso (en kg) *"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'number',
            disabled: onlyView,
          }}
          onChange={(value) => setWeight(value.target.value)}
          error={!weight && save}
        />
      </GridItem>
      <GridItem md={4}>
        <CustomInput
          labelText="IMC *"
          value={imcId ? imcId : ''}
          invalid={!imcId && save}
          disabled={true}
        ></CustomInput>
      </GridItem>
    </>
  )
}

export default Measures

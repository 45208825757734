import './Customers.css';

import React, { Component } from 'react';

import Active from '@material-ui/icons/LockOpen';
import Add from '@material-ui/icons/Add';
import Assignment from '@material-ui/icons/Assignment';
import Button from '../CustomButtons/Button';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import CustomSweetAlert from '../CustomSweetAlert';
import Edit from '@material-ui/icons/Edit';
import FolderShared from '@material-ui/icons/FolderShared';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import HotelIcon from '@material-ui/icons/Hotel';
import Inactive from '@material-ui/icons/Lock';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import SelectInput from '../SelectInput';
import Snackbar from '../Snackbar/Snackbar';
import Table from '../Table/Table';
import Tooltip from '@material-ui/core/Tooltip';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class Customers extends Component {
    constructor(props) {
        super(props);
        const { t } = props;
        this.state = {
            customers: [],
            address: [],
            personAddress: [],
            loading: false,
            isPatient: false,
            filter: { actives: 'true' },
            actives: [{ id: "all", value: t("state.all") }, { id: "true", value: t("state.active") }, { id: "false", value: t("state.deactive") }],
            showDeactivate: false,
            showActive: false,
        }
    }

    componentWillMount() {
        const company_partnership_id = localStorage.getItem('company_partnership_id');
        const isPatient = localStorage.getItem('is_patient') == 'true';

        this.setState({ company_partnership_id, isPatient });

        if (this.props.address) {
            this.setState({ address: this.props.address }, () => {
                this.props.customers && this.formatCustomers(this.props.customers);
            });
        }
        this.getCustomers();
    }

    cleanFilters = () => {
        this.setState({
            filter: { actives: 'true' },
            customers: [],
        }, () => {
            this.getCustomers();
        });
    }

    findFilters = () => {
        this.setState({
            customers: [],
            loading: true,
        }, () => {
            this.getCustomers();
        })
    }

    componentWillReceiveProps(next) {
        if (next.customers) {
            this.formatCustomers(next.customers);
        }

        if (next.address) {
            this.setState({ address: next.address });
        }
    }

    handleValue(value, field) {
        const { filter } = this.state;
        filter[field] = value;
        this.setState({ filter });
    }

    getCustomers() {
        this.setState({ loading: true });
        let actives = 'true';
        if (this.state.filter.actives) {
            actives = this.state.filter.actives;
        }
        PersonApiInvoker.getCustomersList(false, actives, data => {
            this.setState({ loading: false });
            this.formatCustomers(data);
        }, () => {
            this.setState({ loading: false });
        });
    }

    formatCustomers = (customers) => {
        const { t } = this.props;
        const data = customers.map(c => {
            return {
                lastName: c.lastName.trim(),
                firstName: c.firstName.trim(),
                id: c.id,
                email: c.email,
                locationName: c.locationName,
                active: c.active ? t('employee.active') : t('employee.deactive'),
                provinceName: c.provinceName,
                age: moment().diff(c.birthdate, 'years', false),
                actions: (
                    <div className="customers-actions">
                        <Tooltip title={t('medical_record.titleHistory')}>
                            <div>
                                <Button id={`button-record-full-${c.personId}`} simple justIcon color="violet" authority="customers_view_mr" onClick={() => browserHistory.push(
                                    {
                                        pathname: `/patient/${c.personId}/medical-record/full/`
                                    }
                                )}>
                                    <FolderShared />
                                </Button>
                            </div>
                        </Tooltip>
                        <Tooltip title={t('medical_record.title')}>
                            <div>
                                <Button id={`button-history-${c.personId}`} simple justIcon color="primary" authority="customers_view_mr" onClick={() => this.historiaClinicaList(c.personId)}>
                                    <FolderShared />
                                </Button>
                            </div>
                        </Tooltip>
                        <Tooltip title={t('customers.tooltip.view')}>
                            <div>
                                <Button id={`button-view-${c.personId}`} data-testid={`button-view-${c.personId}`} simple justIcon color="primary" authority="customers_view,customers_view_related" onClick={() => browserHistory.push({
                                    state: { mode: 'view' },
                                    pathname: `/ver-paciente/${c.personId}`
                                })}>
                                    <Assignment />
                                </Button>
                            </div>
                        </Tooltip>
                        {this.state.company_partnership_id == null &&
                            <Tooltip title={t('customers.tooltip.edit')}>
                                <div>
                                    <Button id={`button-edit-${c.personId}`} simple justIcon color="success" authority="customers_edit" onClick={() => browserHistory.push(`/editar-paciente/${c.personId}`)}>
                                        <Edit />
                                    </Button>
                                </div>
                            </Tooltip>
                        }
                        
                        {c.active && this.state.company_partnership_id == null &&

                            <Tooltip title={this.props.t('tooltip.inactive')}>
                                <div>
                                    <Button
                                        id={`button-active-${c.personId}`} authority="customers_active_inactive" simple justIcon color="success"
                                        onClick={() => this.setState({ showActive: true, current: c })}>
                                        <Active />
                                    </Button>
                                </div>
                            </Tooltip>
                        }
                        {!c.active && this.state.company_partnership_id == null &&

                            <Tooltip title={this.props.t('tooltip.active')}>
                                <div>
                                    <Button id={`button-inactive-${c.personId}`} authority="customers_active_inactive" simple justIcon color="success"
                                        onClick={() => this.setState({ showDeactivate: true, current: c })}>
                                        <Inactive />
                                    </Button>
                                </div>
                            </Tooltip>
                        }
                    </div>
                )
            }
        });
        this.setState({ customers: data });
    }

    historiaClinicaList(id) {
        browserHistory.push(`/patient/${id}/medical-record`);
    }


    active = (active) => {
        this.setState({ showActive: false, showDeactivate: false });
        if (active) {
            PersonApiInvoker.customerActivate(this.state.current.personId, data => {
                this.getCustomers();
            }, (error) => {
                console.error('** error customerActivate', error);
                this.openAlert('danger', (error ? error.message : 'Error'));
            });
        } else {
            PersonApiInvoker.customerDeactivate(this.state.current.personId, data => {
                this.getCustomers();
            }, (error) => {
                console.error('** error customerDeactivate', error);
                this.openAlert('danger', (error ? error.message : 'Error'));
            });
        }
    }

    openAlert(color, message) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false });
        }, 5000);
    }

    render() {
        const { t } = this.props;
        return (
            <div className="customers">
                <GridContainer >
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="primary">
                                    <HotelIcon />
                                </CardIcon>
                                <Snackbar
                                    place="tr"
                                    color={this.state.alertColor}
                                    message={this.state.alertMessage}
                                    open={this.state.alertOpen}
                                />
                                {this.state.company_partnership_id == null && !this.state.isPatient &&
                                    <Button id="button-customer-add" className="add-content-button customers-button" round color="primary" authority="customers_new" onClick={() => browserHistory.push('nuevo-paciente')}>
                                        <Add className="customers-button-icon" /> {t('customer.create')}
                                    </Button>
                                }
                                <GridContainer className="customers-filters">
                                    <GridItem xs={12} sm={9} md={10} className="no-padding">
                                        <GridContainer className="filters-row">
                                            <GridItem xs={12} sm={8} md={4}>
                                                <SelectInput
                                                    className="no-padding"
                                                    label={t('employee.find.state')}
                                                    elements={this.state.actives}
                                                    value={this.state.filter.actives}
                                                    onSelectedValue={(value) => this.handleValue(value, 'actives')}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem xs={12} sm={3} md={2} className="no-padding">
                                        <GridContainer className="filters-actions">
                                            <GridItem xs={12}>
                                                <Button id="button-find" block onClick={() => this.findFilters()} color="primary">
                                                    {t('employee.find.find')}
                                                </Button>
                                            </GridItem>
                                            <GridItem xs={12}>
                                                <Button id="button-clean" block onClick={() => this.cleanFilters()} color="danger">
                                                    {t('employee.find.clean')}
                                                </Button>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody className="not-scroll-table filtrable-table">
                                <Table
                                    striped
                                    filterable
                                    loading={this.state.loading}
                                    tableHeaderColor="primary"
                                    tableHead={[
                                        {
                                            Header: t('customers.table.last_name'), accessor: 'lastName',
                                            sortMethod: (a, b) => (a.localeCompare(b))
                                        },
                                        {
                                            Header: t('customers.table.first_name'), accessor: 'firstName',
                                            sortMethod: (a, b) => (a.localeCompare(b))
                                        },
                                        { Header: t('customers.table.id'), accessor: 'id' },
                                        { Header: t('customers.table.locationName'), accessor: 'locationName' },
                                        { Header: t('customers.table.provinceName'), accessor: 'provinceName' },
                                        { Header: t('customers.table.email'), accessor: 'email' },
                                        { Header: t('customers.table.active'), accessor: 'active' },
                                        { Header: t('customers.table.actions'), accessor: 'actions', sortable: false, filterable: false }
                                    ]}
                                    tableData={this.state.customers}
                                    colorsColls={['primary']}
                                />
                            </CardBody>
                            {this.state.showActive &&
                                <CustomSweetAlert
                                    type="information"
                                    title={t('customer.active.popup.title')}
                                    onConfirm={() => this.active(false)}
                                    confirmBtnCssClass={"btn primary"}
                                    confirmBtnText={t('common.accept')}
                                    onCancel={() => this.setState({ showActive: false })}
                                    cancelBtnCssClass="btn danger"
                                    cancelBtnText={t('cancel')}
                                    showCancel={true}
                                    message={
                                        <div className="schedule-errors-list">
                                            <span className="schedule-errors-item">
                                                {t('customer.deactivate.popup.message')}
                                            </span>
                                        </div>
                                    }
                                />
                            }
                            {this.state.showDeactivate &&
                                <CustomSweetAlert
                                    type="information"
                                    title={t('customer.active.popup.title')}
                                    onConfirm={() => this.active(true)}
                                    confirmBtnCssClass={"btn primary"}
                                    confirmBtnText={t('common.accept')}
                                    onCancel={() => this.setState({ showDeactivate: false })}
                                    cancelBtnCssClass="btn danger"
                                    cancelBtnText={t('cancel')}
                                    showCancel={true}
                                    message={
                                        <div className="schedule-errors-list">
                                            <span className="schedule-errors-item">
                                                {t('customer.active.popup.message')}
                                            </span>
                                        </div>
                                    }
                                />
                            }
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}


Customers.propTypes = {
    t: PropTypes.func,
    customers: PropTypes.array,
    address: PropTypes.array,
}

export default withTranslation()(Customers);

import React from 'react';
import {
    FilledInput,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Button from '../CustomButtons/Button';
import CustomSweetAlert from '../CustomSweetAlert';
import { Key } from '../../icons';
import PropTypes from 'prop-types';
import Snackbar from '../Snackbar/Snackbar';
import { withTranslation } from 'react-i18next';
import { useChangePassword } from './hook/useChangePassword';


const useStyles = makeStyles(() => ({
    validatePassList: {
        color: '#3C4858',
        fontSize: '0.875rem',
        lineHeight: 1.2,
        opacity: .7,
        paddingLeft: 12,
    },
    changePass: {
        maxWidth: 350,
        margin: 'auto',
        padding: '5px 20px 30px',
        '& .MuiGrid-item': {
            marginTop: 12,
            display: 'flex',
            flexDirection: 'column',
            '& .helper-text': {
                lineHeight: 1.2,
                fontSize: '0.8rem',
                '&:not(.Mui-error)': {
                    opacity: '0.7',
                },
            }
        },
    },
    submitButton: {
        marginTop: '20px !important',
    }
}));



const ChangePassword = ({ t, email, onSubmitChangePass }) => {
    const {handleSubmitPass, setPassword, setAlertSave,checkInputsValidity,onSubmitPass, handlePassValue,isDisabledSubmit, alertSave, validPassword,password, snackbar} = useChangePassword(t, email, onSubmitChangePass)
    const classes = useStyles();
    return (
        <>
            <Snackbar
                place="tr"
                color={snackbar.color}
                message={snackbar.message}
                open={snackbar.open}
            />
            <form
                className="pass-user-form"
                id="pass-form"
                onSubmit={handleSubmitPass}
                onKeyDown={({key}) => key === 'Enter' && onSubmitPass}
                data-testid="pass-form"
            >
                <Grid container justify="center" className={classes.changePass}>
                    <Grid item xs={12}>
                        <FormControl variant="filled">
                            <FilledInput
                                id="password"
                                inputProps={{
                                    placeholder: t('label.currentPass')
                                }}
                                type={!password.showCurrentValue ? 'password' : 'text'}
                                startAdornment={<InputAdornment position="start"><Key /></InputAdornment>}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setPassword((prev) => ({ ...prev, showCurrentValue : !password.showCurrentValue }))}
                                            edge="end"
                                        >
                                            {password.showCurrentValue ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(event) => handlePassValue(event.target.value, 'currentValue')}
                                value={password.currentValue}
                                error={!password.currentValue && password.isSubmit}
                                onKeyUp={checkInputsValidity}
                            />
                        </FormControl>
                        <FormHelperText className="helper-text">
                            {t('changePassword.currentPassDescription')}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        
                        <FormControl variant="filled">
                            <FilledInput
                                id="newPassword"
                                inputProps={{
                                    placeholder: t('restorePassword.newPass')
                                }}
                                type={!password.showValue ? 'password' : 'text'}
                                startAdornment={<InputAdornment position="start"><Key /></InputAdornment>}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setPassword((prev) => ({ ...prev, showValue : !password.showValue }))}
                                            edge="end"
                                            data-testid="togglePassVisib"
                                        >
                                            {password.showValue ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(event) => handlePassValue(event.target.value, 'value')}
                                value={password.value}
                                onKeyUp={checkInputsValidity}
                            />
                        </FormControl>
                        {(!validPassword.isNewValid && !!(password.value)) &&
                            <FormHelperText error className="helper-text">
                                {t('validatePass.error')}
                            </FormHelperText>
                        }
                        <div className="helper-text">
                            <ul className={classes.validatePassList}>
                                <li>{t('validatePass.isCaseSensitive')}</li>
                                <li>{t('validatePass.text1')}</li>
                                <li>{t('validatePass.text2')}</li>
                                <li>{t('validatePass.text3')}</li>
                                <li>{t('validatePass.text4')}</li>
                            </ul>
                        </div>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <FormControl variant="filled">
                            <FilledInput
                                id="repeatPassword"
                                inputProps={{
                                    placeholder: t('restorePassword.repeatPass')
                                }}
                                type={!password.showRepeatValue ? 'password' : 'text'}
                                startAdornment={<InputAdornment position="start"><Key /></InputAdornment>}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setPassword((prev) => ({ ...prev, showRepeatValue: !password.showRepeatValue }))}
                                            edge="end"
                                        >
                                            {password.showRepeatValue ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(event) => handlePassValue(event.target.value, 'repeatValue')}
                                value={password.repeatValue}
                                error={!password.repeatValue && password.isSubmit}
                                onKeyUp={checkInputsValidity}
                            />
                        </FormControl>
                        {!password.isMatchPasswords &&
                            <FormHelperText error className="helper-text">
                                {t('restorePassword.notMatchPass')}
                            </FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={12} className={classes.submitButton}>
                        <Button
                            onClick={onSubmitPass}
                            className="submit-button"
                            variant="contained"
                            fullWidth={true}
                            disabled={isDisabledSubmit}
                            color="primary"
                            id="button-accept"
                        >
                            {t('common.accept')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            {alertSave &&
                <CustomSweetAlert
                    type="warning"
                    title={t('label.changePass')}
                    confirmBtnCssClass="primary"
                    confirmBtnText={t('common.accept')}
                    onConfirm={handleSubmitPass} 
                    showCancel={true}
                    cancelBtnCssClass="danger"
                    cancelBtnText={t('common.cancel')}
                    onCancel={() => setAlertSave(false)}
                    message={<p>{t('changePassword.confirmText')}</p>}
                />
            }
        </>
    )
}

ChangePassword.propTypes = {
    t: PropTypes.func,
    email: PropTypes.string,
    company: PropTypes.object,
    onSubmitChangePass: PropTypes.func.isRequired,
}

export default withTranslation()(ChangePassword);

import { Collapse, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import Button from '../../CustomButtons/Button'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Medicine } from '../interfaces'
import MedicinesForm from './MedicinesForm'
import { useClinicalHistory } from '../hooks/useClinicalHistory'
import { useTranslation } from 'react-i18next'

const MAX_MEDICATIONS = 3

interface propsMedPage {
  handleSaveMedicine: (medicine: Medicine) => void
  data: Medicine[]
  isFormVisible: boolean
  handleAddMedicine: () => void
  handleEditMedicine: (medicine: Medicine) => void
  setIsFormVisible: (value: boolean) => void
  handleMedicineFieldChange: (
    field: keyof Medicine,
    value: Medicine[keyof Medicine],
    data: Medicine
  ) => void
  currentMedicine: Medicine
  disabled: boolean
}

const MedicinesPage: React.FC<propsMedPage> = ({
  handleSaveMedicine,
  handleAddMedicine,
  setIsFormVisible,
  handleMedicineFieldChange,
  handleEditMedicine,
  isFormVisible,
  data,
  currentMedicine,
  disabled,
}) => {
  const {
    getMedicineResources,
    medicineTypes,
    medicineViaTypes,
    medicinePresentationTypes,
    medicineUnitTypes,
  } = useClinicalHistory()

  const { t } = useTranslation()

  const translations = {
    medicinesTitle: t('clinicalHistory.medicines.registerTitle'),
    addLabel: t('dashboardeconomictool.add'),
    medicineLabel: t('medical.record.detail.medicines.medicine'),
    presentationLabel: t('medical.record.detail.medicines.presentation'),
    doseLabel: t('medical.record.detail.medicines.dosis'),
    administeredUnit: t('clinicalHistory.administeredUnit'),
    administrationType: t('clinicalHistory.administrationType'),
    undefinedLabel: t('common.undefined'),
    quantityLabel: t('service.quantity'),
    lotNumberLabel: t('clinicalHistory.lotNumber'),
    expirationDate: t('clinicalHistory.expirationDate'),
  }

  useEffect(() => {
    getMedicineResources()
  }, [])
  const [expanded, setExpanded] = useState<Record<number, boolean>>({})

  const toggleExpand = (id: number) => {
    setExpanded((prev) => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  const getNameById = (
    id: number,
    list: {
      medicineTypeId?: number | null
      medicineViaTypeId?: number | null
      medicinePresentationTypeId?: number | null
      medicineUnitTypeId?: number | null
      name: string | null
    }[]
  ) => {
    const item = list.find(
      (item) =>
        item.medicineTypeId === id ||
        item.medicineViaTypeId === id ||
        item.medicinePresentationTypeId === id ||
        item.medicineUnitTypeId === id
    )
    return item ? item.name : translations?.undefinedLabel
  }

  const getLabelRegister = (index: number, time: string): string => {
    return t('clinicalHistory.registerNumberAndTime', {
      number: index + 1,
      time,
    })
  }

  return (
    <>
      {!isFormVisible ? (
        <div>
          <Typography variant="h6" className="step-title">
            {translations?.medicinesTitle}
          </Typography>
          {!disabled && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '16px 0',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleAddMedicine()
                  setIsFormVisible(true)
                }}
                disabled={data?.length >= MAX_MEDICATIONS}
                style={{ margin: '16px 0' }}
              >
                {translations?.addLabel}
              </Button>
            </div>
          )}

          <div className="content-form">
            {data?.map((medicine, index) => (
              <Grid
                container
                key={medicine.scheduleMedicineId}
                className="step"
              >
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1">
                    {getLabelRegister(
                      index,
                      new Date(
                        medicine?.medicineDateTime ?? ''
                      ).toLocaleString()
                    )}
                  </Typography>
                  {expanded[medicine.scheduleMedicineId] ? (
                    <ExpandLessIcon
                      onClick={() => toggleExpand(medicine.scheduleMedicineId)}
                    />
                  ) : (
                    <ExpandMoreIcon
                      onClick={() => toggleExpand(medicine.scheduleMedicineId)}
                    />
                  )}
                </Grid>
                <Collapse
                  in={expanded[medicine.scheduleMedicineId]}
                  className="content-collapse"
                >
                  <div className="collapse-item">

                    <div style={{ marginTop: '8px' }}>
                      <Typography>
                        <strong>{translations?.medicineLabel}:</strong>{' '}
                        {getNameById(
                          medicine?.medicineType?.medicineTypeId ?? 0,
                          medicineTypes
                        )}
                      </Typography>
                      <Typography>
                        <strong>{translations?.presentationLabel}:</strong>{' '}
                        {getNameById(
                          medicine.medicinePresentationType
                            ?.medicinePresentationTypeId ?? 0,
                          medicinePresentationTypes
                        )}
                      </Typography>
                      <Typography>
                        <strong>{translations?.doseLabel}:</strong>{' '}
                        {medicine.medicineDose ?? translations?.undefinedLabel}
                      </Typography>
                      <Typography>
                        <strong>{translations?.administeredUnit}:</strong>{' '}
                        {getNameById(
                          medicine?.medicineUnitType?.medicineUnitTypeId ?? 0,
                          medicineUnitTypes
                        )}
                      </Typography>
                      <Typography>
                        <strong>{translations?.administrationType}:</strong>{' '}
                        {getNameById(
                          medicine.medicineViaType?.medicineViaTypeId ?? 0,
                          medicineViaTypes
                        )}
                      </Typography>
                      <Typography>
                        <strong>{translations?.quantityLabel}:</strong>{' '}
                        {medicine.medicineQuantity ?? translations?.undefinedLabel}
                      </Typography>
                      <Typography>
                        <strong>{translations?.lotNumberLabel}:</strong>{' '}
                        {medicine.medicineLotNumber ?? translations?.undefinedLabel}
                      </Typography>
                      <Typography>
                        <strong>{translations?.expirationDate}:</strong>{' '}
                        {medicine.medicineExpireDate
                          ? new Date(
                              medicine.medicineExpireDate
                            ).toLocaleDateString()
                          : translations?.undefinedLabel}
                      </Typography>
                    </div>
                    {!disabled && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginBottom: '8px',
                        }}
                      >
                        <Button
                          simple
                          size="lg"
                          {...(false
                            ? { muiClasses: { root: {} } }
                            : { color: 'primary' })}
                          className="button-edit-medicine"
                          onClick={() => {
                            handleEditMedicine(medicine)
                            setIsFormVisible(true)
                          }}
                        >
                          {t('common.edit')}
                        </Button>
                      </div>
                    )}
                  </div>
                </Collapse>
              </Grid>
            ))}
          </div>
        </div>
      ) : (
        <MedicinesForm
          medicineTypes={medicineTypes}
          setIsFormVisible={setIsFormVisible}
          medicineViaTypes={medicineViaTypes}
          medicinePresentationTypes={medicinePresentationTypes}
          medicineUnitTypes={medicineUnitTypes}
          handleSaveMedicine={handleSaveMedicine}
          handleMedicineFieldChange={handleMedicineFieldChange}
          currentMedicine={currentMedicine}
          disabled={disabled}
        />
      )}
    </>
  )
}
export default MedicinesPage

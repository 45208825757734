import { connect } from 'react-redux';
import DashboardHospitalizations from '../components/DashboardsPB/DashboardHospitalizationsIndex';

const mapStateToProps = (state) => {
    return {
        
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}

const DashboardPBHospitalizationsService = connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardHospitalizations);

export default DashboardPBHospitalizationsService;
import { AlertWarning, SnackbarColor } from '../../../../util/interfaces'
import { Edit, InfoOutlined, MonetizationOn } from '@material-ui/icons'
import {
  GetSchedulesFeesByPracticeTypeConnectionError,
  GetSchedulesFeesByPracticeTypeError,
  GetSchedulesFeesByPracticeTypeNotFoundError,
  GetSchedulesFeesByPracticeTypeUsecase,
  ScheduleFee,
  ScheduleFeeRequest,
  ScheduleStatus,
  UpdateScheduleFeeConnectionError,
  UpdateScheduleFeeError,
  UpdateScheduleFeeUsecase,
} from '../../../../core'
import React, { useEffect, useState } from 'react'
import { SnackbarProps, Tooltip, makeStyles } from '@material-ui/core'
import { TableHead, TypeAlert, useFees } from './useFees'
import { initialValueAlert, initialValueSnackbar } from './data'

import Button from '../../../CustomButtons/Button'
import CheckInput from '../../../CheckInput'
import CustomDialog from '../../../CustomDialog'
import { DIContainer } from '../../../../Root'
import FeeAmountForm from './FeeAmountForm'
import FeesWarningDialog from './FeesWarningDialog'
import GridContainer from '../../../Grid/GridContainer'
import GridItem from '../../../Grid/GridItem'
import PropTypes from 'prop-types'
import Snackbar from '../../../Snackbar/Snackbar'
import Table from '../../../Table/Table'
import config from '../../../../config/config'
import moment from 'moment'
import useStateRef from 'react-usestateref'
import { useTranslation } from 'react-i18next'
import { warningColor } from '../../../../assets/components/material-dashboard-pro-react'

type DataFee = {
  fromDate: string
  toDate: string
  amount?: number
}
type ComponentProps = {
  practiceFeesId: number
  data: DataFee
}

interface ScheduleFeeItem extends ScheduleFee {
  check: JSX.Element
  scheduleStatusLabel: string
  scheduleDateTimeLabel: string
  isPaidLabel: string
  actions: JSX.Element
  enabledEdit: boolean
  enabledLiquidate: boolean
  amountValue?: string
}

interface Liquidable {
  isLiquidable: boolean
  schedulesSelectedToLiquidate: number[]
  onOriginalSelectedExcluded: boolean
}

const useStylesFees = makeStyles({
  titleTable: {
    fontSize: '1.1rem',
    textAlign: 'center',
    '&>span': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
  },
  tooltipInfo: {
    paddingLeft: 3,
    '& svg': {
      color: warningColor[0],
      paddingTop: 4,
    },
  },
  footerButtons: {
    paddingTop: '30px !important',
    textAlign: 'right',
  },
})

const dateFormat = config.getDateTimeSmallFormat()

const SchedulesFees = (props: ComponentProps) => {
  const { t } = useTranslation()
  const [sortDate] = useFees()

  const getUsecase = DIContainer.get<GetSchedulesFeesByPracticeTypeUsecase>(
    GetSchedulesFeesByPracticeTypeUsecase
  )
  const updateUsecase = DIContainer.get<UpdateScheduleFeeUsecase>(
    UpdateScheduleFeeUsecase
  )

  const { practiceFeesId, data } = props
  const { fromDate, toDate, amount } = data

  const classes = useStylesFees()

  const [loading, setLoading] = useState<boolean>(false)
  const [snackbar, setSnackbar] = useState<SnackbarProps>(initialValueSnackbar)
  const [schedulesFees, setSchedulesFees, refSchedulesFees] = useStateRef<
    ScheduleFeeItem[] | []
  >([])
  const [schedulesFeesOriginal, setSchedulesFeesOriginal] = useStateRef<
    ScheduleFee[] | []
  >([])
  const [selectedSchedules, setSelectedSchedules, refSelectedSchedules] =
    useStateRef<number[]>([])
  const [checkAllSchedulesFees, setCheckAllSchedulesFees] =
    useState<boolean>(false)
  const [alertWarning, setAlertWarning] =
    useState<AlertWarning>(initialValueAlert)
  const [openForm, setOpenForm] = useState<boolean>(false)

  const openSnackbar = (color: SnackbarColor, message: string): void => {
    setSnackbar({ color, message, open: true })
    setTimeout(() => {
      setSnackbar(initialValueSnackbar)
    }, 5000)
  }

  const renderTableHead = (): TableHead[] => {
    return [
      {
        Header: (
          <div>
            <CheckInput
              onChangeValue={() => onSelectAllSchedules(!checkAllSchedulesFees)}
              checked={checkAllSchedulesFees}
            />
          </div>
        ),
        sortable: false,
        filterable: false,
        accessor: 'check',
        width: 60,
      },
      {
        Header: t('medical.record.detail.scheduleId'),
        accessor: 'scheduleId',
        width: 100,
      },
      {
        Header: t('common.dateTime'),
        accessor: 'scheduleDateTimeLabel',
        sortMethod: (a: string, b: string) => sortDate(a, b),
      },
      { Header: t('label.statusAppointment'), accessor: 'scheduleStatusLabel' },
      { Header: t('label.feesAmount'), accessor: 'amountValue' },
      { Header: t('label.statusFees'), accessor: 'isPaidLabel' },
      { Header: t('actions'), accessor: 'actions' },
    ]
  }

  const selectedAllSchedules = () => {
    const filterSchedulesEnabled = schedulesFees.filter(
      (schedule) => schedule.enabledEdit || schedule.enabledLiquidate
    )
    const schedSelected = filterSchedulesEnabled.map(
      (schedule) => schedule.scheduleId
    )
    return schedSelected
  }

  const onSelectAllSchedules = (value: boolean): void => {
    setCheckAllSchedulesFees(value)
    let schedSelected: number[] = []
    if (value) {
      schedSelected = selectedAllSchedules()
    }
    const data = formatSchedulesFees(schedulesFeesOriginal, schedSelected)
    setSchedulesFees(data)
  }

  const removeElement = (scheduleId: number, array: number[]): number[] =>
    array.filter((item) => item !== scheduleId)

  const updateSelected = (scheduleId: number): number[] => {
    const updatedSelected = [...refSelectedSchedules.current]
    if (updatedSelected.includes(scheduleId)) {
      return removeElement(scheduleId, updatedSelected)
    }
    updatedSelected.push(scheduleId)
    return updatedSelected
  }

  const onSelectSchedule = (scheduleId: number) => {
    setCheckAllSchedulesFees(false)
    const selected = updateSelected(scheduleId)
    const data = formatSchedulesFees(refSchedulesFees.current, selected)
    setSchedulesFees(data)
  }

  const handlerSelectedMasive = (mode: TypeAlert, selected: number[]) => {
    if (mode === TypeAlert.EDIT) {
      onEdit(selected)
      return
    }

    const { onOriginalSelectedExcluded, isLiquidable } =
      schedulesFeesToLiquidate(selected)
    if (!isLiquidable) {
      const message =
        selected.length > 1
          ? t('fees.areNotAvailable')
          : t('fees.isNotAvailable')
      openSnackbar(SnackbarColor.danger, message)
      setCheckAllSchedulesFees(false)
      const data = formatSchedulesFees(refSchedulesFees.current, [])
      setSchedulesFees(data)
      return
    }
    const message = onOriginalSelectedExcluded
      ? t('fees.settle.notAll')
      : t('fees.settle.inBulk.message')

    setAlertWarning({
      message,
      show: true,
      type: TypeAlert.LIQUIDATE,
    })
  }

  const onSelectMasiveSchedules = (mode: TypeAlert): void => {
    if (!selectedSchedules.length) {
      onSelectAllSchedules(true)
      const schedSelected = selectedAllSchedules()
      handlerSelectedMasive(mode, schedSelected)
      return
    }
    handlerSelectedMasive(mode, selectedSchedules)
  }

  const onCloseAlert = () => {
    setAlertWarning(initialValueAlert)
  }

  const onEdit = (selected: number[]) => {
    if (selected.length === 1) {
      setOpenForm(true)
      return
    }
    const isEditable = isSchedulesFeesToEdit(selected)
    if (!isEditable) {
      openSnackbar(SnackbarColor.danger, t('fees.edit.notEditable'))
      return
    }
    setOpenForm(true)
  }

  const liquidateFee = async (
    schedulesSelectedToLiquidate: number[]
  ): Promise<void> => {
    const data = schedulesSelectedToLiquidate.map((schedule) => ({
      scheduleId: schedule,
      isPaid: true,
    }))
    await onUpdateSchedulesFee(data)
    updateDataTable(data, 'isPaid')
    const messageUpdated =
      data.length > 1
        ? t('fees.updateMessagePaid.inBulk')
        : t('fees.updateMessagePaid')
    openSnackbar(SnackbarColor.success, messageUpdated)
    onCloseAlert()
  }

  const onLiquidate = async (): Promise<void> => {
    const { schedulesSelectedToLiquidate } =
      schedulesFeesToLiquidate(selectedSchedules)
    await liquidateFee(schedulesSelectedToLiquidate)
  }

  const onConfirmAlert = async (isMultiple: boolean = true): Promise<void> => {
    if (isMultiple) {
      await onLiquidate()
      return
    }
    await liquidateFee(selectedSchedules)
  }

  const schedulesFeesToLiquidate = (selectedScheds: number[]): Liquidable => {
    const schedulesFeesAvailableToLiquidate = schedulesFees.filter(
      (schedule) => {
        return schedule.enabledLiquidate
      }
    )
    const schedulesSelectedToLiquidate = schedulesFeesAvailableToLiquidate
      .filter((schedule) => {
        return selectedScheds.includes(schedule.scheduleId)
      })
      .map((sch) => sch.scheduleId)

    return {
      isLiquidable: schedulesSelectedToLiquidate.length > 0,
      schedulesSelectedToLiquidate: schedulesSelectedToLiquidate,
      onOriginalSelectedExcluded:
        schedulesSelectedToLiquidate.length !== selectedScheds.length,
    }
  }

  const getSchedulesToEdit = () => {
    const schedulesFeesAvailableToEdit = schedulesFees.filter((schedule) => {
      return isScheduleFeeToEdit(schedule.scheduleStatus, schedule.isPaid)
    })
    return schedulesFeesAvailableToEdit
  }

  const isSchedulesFeesToEdit = (selectedScheds: number[]): boolean => {
    const schedulesFeesAvailableToEdit = getSchedulesToEdit()
    const schedulesSelectedToEdit = schedulesFeesAvailableToEdit.filter(
      (schedule) => {
        return selectedScheds.includes(schedule.scheduleId)
      }
    )

    return schedulesSelectedToEdit.length > 0
  }

  const isScheduleFeeToEdit = (
    scheduleStatus: ScheduleStatus,
    isPaid: boolean
  ): boolean => {
    const scheduleStatusEdiAvailable = [
      ScheduleStatus.DONE,
      ScheduleStatus.APPROVED,
      ScheduleStatus.PENDING_APPROVAL,
      ScheduleStatus.IN_PROGRESS,
    ]
    return scheduleStatusEdiAvailable.includes(scheduleStatus) && !isPaid
  }

  const scheduleLiquidate = (scheduleId: number) => {
    setSelectedSchedules([])
    onSelectSchedule(scheduleId)
    setAlertWarning({
      message: t('fees.settle.message', { scheduleId }),
      show: true,
      type: TypeAlert.LIQUIDATE,
    })
  }

  const buildActionsSchedulesFees = (
    item: ScheduleFee,
    enabledEdit: boolean,
    enabledLiquidate: boolean
  ): JSX.Element => {
    const titleEdit = enabledEdit ? t('fees.edit') : ''
    const titleLiquidate = enabledLiquidate ? t('fees.settle') : ''

    const actions = (
      <>
        <Tooltip title={titleEdit}>
          <span>
            <Button
              simple
              justIcon
              id={`button-edit-${item.id}`}
              className="button-edit"
              color="success"
              onClick={() => onEditSchedule(item.scheduleId)}
              disabled={!enabledEdit}
            >
              <Edit />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={titleLiquidate}>
          <span>
            <Button
              simple
              justIcon
              id={`button-liquidate-${item.id}`}
              className="button-liquidate-fees"
              color="warning"
              onClick={() => scheduleLiquidate(item.scheduleId)}
              disabled={!enabledLiquidate}
            >
              <MonetizationOn />
            </Button>
          </span>
        </Tooltip>
      </>
    )
    return actions
  }

  const sortSchedulesFees = (data: ScheduleFeeItem[]): ScheduleFeeItem[] => {
    return data.sort(
      (a, b) => Date.parse(b.scheduleDateTime) - Date.parse(a.scheduleDateTime)
    )
  }

  const formatSchedulesFees = (
    data: ScheduleFee[],
    selectedSched: number[]
  ): ScheduleFeeItem[] => {
    if (!data.length) {
      return []
    }
    setSelectedSchedules(selectedSched)
    const schedFeesData = data.map((item: ScheduleFee) => {
      const enabledEdit = isScheduleFeeToEdit(item.scheduleStatus, item.isPaid)
      const enabledLiquidate =
        item.scheduleStatus === ScheduleStatus.DONE && !item.isPaid
      const isChecked = selectedSched.includes(item.scheduleId)
      const check = (
        <CheckInput
          onChangeValue={() => onSelectSchedule(item.scheduleId)}
          checked={isChecked}
          disabled={!enabledEdit && !enabledLiquidate}
        />
      )
      const amountValue = item.amount ? `$ ${item.amount.toFixed(2)}` : ''
      return {
        ...item,
        check,
        amountValue,
        scheduleDateTimeLabel: item.scheduleDateTime
          ? moment(item.scheduleDateTime).format(dateFormat)
          : '',
        scheduleStatusLabel: t(`status.${item.scheduleStatus}`),
        isPaidLabel: t(`fees.status.${item.isPaid}`),
        enabledEdit,
        enabledLiquidate,
        actions: buildActionsSchedulesFees(item, enabledEdit, enabledLiquidate),
      }
    })
    return sortSchedulesFees(schedFeesData)
  }

  const getDataErrors = (
    dataError: GetSchedulesFeesByPracticeTypeError
  ): void => {
    let message = t('common.errorService')
    if (dataError instanceof GetSchedulesFeesByPracticeTypeNotFoundError) {
      message = t('fees.schedules.notFoundMessage')
    }
    if (dataError instanceof GetSchedulesFeesByPracticeTypeConnectionError) {
      message = t('confirm.error')
    }
    openSnackbar(SnackbarColor.danger, message)
  }

  const getSchedulesFees = async (): Promise<void> => {
    setLoading(true)
    const result = await getUsecase.execute(practiceFeesId)

    if (result.isLeft()) {
      const dataError = result.getLeft()
      getDataErrors(dataError)
      setLoading(false)
      return
    }

    const resultData = result.getRight()
    const feesData = formatSchedulesFees(resultData, [])
    setSchedulesFees(feesData)
    setSchedulesFeesOriginal(resultData)
    setLoading(false)
  }

  const onEditSchedule = (scheduleId: number) => {
    setSelectedSchedules([])
    onSelectSchedule(scheduleId)
    setOpenForm(true)
  }

  const getAmountSchedule = (scheduleId: number) => {
    if (selectedSchedules.length === 1) {
      return schedulesFees.find((item) => item.scheduleId === scheduleId)
        ?.amount
    }
    return undefined
  }

  const onCloseForm = () => {
    setOpenForm(false)
    setCheckAllSchedulesFees(false)
    setSelectedSchedules([])
    setSchedulesFees(formatSchedulesFees(schedulesFeesOriginal, []))
  }

  const getUpdateDataErrors = (dataError: UpdateScheduleFeeError): void => {
    let message = t('common.errorService')
    if (dataError instanceof UpdateScheduleFeeConnectionError) {
      message = t('confirm.error')
    }
    openSnackbar(SnackbarColor.danger, message)
  }

  const onUpdateSchedulesFee = async (
    data: ScheduleFeeRequest[]
  ): Promise<void> => {
    const result = await updateUsecase.execute(data)

    if (result.isLeft()) {
      const dataError = result.getLeft()
      getUpdateDataErrors(dataError)
      setLoading(false)
      return
    }
    result.getRight()
    setAlertWarning(initialValueAlert)
  }

  const updateSchedulesFeeOnSuccess = (
    dataOriginal: ScheduleFee[],
    updatedData: ScheduleFeeRequest[],
    attribute: string
  ) => {
    updatedData.forEach((newEl) => {
      const existIndex = dataOriginal.findIndex(
        (existEl) => existEl.scheduleId === newEl.scheduleId
      )
      if (existIndex === -1) {
        return dataOriginal
      }
      if (attribute === 'totalAmount' && newEl.totalAmount) {
        dataOriginal[existIndex].amount = newEl.totalAmount
      }
      if (attribute === 'isPaid' && newEl.isPaid) {
        dataOriginal[existIndex].isPaid = newEl.isPaid
      }
    })
    return dataOriginal
  }

  const onUpdatedSchedule = (
    updatedData: ScheduleFeeRequest[],
    attributeUpdated: string
  ): ScheduleFee[] => {
    const newData = updateSchedulesFeeOnSuccess(
      schedulesFeesOriginal,
      updatedData,
      attributeUpdated
    )
    setSchedulesFeesOriginal(newData)
    return newData
  }

  const updateDataTable = (
    data: ScheduleFeeRequest[],
    attributeUpdated: string
  ) => {
    const newData = onUpdatedSchedule(data, attributeUpdated)
    const dataUpdateTable = formatSchedulesFees(newData, [])
    setSchedulesFees(dataUpdateTable)
  }

  const onUpdateScheduleFee = (
    data: ScheduleFeeRequest[],
    attributeUpdated: string
  ) => {
    updateDataTable(data, attributeUpdated)
    onCloseForm()
  }

  useEffect(() => {
    getSchedulesFees()
  }, [])

  const tableData = {
    loading,
    pageSizeOptions: [5, 10, 25, 50, 100],
    defaultPageSize: schedulesFees.length > 5 ? 20 : 5,
    tableHead: renderTableHead(),
    tableData: schedulesFees,
    colorsColls: ['primary'],
    showPaginationTop: false,
    showPaginationBottom: true,
  }

  const titleFeeForm =
    selectedSchedules.length === 1
      ? t('fees.edit.feeSchedule', { number: selectedSchedules[0] })
      : t('fees.edit.feeSchedules', { numbers: selectedSchedules.join(', ') })

  const titleTable =
    fromDate && toDate
      ? t('fees.datesFromToTitle', { fromDate, toDate })
      : t('fees.datesFromTitle', { fromDate })
  const tableTitleInfo = t('fees.scheduleTitleInfo')

  return (
    <>
      <Snackbar
        place="tr"
        color={snackbar.color}
        message={snackbar.message}
        open={snackbar.open}
      />
      <GridContainer>
        <GridItem xs={12} className="table-schedules-fees-list">
          <h4 className={classes.titleTable}>
            <span>{titleTable}</span>
            <Tooltip className={classes.tooltipInfo} title={tableTitleInfo}>
              <span>
                <InfoOutlined />
              </span>
            </Tooltip>
          </h4>
          <Table {...tableData} />
        </GridItem>
        <GridItem xs={12} className={classes.footerButtons}>
          <Button
            id="button-edit-fees"
            className="edit-fees"
            color="primary"
            onClick={() => onSelectMasiveSchedules(TypeAlert.EDIT)}
          >
            <Edit /> {t('fees.edit.inBulk')}
          </Button>
          <Button
            id="button-liquidate-fees"
            className="liquidate-fees"
            color="primary"
            onClick={() => onSelectMasiveSchedules(TypeAlert.LIQUIDATE)}
          >
            <MonetizationOn /> {t('fees.settle.inBulk')}
          </Button>
        </GridItem>
      </GridContainer>

      <FeesWarningDialog
        onConfirm={() => onConfirmAlert()}
        onCancel={() => onCloseAlert()}
        alertWarning={alertWarning}
      />

      <CustomDialog
        title={titleFeeForm}
        maxWidth="sm"
        open={openForm}
        onClose={() => onCloseForm()}
        className="fees-dialog"
      >
        <FeeAmountForm
          feeAmount={amount}
          scheduleAmount={getAmountSchedule(selectedSchedules[0])}
          selectedSchedules={selectedSchedules}
          onUpdateAmount={(data: ScheduleFeeRequest[]) =>
            onUpdateScheduleFee(data, 'totalAmount')
          }
        />
      </CustomDialog>
    </>
  )
}

SchedulesFees.propTypes = {
  practiceFeesId: PropTypes.number.isRequired,
  data: PropTypes.shape({
    fromTo: PropTypes.string,
    toDate: PropTypes.string,
    amount: PropTypes.number,
  }),
}

export default SchedulesFees

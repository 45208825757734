import './EditCustomer.css';

import { Address as AddressIcon } from '../../icons';
import React, { Component } from 'react';
import { TextField, Tooltip } from '@material-ui/core';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import Add from '@material-ui/icons/Add';
import Address from '../Address/address';
import AddressService from '../../containers/AddressService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardFooter from '../Card/CardFooter';
import CardHeader from '../Card/CardHeader';
import CardIcon from '../Card/CardIcon';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CustomDialog from '../CustomDialog';
import DateInput from '../DateInput';
import Financiers from '../Financiers';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import MedicalPlan from './MedicalPlan';
import Person from '@material-ui/icons/Person';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import Phone from '../Phone';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneService from '../../containers/PhoneService';
import PropTypes from 'prop-types';
import SelectInput from '../SelectInput';
import Snackbar from '../Snackbar/Snackbar';
import ValidationInput from '../ValidationInput';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import config from '../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import HistoriaClinicaApi from '../../api/HistoriaClinicaApi'
import Table from '../Table/Table';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Stethoscope from '../../icons/Stethoscope/index'
import ClinicalHistory from '../ClinicalHistory/ClinicalHistory';

const dateToServer = config.getDateToServer();

class EditCustomer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: this.props.location?.state?.mode || '',
            documentTypes: [],
            documents: [],
            nationalities: [],
            genderTypes: [],
            name: '',
            lastName: '',
            document: '',
            gender: '',
            documentType: '',
            email: '',
            birthDate: '',
            nationality: '',
            alertColor: 'info',
            alertMessage: '',
            alertOpen: false,
            personId: '',
            openAddress: false,
            openPhone: false,
            deleteAlert: false,
            address: null,
            phone: null,
            save: false,
            dataChanged: false,
            refresh: true,
            loading: false,
            firstPhone: false,
            phones:[],
            openPlan: false,
            code: '',
            judicialized: false,
            clinicalHistories: [],
            dateFormat: "",
            showClinicalHistory: false,
            orderSelected: ""
        }
    }

    componentWillMount() {
        this.getNationalities(this.findCustomer);
        this.getGenderTypes();
        this.getClinicalHistories(this.props.params.id)
        if (this.props.documentTypes && this.props.documentTypes.length > 0) {
            const documentTypes = this.formatDocumentTypes(this.props.documentTypes);
            this.setState({
                documentTypes,
                documents: this.props.documentTypes,
            });
            return;
        }
        this.getDocumentTypes();
    
    }

    componentWillReceiveProps(next) {
        if (next.documentTypes) {
            const documentTypes = this.formatDocumentTypes(next.documentTypes);
            this.setState({
                documentTypes,
                documents: next.documentTypes,
            });
        }
    }

    formatDocumentTypes(documentTypes) {
        const result = documentTypes.map(d => ({
            id: d.idTypeId,
            value: `${d.name} - ${d.type}`,
        }));
        return result;
    }

    setGenderTypesState(genderTypes) {
        const { t } = this.props;
        const genderTypesMap = {
            UNDEFINED: t('common.gender.undefined'),
            MALE: t('common.gender.male'),
            FEMALE: t('common.gender.female'),
        };
        const result = genderTypes.map(genderType => ({
            id: genderType,
            value: genderTypesMap[genderType],
        }));

        this.setState({ genderTypes: result });
    }

    getDocumentTypes() {
        PersonApiInvoker.getIdTypes(data => {
            this.props.onGetDocumentTypes(data);
        }, null);
    }

    getGenderTypes() {
        PersonApiInvoker.getGenderTypes(data => {
            this.setGenderTypesState(data);
        }, null);
    }

    findDocumentType = (id) => this.state.documents.find(d => d.idTypeId === id);

    openAlert(color, message) {
        this.setState({
            alertColor: color,
            alertMessage: message,
            alertOpen: true,
        });

        setTimeout(() => {
            this.setState({ alertOpen: false })
        }, 5000);
    }

    validEmail(value) {
        if (value.length === 0) return true;
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRex.test(value);
    }

    patchCustomer({ birthDate, document, documentType, email, gender, lastName, name, personId, judicialized }) {
        const { t } = this.props;
        PersonApiInvoker.patchCustomer({
            avatarUrl: '',
            id: document,
            birthdate: moment(birthDate).format(dateToServer),
            email,
            sex: gender,
            firstName: name,
            nationality: this.findNationality(),
            idType: this.findDocumentType(documentType),
            lastName,
            personId,
            metadata: {},
            verificationId: 0,
            qr: '',
            judicialized
        }, (data) => {
            this.props.onEditCustomer(data);
            this.setState({ dataChanged: false, loading: false });
            this.openAlert('success', t('customers.new.success'));
        }, (error) => {
            this.openAlert('danger', `Error: ${error.message}`);
        });
    }

    saveCustomer() {
        this.setState({ save: true, loading: true });
        const { t } = this.props;
        const { birthDate, document, documentType, email, gender, lastName, nationality, name, personId, judicialized } = this.state;
        const requiredFields = name && lastName && document && documentType !== '0' && birthDate && nationality && gender;

        if (requiredFields && this.validEmail(email)) {
            this.patchCustomer({ birthDate, document, documentType, email, gender, lastName, name, personId, judicialized });
            return;
        }
        const messageEmail = !this.validEmail(email) ? t('message.invalidEmail') : '';
        const messageRequired = !requiredFields ? t('appointment.new.requiredFields') : '';
        this.openAlert('warning', `${ messageRequired } ${ messageEmail }`);
        this.setState({ loading: false });
    }

    handleValue(value, state) {
        this.setState({ [state]: value, dataChanged: true, refresh: false });
    }

    showPlan() {
        this.setState({ openPlan: true });
    }

    findCustomer() {
        const { routeParams, t } = this.props;
        PersonApiInvoker.getCustomer(routeParams.id, customer => {
            this.setState({
                name: customer.firstName,
                lastName: customer.lastName,
                birthDate: customer.birthdate,
                nationality: this.loadNationality(customer),
                gender: customer.sex,
                email: customer.email,
                document: customer.id || '',
                documentType: customer.idType.idTypeId,
                personId: customer.personId,
                phones: customer.phone,
                code: customer.code || '',
                planName: customer.planName || '',
                judicialized: customer.judicialized,
            });
        }, (error) => {
            this.openAlert('danger', error.message || t('customers.404.error'))
            setTimeout(() => {
                browserHistory.goBack();
            }, 5000);
        });
    }

    getClinicalHistories(personId) {
        HistoriaClinicaApi.getClinicalHistories(
            personId,
            (response) => {
                this.setState({
                   clinicalHistories: response,
                   dateFormat: config.getDateTimeSmallFormat()

                });
            },
            (error) => {
                console.error('Error al obtener el historial clínico:', error);
            }
        );
    }

    getNationalities(findCustomer) {
        PersonApiInvoker.getNationalities(jsonResponse => {
            const nationalities = jsonResponse.map(n => ({
                id: n.nationalityId,
                name: n.name,
            }));
            this.setState({ nationalities }, findCustomer);
        }, findCustomer);
    }

    findNationality() {
        const nationalityObj = _.find(this.state.nationalities, nationalityItem => {
            return nationalityItem.id === this.state.nationality.id;
        });

        return {
            name: nationalityObj.name,
            nationalityId: nationalityObj.id
        };
    }

    loadNationality(customer) {
        const customerNationalityId = _.get(customer.nationality, 'nationalityId' ,'');
        let nationalityObj = _.find(this.state.nationalities, nationalityItem => {
            return nationalityItem.id === customerNationalityId;
        });

        return nationalityObj;
    }

    createAddress() {
        this.setState({ openAddress: true, address: null });
    }

    createPhone() {
        const { phones } = this.state;
        this.setState({ openPhone: true, phone: null, firstPhone: phones.length === 0 });
    }

    editAddress(address) {
        this.setState({ openAddress: true, address });
    }

    editPhone(phone) {
        this.setState({ openPhone: true, phone });
    }

    goBack() {        
        browserHistory.push('/patient');
    }

    closeClinicalHistory() {
        this.setState({
            showClinicalHistory: false,
        });
    }
    sortDate (a, b) {
        const a1 = moment(a, this.state.dateFormat).valueOf();
        const b1 = moment(b, this.state.dateFormat).valueOf();
        if(a1 < b1)
          return 1;
        else if(a1 > b1)
          return -1;
        else
          return 0;
      }

   formatTableDataSchedules (data)  {
        if (!this.state.clinicalHistories) {
          return [];
        } 
        return data.map((d) => {
          return { 
            ...d,
            scheduleFormatDateTime: moment(d.scheduleDateTime).format(this.state.dateFormat),
            actions:(
            <>
              <Tooltip title="Ver HC">
              <span>
                <Button
                  key={`key-$-${d.order}-hc`}
                  id={`button-view-${d.order}-hc`}
                  simple
                  justIcon
                  color="primary"
                  authority="customers_view_mr_view_schedule_evo"
                  onClick={() => this.setState({showClinicalHistory: true, orderSelected: d.scheduleId})}
                >
                  <Stethoscope />
                </Button>
              </span>
            </Tooltip>  
            </>
            )
          }
        });
      }

    render() {
        const { t } = this.props;
        const {
            address, alertColor, alertMessage, alertOpen,
            birthDate, code, planName,
            dataChanged, document, documentType, documentTypes, email, gender, genderTypes, orderSelected,
            lastName, mode, name, nationalities, nationality, openAddress, openPhone, showClinicalHistory,
            personId, phone, save, refresh, loading, firstPhone, openPlan, judicialized, clinicalHistories
        } = this.state;
        const maxDate = moment().format(dateToServer);
        const minDate = moment().subtract(120, 'years').format(dateToServer);
        return (
            <div className="edit-customer">
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="primary">
                                    <Person />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('customers.new.title.profile')}</h3>
                            </CardHeader>
                            <CardBody className="edit-customer-form">
                                <form>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <ValidationInput
                                                disabled={mode === 'view'}
                                                text={t('customers.new.last_name')}
                                                onChangeValue={(value) => this.handleValue(value, 'lastName')}
                                                value={lastName}
                                                invalid={lastName === '' && save}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <ValidationInput
                                                disabled={mode === 'view'}
                                                text={t('customers.new.first_name')}
                                                onChangeValue={(value) => this.handleValue(value, 'name')}
                                                value={name}
                                                invalid={name === '' && save}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <Autocomplete
                                                disabled={mode === 'view'}
                                                className="nationality-autocomplete select-input"
                                                options={nationalities}
                                                getOptionLabel={(option) => option.name ? option.name:""}
                                                getOptionSelected={option => option.id}
                                                renderInput={params => ( 
                                                    <TextField {...params} label={t('customers.new.nationality')} fullWidth/>
                                                )}
                                                onChange={(event, value) => this.handleValue(value, 'nationality')}
                                                value={nationality}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <SelectInput
                                                className="select-input"
                                                disabled={mode === 'view'}
                                                label={t('customers.new.id_type')}
                                                elements={documentTypes}
                                                onSelectedValue={(value) => this.handleValue(value, 'documentType')}
                                                value={documentType}
                                                invalid={documentType === '' && save}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <ValidationInput
                                                disabled={mode === 'view'}
                                                text={t('customers.new.id')}
                                                type="text"
                                                onChangeValue={(value) => this.handleValue(value, 'document')}
                                                value={document}
                                                invalid={document === '' && save} />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <SelectInput
                                                className="select-input"
                                                disabled={mode === 'view'}
                                                label={t('customers.new.gender')}
                                                elements={genderTypes}
                                                onSelectedValue={(value) => this.handleValue(value, 'gender')}
                                                value={gender} invalid={gender === '' && save}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} className='calendarPicker'>
                                            <DateInput text={t('customers.new.birth_date')}
                                                disabled={mode === 'view'}
                                                minDate
                                                min={minDate}
                                                max={maxDate}
                                                onChangeValue={(value) => this.handleValue(value, 'birthDate')}
                                                value={birthDate}
                                                invalid={birthDate ? birthDate <= maxDate : !birthDate && save}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <ValidationInput
                                                disabled={mode === 'view'}
                                                text={t('customers.new.email')}
                                                type="email"
                                                onChangeValue={(value) => this.handleValue(value, 'email')}
                                                value={email}
                                                invalid={!this.validEmail(email)}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={judicialized}
                                                        onChange={(e) => this.handleValue(e.target.checked, 'judicialized')}
                                                        name="judicialized"
                                                        color="primary"
                                                        disabled={mode === 'view'}
                                                        className="custom-checkbox" 
                                                    />
                                                }
                                                label={t('customers.new.judicialized')}
                                                className="required-text"
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <p className="required-text">
                                                <small>*</small> {t('customers.new.required')}
                                            </p>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                            </CardBody>
                            {(code || mode !== 'view') &&
                                <CardFooter>
                                    <GridContainer>
                                        {code &&
                                            <GridItem xs={mode === 'view' ? 12 : 6}>
                                                <p className="plan-item padding-horizontal">
                                                    <Tooltip title={t('label.medicalPlan.view')}>
                                                        <Button simple color="transparent" onClick={() => this.showPlan()}>
                                                            <CreditCardIcon /> <span className="link">{planName} <small>({code})</small></span>
                                                        </Button>
                                                    </Tooltip>
                                                </p>
                                            </GridItem>
                                        }
                                        {mode !== 'view' &&
                                            <GridItem xs={code ? 6 : 12} className="edit-customer-submit flex-end">
                                                <ButtonSpinner
                                                    onClick={() => this.saveCustomer()}
                                                    disabled={!dataChanged || loading}
                                                    label={t('customers.new.save')}
                                                    labelLoading={t('customers.new.saving')}
                                                    loading={loading}
                                                    typeButton="submit"
                                                    color="primary"
                                                    id="button-save-customer"
                                                />
                                            </GridItem>
                                        }
                                    </GridContainer>
                                </CardFooter>
                            }
                        </Card>
                    </GridItem>
                    <GridItem xs={12} className="address-list">
                        <Card>
                            <CardHeader icon>
                                <CardIcon color="secondary">
                                    <AddressIcon />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('customers.new.addresses')}</h3>
                            </CardHeader>
                            <CardBody>
                                <Address
                                    componenMode={mode === 'view' ? 'view': ''}
                                    personId={personId}
                                    refresh={refresh}
                                    onEditAddress={(addr) => this.editAddress(addr)}
                                />
                            </CardBody>
                            {mode !== 'view' &&
                                <CardFooter className="content-button-add flex-end">
                                    <Button className="edit-customer-create" color="primary" onClick={() => this.createAddress()}>
                                        <Add />
                                        {t('customers.new.create.address')}
                                    </Button>
                                </CardFooter>
                            }
                        </Card>
                        <Card className="phones-list">
                            <CardHeader icon>
                                <CardIcon color="primary">
                                    <PhoneIcon />
                                </CardIcon>
                                <h3 className="card-icon-title">{t('customers.new.phones')}</h3>
                            </CardHeader>
                            <CardBody>
                                <Phone
                                    componenMode={mode === 'view' ? 'view': ''}
                                    personId={personId} 
                                    refresh={refresh} 
                                    onEditPhone={(ph) => this.editPhone(ph)}
                                    onDeletePhone={(newPh) =>this.setState({ phones: newPh })}
                                    personType="customer"
                                />
                            </CardBody>
                            {mode === 'view' ? '' :
                                <CardFooter className="content-button-add flex-end">
                                    <Button
                                        id="button-add-phone"
                                        className="edit-customer-create"
                                        color="primary" 
                                        onClick={(ph,firstPh) => this.createPhone(ph, firstPh)}
                                    >
                                        <Add />
                                        {t('customers.new.create.phone')}
                                    </Button>
                                </CardFooter>
                            }
                        </Card>
                        <Financiers
                            componenMode={mode === 'view' ? 'view': ''}
                            personId={Number(personId)}
                            refresh={refresh}
                        />
                        <Snackbar
                            place="tr"
                            color={alertColor}
                            message={alertMessage}
                            open={alertOpen}
                        />
                        {personId &&
                            <CustomDialog
                                title={t('address.title')}
                                open={openAddress}
                                onClose={() => this.setState({ address: null, openAddress: false })}
                                maxWidth="lg"
                            >
                                <AddressService
                                    edit={address}
                                    personId={personId}
                                    onAddressSubmited={(_, notification) => {
                                        this.setState({ address: null, openAddress: false, refresh: true });
                                        this.openAlert('success', notification);
                                    }}
                                />
                            </CustomDialog>
                        }
                        {personId &&
                            <CustomDialog
                                title={t('phone.title')}
                                open={openPhone}
                                onClose={() => this.setState({ phone: null, openPhone: false })}
                            >
                                <PhoneService
                                    edit={phone}
                                    firstPhone={firstPhone}
                                    personId={personId}
                                    onPhoneSubmited={(data) => this.setState({ phone: null, openPhone: false, refresh:true, phones:[data]})}
                                    personType={'customer'}
                                />
                            </CustomDialog>
                        }
                    </GridItem>
                    <GridItem className="medical-visits" xs={12}>
          <Card className="filtrable-table">
            <CardHeader icon>
              <CardIcon color="primary">
                <DateRangeIcon />
              </CardIcon>
              <h3 className="card-icon-title">{t('clinicalHistory.hcRecords')}</h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {/* Visitas Lista */}
                <GridItem xs={12}>
                  <Table
                    loading={loading}
                    tableHeaderColor="primary"
                    sortable
                    filterable
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    defaultPageSize={5}
                    tableHead={[
                      { Header: t('technicalfile.table.orden'), accessor: 'scheduleId' },
                      { Header: t('appointment.title'), accessor: 'appointmentId' },
                      { Header: t('technicalfile.table.speciality'), accessor: 'practiceName' },
                      { Header: t('technicalfile.table.profesional'), accessor: 'employeeName' },
                      { Header: t('appointment.practice.table.prestador'), accessor: 'serviceProviderName' },
                      { Header: t('integralDashboardClinical.table.scheduleDate'), accessor: 'scheduleDate', sortMethod: (a, b) => this.sortDate(a, b) },
                      {
                        Header: t('technicalfile.table.actions'),
                        accessor: 'actions',
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    tableData={this.formatTableDataSchedules(clinicalHistories)}
                    colorsColls={['primary']}
                  />
                </GridItem>
                <CustomDialog
                    title="Historia Cínica"
                    open={showClinicalHistory}
                    onClose={() => this.closeClinicalHistory() }
                >
                    <ClinicalHistory closeModal={() => this.closeClinicalHistory()} scheduleId={orderSelected} action={"view"}/>
                </CustomDialog>
              </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
                    <GridItem className="actions-buttons text-center">
                        <Button id="button-back" onClick={this.goBack.bind(this)}>
                            {t('appointment.new.back')}
                        </Button>
                    </GridItem>
                </GridContainer>
                {openPlan &&
                    <MedicalPlan
                        open={openPlan}
                        data={{ code, email}}
                        onClosePlan={(value) => this.setState({ openPlan: value })}
                        openAlert={(value) => this.openAlert(value.type, value.message)}
                    />
                }
            </div>
        )
    }
}

EditCustomer.propTypes = {
    t: PropTypes.func,
    onGetDocumentTypes: PropTypes.func.isRequired,
    onEditCustomer: PropTypes.func.isRequired,
    location: PropTypes.object,
    routeParams: PropTypes.object,
    documentTypes: PropTypes.array,
}

export default withTranslation()(EditCustomer);

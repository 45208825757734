import { useEffect, useState } from 'react'

export const useUpDownImage = (
  initialFile: any,
  saveDocument: any,
  removeDocument: any
) => {
  const [file, setFile] = useState(initialFile || null)
  const [dragOver, setDragOver] = useState(false)

  useEffect(() => {
    if (initialFile !== null) {
      setFile(initialFile)
    }
  }, [initialFile])

  const handleRemoveFile = () => {
    setFile(null)
    saveDocument(null)
    removeDocument(file)
  }

  const handleDragOver = (event: any) => {
    event.preventDefault()
    setDragOver(true)
  }

  const handleDragLeave = () => {
    setDragOver(false)
  }

  return {
    setFile,
    setDragOver,
    handleDragOver,
    handleRemoveFile,
    handleDragLeave,
    dragOver,
    file,
  }
}

import '../../../DiabeticProgramStyles.css'
import React, { useEffect } from 'react'
import GridItem from '../../../../Grid/GridItem'
import RadioInput from '../../../../RadioInput'

const Nutrition = (props) => {
  const { setData, editData, onlyView } = props

  const [eatSalt, setEatSalt] = React.useState('no')
  const [eatVegetables, setEatVegetables] = React.useState('no')
  const [eatFruits, setEatFruits] = React.useState('no')

  const FormatData = () => {
    let data = {
      eatSalt: eatSalt == 'yes' ? true : false,
      eatVegetables: eatVegetables == 'yes' ? true : false,
      eatFruits: eatFruits == 'yes' ? true : false,
    }
    setData(data)
  }

  const setDataEdit = (data) => {
    setEatSalt(data.eatSalt === true ? 'yes' : 'no')
    setEatVegetables(data.eatVegetables === true ? 'yes' : 'no')
    setEatFruits(data.eatFruits === true ? 'yes' : 'no')
  }

  useEffect(() => {
    if (editData) {
      setDataEdit(editData);
    }
  }, [editData]); 
  
  useEffect(() => {
    FormatData();
  }, [eatSalt, eatVegetables, eatFruits]); 

  return (
    <>
      <GridItem md={12}>
        <h4>Nutrición</h4>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-diabetic">¿El paciente come con sal?:</p>
          <RadioInput
            value={eatSalt}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => setEatSalt(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-diabetic">
            ¿El paciente come verduras 3(tres) veces por semana?:
          </p>
          <RadioInput
            value={eatVegetables}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => setEatVegetables(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-diabetic">
            ¿El paciente come frutas 3(tres) veces por semana?:
          </p>
          <RadioInput
            value={eatFruits}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => setEatFruits(value)}
          />
        </div>
      </GridItem>
    </>
  )
}

export default Nutrition

import { useEffect, useState } from 'react'
import { PasswordValidator } from '../../../handlers/password-validator'
import { isEqual } from 'lodash'
import Cookies from 'universal-cookie'
const initialValueSnackbar = {
  color: 'success',
  message: '',
  open: false,
}

const initialValuePass = {
  isMatchPasswords: true,
  isSubmit: false,
  openNewPass: false,
  showCurrentValue: false,
  showRepeatValue: false,
  showValue: false,
  currentValue: '',
  repeatValue: '',
  value: '',
}

const initialValidPass = {
  isNewValid: true,
  isRepeatValid: true,
}

type LangKey = 'es' | 'en' | 'pt' | 'it' | 'fr'

interface ChangePasswordProps {
  email: string
  t: (key: string) => string
  onSubmitChangePass: () => void
}
export const useChangePassword = ({
  email,
  t,
  onSubmitChangePass,
}: ChangePasswordProps) => {
  const [password, setPassword] = useState(initialValuePass)
  const [validPassword, setValidPassword] = useState(initialValidPass)
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(true)
  const [snackbar, setSnackbar] = useState(initialValueSnackbar)
  const [alertSave, setAlertSave] = useState(false)

  useEffect(() => {
    return () => {
      setPassword(initialValuePass)
      setSnackbar(initialValueSnackbar)
      setAlertSave(false)
    }
  }, [])

  const langMap: Record<LangKey, string> = {
    es: 'es-ES',
    en: 'en-US',
    pt: 'pt-BR',
    it: 'it-IT',
    fr: 'fr-FR',
  }

  const openSnackbar = (color: string, message: string) => {
    setSnackbar((prev) => ({ ...prev, color, message, open: true }))
    setTimeout(() => {
      setSnackbar((prev) => ({ ...prev, open: false }))
    }, 5000)
  }
  const handlePassValue = (value: string, state: any) => {
    setPassword((prev) => ({ ...prev, [state]: value }))
  }

  const onSubmitPass = () => {
    if (!password.currentValue || !password.value || !password.repeatValue) {
      openSnackbar('warning', t('common.messageWarning.fieldsComplete'))
      return
    }
    if (password.currentValue && password.value && password.repeatValue) {
      if (!password.isMatchPasswords) {
        openSnackbar('warning', t('restorePassword.notMatchPass'))
        return
      }
      setAlertSave(true)
    }
  }

  const getLangByCookies = () => {
    const cookies = new Cookies()
    let lang = cookies.get('lang') as LangKey | null
    if (lang == null) {
      return langMap['es']
    } else {
      return langMap[lang] == null ? langMap['es'] : langMap[lang]
    }
  }

  const getTypeMessageByData = (data: any) => {
    let type = 'danger'
    let message = t('common.errorService') // code 1/7
    if (data.code === 0) {
      type = 'success'
      message = t('changePassword.success')
    }
    if (data.code === 6) {
      message = t('changePassword.errorCurrentPass')
    }
    if (data.code === 11) {
      message = t('validatePass.error.newPassUsername')
    }
    return { type, message }
  }

  const handleMatchPass = () => {
    const isValues = !!password.value && !!password.repeatValue
    if (isValues) {
      const isMatchPasswords = isEqual(password.value, password.repeatValue)
      setPassword((prev) => ({ ...prev, isMatchPasswords }))
      return
    }
    setPassword((prev) => ({ ...prev, isMatchPasswords: true }))
  }

  const checkInputsValidity = () => {
    const { currentValue, repeatValue, value } = password
    if (currentValue && (!value || !repeatValue)) {
      return setIsDisabledSubmit(true)
    }
    const isNewPasswordValid = PasswordValidator(value)
    const isValidConfirmPass = PasswordValidator(repeatValue)
    const isDisabledSubmit = !(
      currentValue &&
      isNewPasswordValid &&
      isValidConfirmPass
    )

    setValidPassword((prev) => ({
      ...prev,
      isNewValid: isNewPasswordValid,
      isRepeatValid: isValidConfirmPass,
    }))
    setIsDisabledSubmit(isDisabledSubmit)
    handleMatchPass()
  }

  const getFetchParams = (method: string, data: any, companyId: string) => ({
    headers: new Headers({
      'Content-Type': 'application/json',
      'x-itlg-companyId': companyId ?? undefined,
      'Accept-Language': getLangByCookies(),
    }),
    method,
    body: JSON.stringify({
      email,
      password: data.currentValue,
      newPassword: data.value,
    }),
  })
  const handleSubmitPass = async () => {
    setPassword((prev) => ({ ...prev, isSubmit: true }))
    setAlertSave(false)

    const { currentValue, value } = password
    const params = getFetchParams('POST', { currentValue, value }, '')
    try {
      const response = await fetch('/keychange/reset', params)
      const data = await response.json()
      const { type, message } = getTypeMessageByData(data)

      openSnackbar(type, message)
      if (data.code === 0) {
        // 200
        setTimeout(() => {
          onSubmitChangePass()
        }, 1500)
      }
    } catch (error) {
      console.error('** error keychange/reset', error)
      openSnackbar('danger', t('common.errorService'))
    }
  }

  return {
    getLangByCookies,
    setPassword,
    setAlertSave,
    checkInputsValidity,
    onSubmitPass,
    handlePassValue,
    handleSubmitPass,
    password,
    snackbar,
    validPassword,
    isDisabledSubmit,
    alertSave,
  }
}

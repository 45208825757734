import '../../../DiabeticProgramStyles.css'

import CustomInput from '../../../../CustomInput/CustomInput'
import DateInput from '../../../../DateInput'
import GridItem from '../../../../Grid/GridItem'
import RadioInput from '../../../../RadioInput'
import React from 'react'
import ValidationInput from '../../../../ValidationInput'
import { useEffect } from 'react'

const Cardiovascular = (props) => {
  const { setData, setValidateCardioSection, editData, onlyView, save } = props

  const [heartRate, setHeartRate] = React.useState(null)
  const [bloodPressureA, setBloodPressureA] = React.useState(null)
  const [bloodPressureB, setBloodPressureB] = React.useState(null)
  const [peripheralPulses, setPeripheralPulses] = React.useState('no')
  const [angina, setAngina] = React.useState('no')
  const [anginaDate, setAnginaDate] = React.useState(null)
  const [heartAttack, setHeartAttack] = React.useState('no')
  const [heartAttackDate, setHeartAttackDate] = React.useState(null)
  const [heartDisease, setHeartDisease] = React.useState('no')
  const [heartDiseaseDate, setHeartDiseaseDate] = React.useState(null)

  const [cardiovascularComments, setCardiovascularComments] =
    React.useState(null)

  let cardiovascularCommentsLenth = cardiovascularComments
    ? cardiovascularComments.length
    : 0

  const FormatData = () => {
    let data = {
      heartRate: heartRate,
      bloodPressureA: bloodPressureA,
      bloodPressureB: bloodPressureB,
      peripheralPulses: peripheralPulses === 'yes' ? true : false,
      angina: angina === 'yes' ? true : false,
      anginaDate: anginaDate,
      heartAttack: heartAttack === 'yes' ? true : false,
      heartAttackDate: heartAttackDate,
      heartDisease: heartDisease === 'yes' ? true : false,
      heartDiseaseDate: heartDiseaseDate,
      cardiovascularComments: cardiovascularComments,
    }
    setData(data)
  }

  const setEditData = (data) => {
    setHeartRate(data.heartRate)
    setBloodPressureA(data.bloodPressureA)
    setBloodPressureB(data.bloodPressureB)
    setPeripheralPulses(data.peripheralPulses === true ? 'yes' : 'no')
    setAngina(data.angina === true ? 'yes' : 'no')
    setAnginaDate(data.anginaDate)
    setHeartAttack(data.heartAttack === true ? 'yes' : 'no')
    setHeartAttackDate(data.heartAttackDate)
    setHeartDisease(data.heartDisease === true ? 'yes' : 'no')
    setHeartDiseaseDate(data.heartDiseaseDate)
    setCardiovascularComments(data.cardiovascularComments)
  }

  const validateSection = () => {
    if (
      (heartAttack === 'yes' && !heartAttackDate) ||
      (heartDisease === 'yes' && !heartDiseaseDate) ||
      (angina === 'yes' && !anginaDate) ||
      (heartRate && (heartRate > 500 || heartRate < 0)) ||
      (bloodPressureA && !bloodPressureB) ||
      (bloodPressureB && !bloodPressureA)
    ) {
      setValidateCardioSection(false)
    } else {
      setValidateCardioSection(true)
    }
  }

  useEffect(() => {
    if (editData) {
      setEditData(editData);
    }
  }, [editData]); // Se ejecuta solo cuando cambia `editData`
  
  useEffect(() => {
    FormatData();
    validateSection();
  }, [
    heartRate,
    bloodPressureA,
    bloodPressureB,
    peripheralPulses,
    angina,
    anginaDate,
    heartAttack,
    heartAttackDate,
    heartDisease,
    heartDiseaseDate,
    cardiovascularComments,
  ]);

  return (
    <>
      <GridItem md={12}>
        <h4>Cardiovascular</h4>
      </GridItem>
      <GridItem md={5}>
        <ValidationInput
          value={heartRate ? heartRate : ''}
          text="Frecuencia cardíaca (n° latidos por minuto)"
          formControlProps={{
            fullWidth: true,
          }}
          type="number"
          disabled={onlyView}
          onChangeValue={(value) => setHeartRate(value)}
          invalid={heartRate < 0 || heartRate > 500}
        />
      </GridItem>
      <GridItem md={7}></GridItem>
      <GridItem md={12}>
        <p className="label-radiobox-container-bloodPressure">
          Tensión arterial (n° de milímetros de mercurio)
        </p>
      </GridItem>
      <GridItem md={2}>
        <ValidationInput
          value={bloodPressureA}
          formControlProps={{
            fullWidth: true,
          }}
          onChangeValue={(value) => {
            if (value === '') {
              setBloodPressureA(null)
            } else {
              setBloodPressureA(value)
            }
          }}
          disabled={onlyView}
          type="number"
          invalid={
            bloodPressureA < 0 ||
            bloodPressureA > 500 ||
            (bloodPressureB && !bloodPressureA && save)
          }
        />
      </GridItem>
      <p className="division-bloodPressure">/</p>
      <GridItem md={2}>
        <ValidationInput
          value={bloodPressureB}
          formControlProps={{
            fullWidth: true,
          }}
          onChangeValue={(value) => {
            if (value === '') {
              setBloodPressureB(null)
            } else {
              setBloodPressureB(value)
            }
          }}
          disabled={onlyView}
          type="number"
          invalid={
            bloodPressureB < 0 ||
            bloodPressureB > 500 ||
            (bloodPressureA && !bloodPressureB && save)
          }
        />
      </GridItem>
      <GridItem md={8}></GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Pulsos periféricos:</p>
          <RadioInput
            value={peripheralPulses}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setPeripheralPulses(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <h5>Antecedentes</h5>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Angina:</p>
          <RadioInput
            value={angina}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setAnginaDate(null)
                setAngina(value)
              } else {
                setAngina(value)
              }
            }}
            disabled={false}
          />
          {angina === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setAnginaDate(value)}
              value={anginaDate}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
              disabled={onlyView}
              error={angina === 'yes' && !anginaDate}
              errorText={
                angina === 'yes' && !anginaDate ? 'Debe ingresar una fecha' : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Infarto:</p>
          <RadioInput
            value={heartAttack}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => setHeartAttack(value)}
            disabled={false}
          />
          {heartAttack === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setHeartAttackDate(value)}
              value={heartAttackDate}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
              error={heartAttack === 'yes' && !heartAttackDate}
              errorText={
                heartAttack === 'yes' && !heartAttackDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
              disabled={onlyView}
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Cardiopatías:</p>
          <RadioInput
            value={heartDisease}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => setHeartDisease(value)}
            disabled={false}
          />
          {heartDisease === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setHeartDiseaseDate(value)}
              value={heartDiseaseDate}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
              error={heartDisease === 'yes' && !heartDiseaseDate}
              errorText={
                heartDisease === 'yes' && !heartDiseaseDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
              disabled={onlyView}
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <CustomInput
          value={cardiovascularComments ? cardiovascularComments : ''}
          labelText="Comentarios:"
          multiline={true}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            maxlength: '500',
            disabled: onlyView,
          }}
          helpText={`Hasta 500 caracteres. Te quedan ${
            500 - cardiovascularCommentsLenth
          } `}
          onChange={(e) => setCardiovascularComments(e.target.value)}
        />
      </GridItem>
    </>
  )
}

export default Cardiovascular

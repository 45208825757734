import { Grid, TextField, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'

import { ClinicalHistoryData } from '../interfaces'
import DateInput from '../../DateInput'
import { SelectOutlined } from '../../SelectOutlined/SelectOutlined'
import { useClinicalHistory } from '../hooks/useClinicalHistory'
import ValidatedTextField from '../components/ValidatedInput'

interface FollowUpProps {
  data: ClinicalHistoryData
  handleFieldChange: (field: keyof ClinicalHistoryData, value: any) => void
  disabled: boolean
}

const FollowUp: React.FC<FollowUpProps> = ({
  data,
  handleFieldChange,
  disabled,
}) => {
  const { practiceTypes, getPracticeTypes } = useClinicalHistory()

  useEffect(() => {
    getPracticeTypes()
  }, [])

  const practiceTypeOptions =
    practiceTypes?.map((pract: any) => ({
      id: pract.practiceTypeId,
      value: pract.name,
    })) ?? []

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} className="step-title">
        <Typography variant="h6">Seguimiento</Typography>
      </Grid>
      <Grid item container xs={12} className="content-form">
        <Grid item xs={12} sm={6} md={6}>
          <DateInput
            id="date-input-next-consult"
            text="Próxima consulta"
            value={data?.nextConsultationDate}
            onChangeValue={(value: any) =>
              handleFieldChange('nextConsultationDate', value)
            }
            disabled={disabled}
            variant="outlined"
            minDate={new Date()}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <DateInput
            id="date-input-discharge"
            text="Fecha de Alta"
            value={data?.dischargeDate}
            onChangeValue={(value: any) =>
              handleFieldChange('dischargeDate', value)
            }
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 16 }}>
          <SelectOutlined
            label="Especialidad 1"
            options={practiceTypeOptions}
            select={data?.specialty1?.practiceType ?? ''}
            onChange={(value) =>
              handleFieldChange('specialty1', { practiceType: value })
            }
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 16 }}>
          <SelectOutlined
            label="Especialidad 2"
            options={practiceTypeOptions}
            select={data.specialty2?.practiceType ?? ''}
            onChange={(value) =>
              handleFieldChange('specialty2', { practiceType: value })
            }
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <SelectOutlined
            label="Especialidad 3"
            options={practiceTypeOptions}
            select={data.specialty3?.practiceType ?? ''}
            onChange={(value) =>
              handleFieldChange('specialty3', { practiceType: value })
            }
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 24 }}>
          <ValidatedTextField
            variant="outlined"
            label="Observaciones"
            placeholder="Ingresar observaciones aquí"
            minRows={4}
            value={data?.observations ?? ''}
            onChange={(value) => handleFieldChange('observations', value)}
            type="string"
            maxLength={200}
            multiline
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 16 }}>
          <ValidatedTextField
            variant="outlined"
            label="Otros"
            placeholder="Ingresar otros aquí"
            minRows={4}
            value={data?.others ?? ''}
            onChange={(value) => handleFieldChange('others', value)}
            type="string"
            maxLength={200}
            multiline
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FollowUp

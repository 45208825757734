// icons
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import HotelOutlinedIcon from '@material-ui/icons/HotelOutlined';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Car from "./icons/Drivers";
import { ItineraryOutlined, MedicalDoctorOutlined, SyringeOutlined } from '../src/icons';

import { Module } from 'module';
import Alerts from './components/Alerts';

import Appointments from './components/Appointments';
import AppointmentsPractice from './components/Appointments/index_practice';
import Bookings from './components/Bookings';
import AppointmentDetail from './components/Appointments/AppointmentDetail';
import AppointmentTools from './components/AppointmentTools';
import Schedules from './components/Schedules';
import NewSchedule from './components/NewSchedule';
import ScheduleDetail from './components/MedicalRecord/ScheduleDetail';

import Routes from './components/Routes';

import Customers from './components/Customers';
import NewCustomer from './components/NewCustomer/new-customer';
import EditCustomer from './components/EditCustomer/edit-customer';

import SupplyCatalog from './components/SupplyCatalog';
import NewSupplyCatalog from './components/NewSupplyCatalog';
import EditSupplyCatalog from './components/EditSupplyCatalog';

import Drivers from './components/Drivers';
import Driver from './components/Driver';

import Vehicles from './components/Vehicles';
//import Driver from './components/Driver';

import Employees from './components/Employees';
import NewEmployee from './components/NewEmployee/new-employee';
import EditEmployee from './components/EditEmployee/EditEmployee';

import ToolTypes from './components/ToolTypes';
import Tools from './components/Tools';
import Users from './components/Users';
import NewUser from './components/NewUser';

import NewTool from './components/NewTool';
import EditTool from './components/EditTool';

import Dashboard from './components/Dashboard';
import DashboardProfesional from './components/DashboardProfesional';
import DoneAppointmentsReport from './components/Statistics/DoneAppointmentsReport';

import MedicalRecordList from './components/MedicalRecord/MedicalRecordList';
import TechnicalFile from './components/TechnicalFile/TechnicalFile';
import IntegralTechnicalFile from './components/IntegralTechnicalFile/IntegralTechnicalFile';
import PracticeTypes from './components/PracticeTypes';
import EditPracticeTypes from './components/EditPracticeTypes';
import ReassignAppointmentsStepper from './components/ReassignAppointmentsStepper';
import TransferAppointments from './components/TransferAppointments';
import Vehicle from './components/Vehicle';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import HospitalizationForm from './components/HospitalizationForm/HospitalizationForm'
import HospitalizationFormView from './components/HospitalizationForm/HospitalizationFormView'
import HospitalizationList from './components/HospitalizationForm/HospitalizationList'
import DiabeticProgram from './components/DiabeticProgram/DiabeticProgram'
import DiabeticProgramForm from './components/DiabeticProgram/DiabeticProgramForm'
import DiabeticProgramFormView from './components/DiabeticProgram/DiabeticProgramFormView'
import ClinicalHistory from './components/ClinicalHistory/ClinicalHistory'


const routesList = [
  {
    path: '/alertas',
    name: 'routes.alerts',
    mini: 'routes.alerts.mini',
    rtlName: '',
    component: Alerts,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    collapse: true,
    name: 'routes.appointments',
    rtlName: '',
    icon: AssignmentOutlinedIcon,
    state: 'appointmentsCollapse',
    views: [
      {
        path: '/solicitudes',
        name: 'routes.appointments',
        mini: 'routes.appointments.mini',
        rtlName: '',
        inset: 10,
        component: Appointments,
        layout: '',
        enabled: true,
        authority:'menu_appointment',
      },
      {
        path: '/practices',
        name: 'routes.practices',
        mini: 'routes.practices.mini',
        inset: 10,
        rtlName: '',
        component: AppointmentsPractice,
        layout: '',
        enabled: true,
        authority:'menu_appointment_item_request',
      },
      {
        path: '/reasign-appointments',
        name: 'routes.reassignment',
        mini: 'routes.reassignment.mini',
        inset: 10,
        rtlName: '',
        component: ReassignAppointmentsStepper,
        layout: '',
        enabled: true,
        authority:'menu_reassignment',
      },
      
      {
        path: '/solicitudes/:id',
        name: 'routes.appointments',
        mini: 'routes.appointments.mini',
        rtlName: '',
        component: AppointmentDetail,
        layout: '',
        enabled: true,
        hidden: true,
      },
      {
        path: '/appointments/:id/edit/agenda',
        name: 'routes.appointments',
        mini: 'routes.appointments.mini',
        rtlName: '',
        component: AppointmentDetail,
        layout: '',
        enabled: true,
        hidden: true,
      },
      {
        path: '/appointments/:id/renew/agenda',
        name: 'routes.renewAppointment',
        mini: 'routes.renewAppointment.mini',
        rtlName: '',
        component: AppointmentDetail,
        layout: '',
        enabled: true,
        hidden: true,
      },
    ],
  },

{
  collapse: true,
  name: 'routes.transferAppointments',
  rtlName: '',
  icon: AssignmentOutlinedIcon,
  state: 'transferAppointmentsCollapse',
  authority:'menu_mobility_transfer',
  views: [
    {
      path: '/solicitudes-traslado',
      name: 'routes.transferAppointments',
      mini: 'routes.transferAppointments.mini',
      rtlName: '',
      inset: 10,
      component: TransferAppointments,
      layout: '',
      enabled: true,
      authority:'menu_mobility_transfer',
    },
    {
      path: '/reasign-transferAppointments',
      name: 'routes.transferReassignment',
      mini: 'routes.transferReassignment.mini',
      inset: 10,
      rtlName: '',
      component: ReassignAppointmentsStepper,
      layout: '',
      enabled: true,
      authority:'menu_mobility_reassignment',
    },
    {
      path: '/solicitudes-traslado/:id',
      name: 'routes.transferAppointments',
      mini: 'routes.transferAppointments.mini',
      rtlName: '',
      component: AppointmentDetail,
      layout: '',
      enabled: true,
      hidden: true,
    },
    {
      path: '/appointments/:id/edit/agenda',
      name: 'routes.transferAppointments',
      mini: 'routes.transferAppointments.mini',
      rtlName: '',
      component: AppointmentDetail,
      layout: '',
      enabled: true,
      hidden: true,
    },
    {
      path: '/appointments/:id/renew/agenda',
      name: 'routes.renewTransferAppointment',
      mini: 'routes.renewTransferAppointment.mini',
      rtlName: '',
      component: AppointmentDetail,
      layout: '',
      enabled: true,
      hidden: true,
    },
  ],
},
  {
    path: '/nueva-solicitud',
    name: 'routes.newRequest',
    mini: 'routes.newRequest.mini',
    rtlName: '',
    component: NewSchedule,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    path: '/turnos',
    name: 'routes.shifts',
    mini: 'routes.shifts.mini',
    rtlName: '',
    component: Bookings,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    path: '/hojas-ruta',
    name: 'routes.itinerary',
    mini: 'routes.itinerary.mini',
    icon: ItineraryOutlined,
    rtlName: '',
    component: Routes,
    layout: '',
    enabled: true,
    authority:'menu_roadmap'
  },
  
  {
    path: '/profesionales',
    name: 'routes.professionals',
    mini: 'routes.professionals.mini',
    icon: MedicalDoctorOutlined,
    rtlName: '',
    component: Employees,
    layout: '',
    enabled: true,
    authority:'menu_employees'
  },
  {
    path: "/drivers",
    name: "routes.drivers",
    mini: "routes.drivers.mini",
    icon: Car,
    rtlName: "",
    component: Drivers,
    layout: "",
    enabled: true,
    authority: "menu_mobility_driver",
  },
  {
    path: "/driver/:id",
    name: "routes.driver",
    mini: "routes.driver.mini",
    rtlName: "",
    component: Driver,
    layout: "",
    enabled: true,
    hidden: true,
    authority: "menu_mobility_driver"
  },
  {
    path: "/vehicles",
    name: "routes.vehicles",
    mini: "routes.vehicles.mini",
    icon: Car,
    rtlName: "",
    component: Vehicles,
    layout: "",
    enabled: true,
    authority: "menu_mobility_manager_vehicles",
  },
  {
    path: "/vehicle/:id",
    name: "routes.vehicle",
    mini: "routes.vehicle.mini",
    rtlName: "",
    component: Vehicle,
    layout: "",
    enabled: true,
    hidden: true,
  },
  {
    path: '/nueva-solicitud-traslado',
    name: 'routes.newRequestTransfer',
    mini: 'routes.newRequestTransfer.mini',
    rtlName: '',
    component: NewSchedule,
    layout: '',
    enabled: true,
    hidden: true
  },

  {
    path: '/nuevo-profesional',
    name: 'routes.newProfessional',
    mini: 'routes.newProfessional.mini',
    rtlName: '',
    component: NewEmployee,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    path: '/editar-profesional/:id',
    name: 'routes.editProfessional',
    mini: 'routes.editProfessional.mini',
    rtlName: '',
    component: EditEmployee,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    path: '/ver-profesional',
    name: 'routes.viewProfessional',
    mini: 'routes.viewProfessional.mini',
    rtlName: '',
    component: EditEmployee,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    path: '/patient',
    name: 'routes.patients',
    mini: 'routes.patients.mini',
    icon: HotelOutlinedIcon,
    rtlName: '',
    component: Customers,
    layout: '',
    enabled: true,
    authority: 'menu_customers',
    views: [
      {
        path: '/patient/:id/medical-record/full/',
        name: 'routes.integralMedicalHistory',
        titlewindow: 'routes.integralMedicalHistory',
        mini: 'routes.integralMedicalHistory.mini',
        rtlName: '',
        component: IntegralTechnicalFile,
        layout: '',
        hidden: true,
        enabled: true,
      },
    ],
  },
  {
    path: '/nuevo-paciente',
    name: 'routes.newPatient',
    mini: 'routes.newPatient.mini',
    rtlName: '',
    component: NewCustomer,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    path: '/editar-paciente/:id',
    name: 'routes.editPatient',
    mini: 'routes.editPatient.mini',
    rtlName: '',
    component: EditCustomer,
    layout: '',
    enabled: true,
    hidden: true,
    authority:'customers_edit'
  },
  {
    path: '/ver-paciente/:id',
    name: 'routes.viewPatient',
    mini: 'routes.viewPatient.mini',
    rtlName: '',
    component: EditCustomer,
    layout: '',
    enabled: true,
    hidden: true,
    authority:'customers_view,customers_view_related'
  },
  {
    path: '/medical-record',
    name: 'routes.clinicalHistoryRequest',
    mini: 'routes.clinicalHistoryRequest.mini',
    rtlName: '',
    component: MedicalRecordList,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    path: '/detail',
    name: 'routes.technicalSheet' ,
    mini: 'routes.technicalSheet.mini',
    rtlName: '',
    component: TechnicalFile,
    layout: '',
    hidden: true,
    enabled: true
  },
  {
    path: '/admission',
    name: 'routes.admission',
    mini: 'routes.admission.mini',
    rtlName: '',
    inset: 10,
    icon: LocalHospitalIcon,
    component: HospitalizationList,
    layout: '',
    enabled: true,
    authority: 'admission_module',
  },
  {
    path: '/admission/new-form',
    inset: 10,
    rtlName: '',
    component: HospitalizationForm,
    layout: '',
    enabled: true,
    hidden: true,
  },
  {
    path: '/admission/edit-form/:id',
    inset: 10,
    rtlName: '',
    component: HospitalizationForm,
    layout: '',
    enabled: true,
    hidden: true,
  },
  {
    path: '/admission/view/:id',
    inset: 10,
    rtlName: '',
    component: HospitalizationFormView,
    layout: '',
    enabled: true,
    hidden: true,
  },
  {
    path: '/diabetic-program',
    name: 'routes.diabetic',
    mini: 'routes.diabetic.mini',
    inset: 10,
    rtlName: '',
    icon: HotelOutlinedIcon,
    component: DiabeticProgram,
    layout: '',
    enabled: true,
    authority: 'diabetic_module',
  },
  {
    path: '/diabetic-program/form',
    inset: 10,
    rtlName: '',
    component: DiabeticProgramForm,
    layout: '',
    enabled: true,
    hidden: true,
  },

  {
    path: '/diabetic-program/edit/:id',
    inset: 10,
    rtlName: '',
    component: DiabeticProgramForm,
    layout: '',
    enabled: true,
    hidden: true,
  },
  {
    path: '/diabetic-program/view/:id',
    inset: 10,
    rtlName: '',
    component: DiabeticProgramFormView,
    layout: '',
    enabled: true,
    hidden: true,
  },
  {
    collapse: true,
    name: 'routes.suppliesGroup',
    rtlName: '',
    mini: 'routes.suppliesGroup.mini',
    icon: SyringeOutlined,
    state: 'medicalProductsCollapse',
    authority:'menu_supplies_and_tools',
    views: [
      {
        path: '/catalogo-insumos',
        name: 'routes.supplies' ,
        mini: 'routes.supplies.mini',
        rtlName: '',
        inset: 10,
        component: SupplyCatalog,
        layout: '',
        enabled: true,
        authority:'menu_supplies_and_tools_supply_catalog'
      },
      //---------
      {
        path: '/tool-types',
        name: 'routes.productType' ,
        mini: 'routes.productType.mini',
        rtlName: '',
        component: ToolTypes,
        layout: '',
        inset: 10,
        enabled: true,
        authority:'menu_supplies_and_tools_tool_type'
      },
      //---------
      {
        path: '/tools',
        name: 'routes.tools' ,
        mini: 'routes.tools.mini',
        rtlName: '',
        component: Tools,
        layout: '',
        inset: 10,
        enabled: true,
        authority:'menu_supplies_and_tools_tool'
      },
      {
        path: '/nuevo-insumo',
        name: 'routes.newSupply',
        mini: 'routes.newSupply.mini',
        rtlName: '',
        component: NewSupplyCatalog,
        layout: '',
        inset: 10,
        enabled: true,
        hidden: true
      },
      {
        path: '/editar-insumo/:id',
        name: 'routes.supplyEdit',
        mini: '',
        rtlName: '',
        component: EditSupplyCatalog,
        layout: '',
        inset: 10,
        enabled: true,
        hidden: true
      },
      {
        path: '/nuevo-producto',
        name: 'routes.newProduct',
        mini: 'routes.newProduct.mini',
        rtlName: '',
        component: NewTool,
        layout: '',
        inset: 10,
        enabled: true,
        hidden: true
      },
      {
        path: '/editar-producto/:id',
        name: 'routes.productEdit',
        mini: 'routes.productEdit.mini',
        inset: 10,
        rtlName: '',
        component: EditTool,
        layout: '',
        enabled: true,
        hidden: true
      },
      {
        path: '/ver-profesional',
        name: 'routes.viewProfessional',
        mini: 'routes.viewProfessional.mini',
        rtlName: '',
        component: EditEmployee,
        layout: '',
        enabled: true,
        hidden: true
      },
      {
        path: '/editar-profesional/:id',
        name: 'routes.editProfessional',
        mini: 'routes.editProfessional.mini',
        rtlName: '',
        component: EditEmployee,
        layout: '',
        enabled: true,
        hidden: true
      },      
    ],
  },
  {
    collapse: true,
    name: 'routes.settings',
    rtlName: '',
    icon: SettingsOutlinedIcon,
    state: 'mantenimientoCollapse',
    authority:'menu_maintenance',
    views: [
      {
        path: '/practice-types',
        name: 'routes.practiceTypes',
        mini: 'routes.practiceTypes.mini',
        rtlName: '',
        inset: 10,
        component: PracticeTypes,
        layout: '',
        enabled: true,
        authority:'menu_maintenance_practices'
      },
      {
        state: 'mantenimientoCollapse',
        path: '/users',
        name: 'routes.users',
        mini: 'routes.users.mini',
        titlewindow: 'routes.Users',
        inset: 10,
        rtlName: '',
        component: Users,
        layout: '',
        enabled: true
      },
      {
        path: 'practice-types/:id/edit',
        name: 'routes.editPracticeTypes',
        mini: 'routes.editPracticeTypes.mini',
        rtlName: '',
        component: EditPracticeTypes,
        layout: '',
        enabled: true,
        hidden: true,
      },
      {
        path: '/module-settings',
        name: 'routes.moduleSettings',
        mini: 'routes.moduleSettings.mini',
        rtlName: '',
        inset: 10,
        component: Dashboard,
        layout: '',
        enabled: false,
        hidden: true,
      },
      {
        path: '/appointments-settings',
        name: 'routes.appointmentSettings',
        mini: 'routes.appointmentSettings.mini',
        rtlName: '',
        inset: 10,
        component: Dashboard,
        layout: '',
        enabled: false,
        hidden: true,
      },
      {
        path: '/config-personal',
        name: 'routes.personalSettings',
        mini: 'routes.personalSettings.mini',
        inset: 10,
        rtlName: '',
        component: Dashboard,
        layout: '',
        enabled: false,
        hidden: true,
      }
    ]
  },
  {
    path: '/new-user',
    name: 'routes.newUser',
    mini: 'routes.newUser.mini',
    rtlName: '',
    component: NewUser,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    collapse: true,
    name: 'routes.statistics',
    rtlName: '',
    icon: PollOutlinedIcon,
    state: 'statisticsCollapse',
    authority:'menu_reports',
    views: [
      {
        state: 'mantenimientoprofesionalCollapse',
        path: '/dashboard-professionals',
        name: 'routes.professionals.dashboard',
        titlewindow: 'routes.DashboardProfessional',
        mini: 'routes.DashboardProfessional.mini',
        rtlName: '',
        inset: 10,
        component: DashboardProfesional,
        layout: '',
        enabled: true
      },
      {
        state: 'mantenimientoCollapse',
        path: '/dashboard',
        name: 'routes.professionalPerformance',
        mini: 'routes.professionalPerformance.mini',
        inset: 10,
        rtlName: '',
        component: Dashboard,
        layout: '',
        enabled: true
      },
      {
        path: '/done-appointments-report',
        name: 'routes.doneAppointmentsReport',
        mini: 'routes.doneAppointmentsReport.mini',
        inset: 10,
        rtlName: '',
        component: DoneAppointmentsReport,
        layout: '',
        enabled: true
      }
    ]
  },
  {
    name: 'routes.powerbi',
    mini: 'routes.powerbi.mini',
    rtlName: '',
    layout: '',
    enabled: true,
    icon: DashboardIcon,
    collapse: true,
    authority:'menu_pbi_reports',
    state: 'dashboardpowerbi',
    views: [
        {
        path: '/dashboard-operatives-pb',
        name: 'routes.dashboardOperative',
        titlewindow: 'routes.dashboardOperative',
        mini: 'routes.dashboardOperative.mini',
        rtlName: '',
        inset: 10,
        layout: '',
        authority:'menu_pbi_reports_operatives',
        enabled: true
      },
      {
        path: '/dashboard-management-pb',
        name: 'routes.dashboardManagement',
        titlewindow: 'routes.dashboardManagement',
        mini: 'routes.dashboardManagement.mini',
        rtlName: '',
        inset: 10,
        layout: '',
        authority:'menu_pbi_reports_management',
        enabled: true,
      },
      {
        path: '/dashboard-management-control-pb',
        name: 'routes.dashboardManagementControl',
        titlewindow: 'routes.dashboardManagementControl',
        mini: 'routes.dashboardManagementControl.mini',
        rtlName: '',
        inset: 10,
        layout: '',
        authority:'menu_pbi_reports_management_control',
        enabled: true,
      },
      {
        path: '/dashboard-financier-pb',
        name: 'routes.Financiero',
        titlewindow: 'routes.Financiero',
        mini: 'routes.Financiero.mini',
        rtlName: '',
        inset: 10,
        layout: '',
        authority:'menu_pbi_reports_financier',
        enabled: true,
      },      {
        path: '/dashboard-hospitalizations',
        name: 'routes.dashboardHospitalizations',
        titlewindow: 'routes.dashboardHospitalizations',
        mini: 'routes.dashboardHospitalizations.mini',
        rtlName: '',
        inset: 10,
        layout: '',
        authority: 'admission_module',
        enabled: true,
      },
      {
        path: '/dashboard-diabetic',
        name: 'routes.dashboardDiabetic',
        titlewindow: 'routes.dashboardDiabetic',
        mini: 'routes.dashboardDiabetic.mini',
        rtlName: '',
        inset: 10,
        layout: '',
        authority: 'diabetic_module',
        enabled: true,
      },

    ]
  },
  {
    path: '/schedule',
    name: 'routes.medicalVisit',
    mini: 'routes.medicalVisit.mini',
    rtlName: '',
    component: ScheduleDetail,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    path: '/historial',
    name: 'routes.record',
    mini: 'routes.record.mini',
    rtlName: '',
    component: Schedules,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    path: '/herramientas-turno',
    name: 'routes.addMedicalProducts',
    mini: 'routes.addMedicalProducts.mini',
    rtlName: '',
    component: AppointmentTools.Form,
    layout: '',
    enabled: true,
    hidden: true
  },
  {
    path: '/modulos',
    name: 'Package',
    mini: 'routes.package.mini',
    rtlName: '',
    component: Module,
    layout: '',
    enabled: true,
    authority:'menu_package'
  },
]

const routes = routesList.map((route, index) => {
  
  const routeWithId = { ...route, id: String(index + 1) };
  if (route.views) {
    routeWithId.views = route.views.map((view, viewIndex) => ({ ...view, id: `${index + 1}.${viewIndex + 1}` }));
  }
  return routeWithId;
});


export default routes;

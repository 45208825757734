import BaseInvoker from './BaseInvoker'
import { Component } from 'react'

const urlInvoke = `/api`;
const baseInvoker = new BaseInvoker();
export default class HozpitalizationApiInvoker extends Component {
  static getServicesCenter(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/service-center/viewAll`, callback, callerror)
  }

  static getTreatingMedicals(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/treating-medical/viewAll`, callback, callerror)
  }

  static addTreatingMedicals(body, callback, callerror) {
    baseInvoker.post(`${urlInvoke}/treating-medical`, body, callback, callerror)
  }

  static addDressingsType(body, callback, callerror) {
    baseInvoker.post(`${urlInvoke}/dressings-type`, body, callback, callerror)
  }

  static getDischargePlace(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/discharge-place/viewAll`, callback, callerror)
  }

  static getDressingsType(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/dressings-type/viewAll`, callback, callerror)
  }

  static getMedicinesType(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/medicines-type`, callback, callerror)
  }

  static getFrequencyRanges(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/FrequencyRanges/viewAll`, callback, callerror)
  }

  static getMedicinesUnitType(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/medicines-unit-type`, callback, callerror)
  }

  static getMedicinesWayType(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/medicines-via-type`, callback, callerror)
  }

  static getMedicinesPresentationType(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/medicines-presentation-type`, callback, callerror)
  }

  static getAxaInternment(callback, callerror) {
    baseInvoker.get(`${urlInvoke}/internment/viewAll`, callback, callerror)
  }

  static getAxaInternmentById(id, callback, callerror) {
    baseInvoker.get(`${urlInvoke}/internment/${id}`, callback, callerror)
  }

  static postDischargeData(body, callback, callerror) {
    baseInvoker.post(`${urlInvoke}/internment`, body, callback, callerror)
  }

  static updateAxaInternment(body, callback, callerror) {
    baseInvoker.put(`${urlInvoke}/internment/update`, body, callback, callerror)
  }

  static filterListData(params, callback, callerror) {
    baseInvoker.get(`${urlInvoke}/internment-filter?${params}`, callback, callerror)
  }
}

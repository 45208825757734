import React from 'react';
import { CloudUpload, PictureAsPdf, Delete } from '@material-ui/icons';
import { Button, IconButton } from '@material-ui/core';
import { useUpDownImage } from './hooks/useUpDownImage';

const FileUpload = ({ saveDocument, removeDocument, initialFile }) => {
  const {setFile, setDragOver,handleDragOver,handleRemoveFile, handleDragLeave,dragOver, file} = useUpDownImage(initialFile, saveDocument, removeDocument)

  const handleFileChange = (event) => {
    let uploadedFile = event.target.files[0]
    if (uploadedFile) {
      const reader = new FileReader()
      reader.onloadend = () => {
        let base64String = reader.result
          .replace('data:', '')
          .replace(/^.+,/, '')
        let ext = uploadedFile.name.split('.').pop()
        base64String = ext + ',' + base64String
        console.log(ext)
        saveDocument(base64String)
      }
      reader.readAsDataURL(uploadedFile)
      setFile(uploadedFile)
    }
  }
  const handleDrop = (event) => {
    event.preventDefault()
    setDragOver(false)
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const droppedFile = event.dataTransfer.files[0]
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result
          .replace('data:', '')
          .replace(/^.+,/, '')
        saveDocument(base64String)
      }
      reader.readAsDataURL(droppedFile)
      setFile(droppedFile)
    }
  }

  const renderUploadArea = () => (
    <div
      style={{
        textAlign: 'center',
        border: dragOver ? '2px dashed #000' : '2px dashed #ccc',
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: dragOver ? '#eee' : '#fff'
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <CloudUpload style={{ fontSize: '50px' }} />
      <p>Arrastre y suelte archivos aquí o</p>
      <Button variant="contained" component="label">
        Cargar archivo
        <input type="file" hidden onChange={handleFileChange} />
      </Button>
    </div>
  );

  const renderFilePreview = () => (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <PictureAsPdf style={{ fontSize: '50px', marginRight: '10px' }} />
      <IconButton onClick={handleRemoveFile}>
        <Delete style={{ fontSize: '50px' }} />
      </IconButton>
    </div>
  );

  return (
    <div>
      {file ? renderFilePreview() : renderUploadArea()}
    </div>
  );
};

export default FileUpload;

import './Bookings.css';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import React, { Component } from 'react';

import AppointmentApiInvoker from '../../api/AppointmentApiInvoker';
import BeforeUnloadComponent from 'react-beforeunload-component';
import BookingDetail from './BookingDetail';
import Button from '../CustomButtons/Button';
import ButtonSpinner from '../ButtonSpinner';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import CardHeader from '../Card/CardHeader';
import CustomDialog from '../CustomDialog';
import CustomSweetAlert from '../CustomSweetAlert';
import EventWithIcon from '../EventWithIcon/EventWithIcon';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import PropTypes from 'prop-types';
import SchedulerInvoker from '../../api/SchedulerInvoker';
import Snackbar from '../Snackbar/Snackbar';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import config from '../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

config.updateMoment();

const localizer = momentLocalizer(moment);

class Bookings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      alert: null,
      openDetail: false,
      updateErrors: [],
      alertServerOpen: false,
      alertErrorOpen: false,
      alertStatus: '',
      alertMessage: '',
      alertColor: 'info',
      calendarViewMode: 'month',
      dates: {},
      busy: false,
      changeCalendarView: false,
      customer:''
    }
    this.componentCleanup = this.componentCleanup.bind(this);
  }

 componentCleanup() {
    this.cancel(true);
  }

  onUnload = (event) => {
    event.preventDefault()
    event.returnValue = '';
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload, false)
  }

  componentDidMount() {
    const { bookings, location, onGetBookings } = this.props;
    window.addEventListener('beforeunload', this.onUnload, false);
    if (bookings && !(location.state?.fromAppointmentAssignAgenda)) {
      this.formatBookings(bookings);
    } else {
      onGetBookings(location.state.bookings);
      this.formatBookings(location.state.bookings)
    }
    
  
  }
  componentWillMount() {
    window.addEventListener('unload', this.componentCleanup);
  }

  
  getDuration = (startDate, endDate)=> {
    const start = moment(startDate);
    const end = moment(endDate);
    const differenceInMinutes = end.diff(start, 'minutes');
    
    return Math.abs(differenceInMinutes);
  }

  convertTimeToArray= (timeString)=> {
    const timeRegex = /^(\d{1,2}):(\d{2})\s?(am|pm)$/i;
    const match = timeString.match(timeRegex);

    if (!match) {
        throw new Error('Formato de tiempo inválido');
    }
    let hours = parseInt(match[1], 10);
    const period = match[3].toLowerCase();
    const minutes = parseInt(match[2], 10);

    if (period === 'pm' && hours !== 12) {
        hours += 12;
    } else if (period === 'am' && hours === 12) {
        hours = 0;
    }

    return [hours, minutes];
  }

  formatBookings(data) {
    let bookings = [];
    const dataBookings = data.length && data.map(obj => ({ ...obj, type: 'booking' }));
    const foreignBookings = data.foreign-bookings?.length && data.foreign-bookings.map(obj => ({ ...obj, type: 'fbooking' }));
    const dataSchedules = 0
    bookings = _.concat(bookings, dataBookings, foreignBookings, dataSchedules);

    bookings = _.orderBy(bookings, ['scheduleDateTime'], ['asc'])
    const result = bookings.map(b => {
      return {
        id: b?.transferScheduleId || b?.scheduleId,
        title: "Traslado",
        time_display: `${moment(b?.startDateTime).format('hh:mm a')} - ${moment(b?.endDateTime).format('hh:mm a')}`,
        time_start: moment(b?.startDateTime).format('hh:mm a'),
        start: new Date(b?.startDateTime),
        end: new Date(b?.endDateTime),
        allDay: false,
        view: this.state.calendarViewMode,
        color: this.getColor(b?.group) || "D81B60",
        employeeId: b?.driver?.personId,
        status: b?.transferScheduleStatus,
        companyPartnerShip:b?.transferItemRequestId,
        estimatedDuration:this.getDuration(b?.startDateTime,b?.endDateTime),
        type: b?.type,
        address:b?.address,
        startDateTime:this.convertTimeToArray(moment(b?.startDateTime).format('hh:mm a')),
        endDateTime:this.convertTimeToArray(moment(b?.endDateTime).format('hh:mm a')),
        errorDetail: b?.status === 'ERROR' || b?.status === 'WARNING' ? b?.errorDetail : '',
        customerName:this.props.location.state.appointment?.customer.name + ' ' +  this.props.location.state.appointment.customer.lastname
      }
    });
    this.setState({ events: result });
  }

  getColor(group) {
    const colors = {
      0: 'E157CA',
      1: 'F06292',
      2: '5AB4FF',
      3: '79009B',
      4: 'D81B60',
      5: '0A85FF',
      6: 'BF00B0',
      7: '880E4F',
      8: '1F3FB9',
    };
    return colors[group % 9];
  }

  selectEvent(event) {
    event.action = 'create';
    this.setState({ openDetail: true, currentEvent: event });
  }


  cancel(redirect) {
    if (this.state.calendarViewMode !== 'month') {    
      this.setState({calendarViewMode: 'month'});
      return;
    }
    if (this.props.bookings) {
      SchedulerInvoker.deleteBookings(this.props.bookings.appointmentBookingId);
    }
    if (redirect) {
      browserHistory.push({
        state: { mode: 'assign_agenda' },
        pathname: `/transferappointments/${this.props.params.id}/edit/agenda`,
      });
      this.setState({ busy: false });
    }
  }

  confirmBookigs() {
      this.setState({
        alertOpen: true,
        alertStatus: this.props.bookings.status,
      });
  }
  

  openAlert = (color, message) => {
    this.setState({ alertColor: color, alertMessage: message, alertServerOpen: true });

    setTimeout(() => {
      this.setState({
        alertServerOpen: false,
        alertOpen: false,
      });
    }, 5000);
  }

  saveBookings() {
    this.setState({ busy: true });
    AppointmentApiInvoker.saveBookings(this.props.location.state?.bookings?.[0]?.bookingKey,(data) => {
     
        this.props.onSaveBookings(data);
        browserHistory.push('/solicitudes-traslado');
      
    }, (error) => {
      console.log(error)
      this.openAlert('danger', error.message || this.props.t('appointment.new.404.error'))
      this.setState({ busy: false, alertOpen: false });
      console.error('** error postSaveBookings', error);
    });
  }

  eventColors(event, start, end, isSelected) {
    const eventColor = event.color ? (`event-${event.color}`) : 'event-default'
    return {
      className: eventColor
    }
  }

  updateBooking = (booking) => {
    const result = this.state.events
    if (booking.status !== 'ERROR') {
      this.state.events.forEach((b, i) => {
        if (b.id === booking.bookings[0].scheduleBookingId) {
          result[i] = {
            id: booking.bookings[0].scheduleBookingId,
            allDay: false,
            title: booking.bookings[0].label,
            time_display: `${moment(booking.bookings[0].scheduleDateTime).format('hh:mm a')} - ${moment(booking.bookings[0].scheduleEndDateTime).format('hh:mm a')}`,
            time_start: moment(booking.bookings[0].scheduleDateTime).format('hh:mm a'),
            view: this.state.calendarViewMode,
            color: this.getColor(booking.bookings[0].group),
            start: new Date(booking.bookings[0].scheduleDateTime),
            end: new Date(booking.bookings[0].scheduleEndDateTime),
            employeeId: booking.bookings[0].employeeId,
            status: booking.status,
            type: booking.bookings[0].booking || booking.bookings[0].scheduleBookingId
              ? 'booking'
              : 'schedule',
          }
        }
        this.setState({ events: _.orderBy(result, ['start'], 'asc'), openDetail: false });
      });
    } else {
      this.setState({ updateErrors: booking.errors, status: booking.status })
    }
  }

  onChangeView = (event) => {
    const { events } = this.state
    events.forEach((b, i) => {
      b.view = event
    });
    this.setState({ calendarViewMode: event });
  }
  
  onModalComponentHandler(handleModalLeave, handleModalCancel) {
    const { t } = this.props
    return (
      <CustomSweetAlert
        onConfirm={() => {
          this.cancel(false);
          handleModalLeave();
        }}
        title={t('reload.site.title')}
        onCancel={() => handleModalCancel()}
        confirmBtnText={t('confirm')}
        cancelBtnText={t('common.cancel')}
        confirmBtnCssClass="primary"
        cancelBtnCssClass="danger"
        message={<p>{t('reasign.reload.site.changes.lost')}</p>}
        showCancel
      />
    )
  }

  formatBookingsErrors() {
    const bookingErrorsList = this.state.bookings.errors.map((item, index) => ({
      ...item,
      key: index+1,
    }));
    return bookingErrorsList.map((e) => <span key={e.key} className="schedule-errors-item">{e.detail}</span>)
  }

  render() {
    const { bookings, scheduleDates, location, t } = this.props;
    const {
      alertErrorMessage,
      alertColor,
      alertErrorOpen,
      alertMessage,
      alertOpen,
      busy,
      currentEvent,
      dates,
      alertServerOpen,
      alertStatus,
      openDetail,
      status,
      events,
      updateErrors,
    } = this.state;
    const dateFrom = moment(scheduleDates ? scheduleDates.startDate : dates.startDate).format('DD-MM-YYYY');
    const dateTo = moment(scheduleDates ? scheduleDates.endDate : dates.endDate).format('DD-MM-YYYY');
    const tipo = location.state?.isTransfer
    return (
      <BeforeUnloadComponent
        ignoreChildrenLinks={true}
        blockRoute={true}
        modalComponentHandler={({ handleModalLeave, handleModalCancel }) => this.onModalComponentHandler(handleModalLeave, handleModalCancel)}
      >
        <GridContainer 
          justify="center"
        >
          <GridItem xs={12}>
            <Card>
              {(scheduleDates || dates) && (
                <CardHeader className="text-center">
                  <h4 className="card-title">
                    <strong>
                      {t('appointment.calendar.dates')}
                    </strong>
                    {t('appointment.calendar.fromToDates', { dateFrom, dateTo })}
                  </h4>
                </CardHeader>
              )}
              <CardBody id="calendar-view" className="calendar-booking" calendar>
                <Calendar
                  showMultiDayTimes={true}
                  selectable
                  defaultView={'month'}
                  view={this.state.calendarViewMode}
                  localizer={localizer}
                  events={events}
                  step={30}
                  popup={false}
                  onView={event => this.onChangeView(event)}
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  onSelectEvent={event => this.selectEvent(event)}
                  eventPropGetter={this.eventColors}
                  defaultDate={new Date()}
                  messages={{
                    next: t('common.next'),
                    previous: t('common.prev'),
                    today: t('common.today'),
                    month: t('common.month'),
                    week: t('common.week'),
                    day: t('common.day'),
                    event: t('common.event'),
                    date: t('common.date'),
                    time: t('common.hour')
                  }}
                  components={{
                    event: EventWithIcon,
                  }}
                />

                <CustomDialog
                  title={`${t('appointment.calendar.scheduleDialogTitle')} ${(currentEvent && currentEvent.type !== 'booking' ? t('appointment.calendar.scheduleDialogTitle.next') : '')}`}
                  open={openDetail}
                  maxWidth="md"
                  onClose={() => this.setState({ openDetail: false })}
                  className="bookings-component"
                >
                  <BookingDetail
                    mode={location.state.mode}
                    readOnly={!(currentEvent?.color)}
                    action={location.state.action}
                    event={currentEvent}
                    onCancel={() => this.setState({ openDetail: false })}
                    onUpdate={booking => this.updateBooking(booking)}
                    isTransfer={tipo}
                    errors={updateErrors}
                    status={status}
                  />
                </CustomDialog>
                
                {alertOpen &&
                  <CustomSweetAlert
                    type={alertStatus === 'ERROR' ? 'error' : 'warning'}
                    title={t('appointment.new.appointmentReview')}
                    showConfirm={false}
                    showCancel={false}
                    message={
                      <div className="booking-errors">
                        <div className="booking-errors-list">
                          <span 
                            className="schedule-errors-item"
                          >
                            {(alertStatus === 'WARNING') || alertStatus === 'ERROR' ? (
                              <div 
                                className="schedule-errors-list"
                              >
                                {bookings?.errors?.length > 0 && this.formatBookingsErrors()}
                              </div>
                            ) : <span>
                                  {t('appointment.calendar.confirmMessage')}
                                </span>
                            }
                          </span>
                        </div>
                        <div className="booking-errors-actions">
                          <Button
                            color="danger"
                            onClick={() => this.setState({ alertOpen: false })}
                            id="button-cancel-book"
                          >
                            {t('common.cancel')}
                          </Button>
                          <ButtonSpinner
                            loading={busy}
                            className="buttonSpinner"
                            onClick={() => this.saveBookings()}
                            disabled={alertStatus === 'ERROR' || busy}
                            color="primary"
                            label={t('common.save')}
                            labelLoading={t('common.saving')}
                            id="button-save-book"
                          />
                        </div>
                      </div>
                    }
                  />
                }

                {alertErrorOpen &&
                  <CustomSweetAlert
                    type="error"
                    showConfirm={false}
                    title={t('appointment.new.appointmentReview')}
                    showCancel={false}
                    message={
                      <div className="booking-errors">
                        <div className="booking-errors-list">
                          <p>{alertErrorMessage}</p>
                        </div>
                        <div className="booking-errors-actions">
                          <Button
                            id="button-cancel-alert"
                            onClick={() => this.setState({ alertErrorOpen: false })}
                            color="danger"
                          >
                            {t('common.cancel')}
                          </Button>
                        </div>
                      </div>
                    }
                  />
                }
              </CardBody>
            </Card>
          </GridItem>
          <GridItem 
            xs={12} 
            className="footerButtons justify-space-between"
          >
            <Button id="button-cancel-agenda" onClick={() => this.cancel(true)}>
              {t('appointment.new.backStep')}
            </Button>
            <Button 
              id="button-accept-agenda" 
              color="primary" 
              onClick={() => this.confirmBookigs()}
            >
              {t('appointment.new.accept')}
            </Button>
          </GridItem>
          <Snackbar
            open={alertServerOpen}
            place="tr"
            color={alertColor}
            message={alertMessage}
          />
        </GridContainer>
      </BeforeUnloadComponent>
    )
  }
}

Bookings.propTypes = {
  t: PropTypes.func,
  bookings: PropTypes.object,
  onSaveBookings: PropTypes.func,
  scheduleDates: PropTypes.object.isRequired,
  params: PropTypes.object,
  location: PropTypes.object,
  onGetBookings: PropTypes.func,
};

export default withTranslation()(Bookings);

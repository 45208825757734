import React, { useEffect } from 'react'

import { ClinicalHistoryData } from '../interfaces'
import GridItem from '../../Grid/GridItem'
import SelectInput from '../../SelectInput'
import { SelectOutlined } from '../../SelectOutlined/SelectOutlined'
import Typography from '@material-ui/core/Typography'
import { useClinicalHistory } from '../hooks/useClinicalHistory'
import { useTranslation } from 'react-i18next'

interface LaboratoryResultsProps {
  data: ClinicalHistoryData
  handleFieldChange: (field: keyof ClinicalHistoryData, value: any) => void
  disabled: boolean
}

const LaboratoryResults: React.FC<LaboratoryResultsProps> = ({
  data,
  handleFieldChange,
  disabled,
}) => {
  const { t } = useTranslation()
  const {
    getLaboratoryOptions,
    glucemiaOptions,
    trigliceridosOptions,
    hemoglobinaGlicolisadaOptions,
    colesterolOptions,
  } = useClinicalHistory()

  useEffect(() => {
    getLaboratoryOptions()
  }, [])

  const translations = {
    laboratoryTitle: t('clinicalHistory.laboratoryTitle'),
  }

  return (
    <div>
      <GridItem md={12} className="step-title">
        <Typography variant="h6">{translations?.laboratoryTitle}</Typography>
      </GridItem>

      <GridItem md={12} style={{ marginBottom: '16px' }}>
        <SelectOutlined
          label={t('clinicalHistory.labelGlycemia')}
          options={glucemiaOptions ?? []}
          select={data.glycemiaId?.laboratoryResultsId ?? ''}
          onChange={(value) =>
            handleFieldChange('glycemiaId', { laboratoryResultsId: value })
          }
          disabled={disabled}
        />
      </GridItem>

      <GridItem md={12} style={{ marginBottom: '16px' }}>
        <SelectOutlined
          label="Hemoglobina glicosilada"
          options={hemoglobinaGlicolisadaOptions ?? []}
          select={data.glycosylatedHemoglobinId?.laboratoryResultsId ?? ''}
          onChange={(value) =>
            handleFieldChange('glycosylatedHemoglobinId', {
              laboratoryResultsId: value,
            })
          }
          disabled={disabled}
        />
      </GridItem>

      <GridItem md={12} style={{ marginBottom: '16px' }}>
        <SelectOutlined
          label="Colesterol"
          options={colesterolOptions ?? []}
          select={data.cholesterolId?.laboratoryResultsId ?? ''}
          onChange={(value) =>
            handleFieldChange('cholesterolId', { laboratoryResultsId: value })
          }
          disabled={disabled}
        />
      </GridItem>

      <GridItem md={12} style={{ marginBottom: '16px' }}>
        <SelectOutlined
          label="Triglicéridos"
          options={trigliceridosOptions ?? []}
          select={data.triglyceridesId?.laboratoryResultsId ?? ''}
          onChange={(value) =>
            handleFieldChange('triglyceridesId', { laboratoryResultsId: value })
          }
          disabled={disabled}
        />
      </GridItem>
    </div>
  )
}

export default LaboratoryResults

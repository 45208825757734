import '../Dashboard.css';

import React, { Component } from 'react';

import Button from '../../CustomButtons/Button';
import ButtonSpinner from '../../ButtonSpinner';
import ComponentDisabledWithTooltip from '../../ComponentDisabledWithTooltip';
import DateInput from '../../DateInput';
import { Grid } from '@material-ui/core';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import SelectInput from '../../SelectInput';
import { withTranslation } from 'react-i18next';

class Filters extends Component {
    constructor(props) {
        super(props);
        this.sendFilters = this.sendFilters.bind(this);
        this.state = {
            professionals: [],
            employees: [],
            dateFrom: '',
            dateTo: '',
            professional: [],
            employeeTypesFilter: [],
            oemployeesType: [],
        }
    }

    componentDidMount() {
        if (this.props.employees?.length) {
            this.setState({
                employees: this.props.employees,
                professionals: this.formatEmployees(this.props.employees),
            });
        } else {
            this.getEmployees();
        }

        if (this.props.employeesType?.length) {
            this.setState({
                employeeType: this.props.employeesType,
                oemployeeType: this.formatEmployeeTypes(this.props.oemployeesType),
            });
        } else {
            this.getEmployeeTypes();
        }
    }

    componentWillReceiveProps(next) {
        if (next.employees?.length) {
            this.setState({
                employees: next.employees,
                professionals: this.formatEmployees(next.employees),
            });
        }

        if (next.employeesType?.length) {
            this.setState({
                employeesType: next.employeesType,
                oemployeesType: this.formatEmployeeTypes(next.employeesType),
            });
        }
    }

    getEmployeeTypes() {
        const type = 'PROFESSIONAL';
        PersonApiInvoker.getAllEmployeesType(type, employees => {
            if (employees?.length) {
                this.props.onGetEmployeesType(employees);
            }
        }, e => {
            console.error(e);
        });
    }


    formatEmployeeTypes(employeeTypes) {
        if (!employeeTypes){
            return [];
        }
        return employeeTypes.map(e => ({
            id: e.employeeTypeId,
            value: e.employeeTypeName,
        }));
    }

    formatEmployees(employees) {
        if (!employees.length) {
            return [];
        }
        const result = employees.map(e => ({
            id: e.personId,
            value: `${e.lastName} ${e.firstName}`
        })).sort((a, b) => a.value.localeCompare(b.value));
        return result;
    }

    getEmployees() {
        PersonApiInvoker.getEmployees(employees => {
            if (employees?.length) {
                this.props.onGetEmployees(employees);
            }
        }, (error) => {
            console.error('** error getEmployees', error);
        });
    }

    handleValue = (value, state) => {
        this.setState({ [state]: value });
    }

    sendFilters() {
        if((this.state.professional?.length) || this.state.dateFrom || this.state.dateTo){
            this.props.onChange({
                professional: this.state.professional,
                employeeTypesFilter: this.state.employeeTypesFilter,
                dateFrom: this.state.dateFrom,
                dateTo: this.state.dateTo,
            });
        }
    }

    cleanFilters() {
        this.setState({
            professional: [],
            dateFrom: '',
            dateTo: '',
            employeeTypesFilter: [],
        }, () => {
            this.props.onClearFilters();
        });
    }

    render() {
        const { t, loading } = this.props;
        const { dateFrom, dateTo, employeeTypesFilter, oemployeesType, professional, professionals } = this.state;
        const disabledButton = !(dateFrom && dateTo && professional.length);

        return (
            <Grid container className="filters dashboard">
                <Grid item xs={8} sm={9} md={10}>
                    <Grid container item xs={12}>    
                        <Grid item xs={12} sm={4} md={3} className="date-filter date-nopadding">
                            <DateInput
                                id="date-input-from"
                                text={t('dashboard.filters.from')}
                                value={dateFrom}
                                onChangeValue={(value) => this.handleValue(value, 'dateFrom')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} className="date-filter date-nopadding">
                            <DateInput
                                id="date-input-to"
                                text={t('dashboard.filters.to')}
                                minDate
                                min={dateFrom}
                                value={dateTo}
                                onChangeValue={(value) => this.handleValue(value, 'dateTo')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item className="professionals-filter" xs={12} sm={6}>
                            <SelectInput 
                                id="select-professionals"
                                data-testid="select-professionals"
                                label={`${t('dashboard.filters.professionals')} *`}
                                chips
                                multi
                                elements={professionals}
                                value={professional}
                                onSelectedValue={(value) => this.handleValue(value, 'professional')}
                            />
                        </Grid>
                        <Grid item className="professionals-filter" xs={12} sm={6}>
                            <SelectInput
                                id="select-employees"
                                label={t('dashboard.filters.employeeTypesFilter')}
                                chips multi
                                elements={oemployeesType}
                                value={employeeTypesFilter}
                                onSelectedValue={(value) => this.handleValue(value, 'employeeTypesFilter')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className="filters-actions" xs={4} sm={3} md={2}>
                    <ComponentDisabledWithTooltip
                        tooltipText={t('dashboardProfessional.buttonTooltip')}
                        disabled={disabledButton}
                        loading={loading}
                        component={
                            <ButtonSpinner
                                block
                                label={t('dashboard.filters.search')}
                                labelLoading={t('dashboard.filters.searching')}
                                loading={loading}
                                disabled={disabledButton || loading}
                                onClick={() => this.sendFilters()}
                                color="primary"
                                id="button-search"
                            />
                        }
                    />
                    <Button
                        block
                        onClick={() => this.cleanFilters()}
                        color="danger"
                        id="button-clear"
                    >{t('dashboard.filters.clean')}</Button>
                </Grid>
            </Grid>
        )
    }
}

Filters.defaultProps = {
    loading: false,
}

Filters.propTypes = {
    t: PropTypes.func,
    onGetEmployees: PropTypes.func.isRequired,
    onGetEmployeesType: PropTypes.func.isRequired,
    onClearFilters: PropTypes.func,
    onChange: PropTypes.func,
    employees: PropTypes.array,
    loading: PropTypes.bool,
    employeesType: PropTypes.array,
    oemployeesType: PropTypes.array,
}

export default withTranslation()(Filters);

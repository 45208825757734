import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  makeStyles,
} from '@material-ui/core'
import React, { FC, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { whiteColor } from '../../assets/components/material-dashboard-pro-react'

type ValueType = string | number

type Options = {
  id: ValueType
  value: string
}

type Props = {
  label: string
  options: Options[]
  select: string | number
  onChange: (value: ValueType) => void
  disabled?: boolean
}

export const SelectOutlined: FC<Props> = ({
  label,
  options,
  select,
  onChange,
  disabled,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [value, setValue] = React.useState<ValueType>('')

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as ValueType
    if (value === newValue) {
      return
    }
    setValue(newValue)
    onChange(newValue)
  }

  useEffect(() => {
    setValue(select)
  }, [select])

  const selectedText = t('appointment.new.schedule.selected')

  return (
    <FormControl variant="standard" fullWidth className={classes.selectLine}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="glycemia-label"
        id="glycemia"
        value={value ?? ''}
        label={label}
        onChange={handleChange}
        disabled={disabled ?? false}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
              maxWidth: 400,
            },
          },
        }}
      >
        <MenuItem value="" disabled>
          <em>{selectedText}</em>
        </MenuItem>
        {options?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  selectLine: {
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
      backgroundColor: whiteColor,
      marginTop: theme.spacing(1),
    },
    '& label': {
      marginLeft: theme.spacing(1),
      marginTop: 8,
      padding: '0 5px',
      zIndex: 1,
    },
    '& label + .MuiInput-formControl': {
      marginTop: theme.spacing(1.5),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid rgba(0, 0, 0, 0.26)',
      fontSize: '0.875rem',
      height: '1.1876em',
      padding: '18.5px 14px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
        cursor: 'default',
      },
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  },
}))

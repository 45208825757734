import React, { useEffect } from 'react'
import Card from '../../../Card/Card'
import CardBody from '../../../Card/CardBody'
import CardHeader from '../../../Card/CardHeader'
import CardIcon from '../../../Card/CardIcon'
import GridContainer from '../../../Grid/GridContainer'
import HotelIcon from '@material-ui/icons/Hotel'
import Measures from './Sections/Measures'
import Nutrition from './Sections/Nutrition'
import PhysicalActivity from './Sections/PhysicalActivity'
import Vaccination from './Sections/Vaccination'

const FormPatientData = (props) => {
  const { editData, setDataPatient, save, validateFormPatientData, onlyView } =
    props

  const [dataMeasure, setDataMeasure] = React.useState(null)
  const [dataPhysicalActivity, setDataPhysicalActivity] = React.useState(null)
  const [dataNutrition, setDataNutrition] = React.useState(null)
  const [dataVaccination, setDataVaccination] = React.useState(null)
  const [isValidatedMeasure, setIsValidatedMeasure] = React.useState(false)
  const [isValidatedVacination, setIsValidatedVacination] =
    React.useState(false)

  const validateSections = () => {
    if (isValidatedMeasure && isValidatedVacination) {
      validateFormPatientData(true)
    } else {
      validateFormPatientData(false)
    }
  }

  const FormatData = () => {
    let data = {
      height: dataMeasure ? dataMeasure.height : null,
      weight: dataMeasure ? dataMeasure.weight : null,
      imcId: dataMeasure ? dataMeasure.imcId : null,
      walkDaily: dataPhysicalActivity ? dataPhysicalActivity.walkDaily : null,
      eatSalt: dataNutrition ? dataNutrition.eatSalt : null,
      eatVegetables: dataNutrition ? dataNutrition.eatVegetables : null,
      eatFruits: dataNutrition ? dataNutrition.eatFruits : null,
      fluVaccine: dataVaccination ? dataVaccination.fluVaccine : null,
      fluVaccineDate: dataVaccination ? dataVaccination.fluVaccineDate : null,
      pneumococcusVaccine: dataVaccination
        ? dataVaccination.pneumococcalVaccine
        : null,
      pneumococcalVaccineDate: dataVaccination
        ? dataVaccination.pneumococcalVaccineDate
        : null,
    }
    setDataPatient(data)
  }

  useEffect(() => {
    FormatData()
    validateSections()
  }, [
    dataMeasure,
    dataPhysicalActivity,
    dataNutrition,
    dataVaccination,
    save,
    isValidatedMeasure,
    isValidatedVacination,
    editData,
  ])

  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <HotelIcon />
        </CardIcon>
        <h4>Datos del paciente</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <Measures
            setData={setDataMeasure}
            save={save}
            setIsValidatedMeasure={setIsValidatedMeasure}
            editData={editData}
            onlyView={onlyView ? onlyView : false}
          ></Measures>
          <PhysicalActivity
            setData={setDataPhysicalActivity}
            editData={editData}
            onlyView={onlyView ? onlyView : false}
          ></PhysicalActivity>
          <Nutrition
            setData={setDataNutrition}
            editData={editData}
            onlyView={onlyView ? onlyView : false}
          ></Nutrition>
          <Vaccination
            setData={setDataVaccination}
            save={save}
            setIsValidatedVacination={setIsValidatedVacination}
            editData={editData}
            onlyView={onlyView ? onlyView : false}
          ></Vaccination>
        </GridContainer>
      </CardBody>
    </Card>
  )
}

export default FormPatientData

import React, { Component } from 'react';

import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import Button from '../../CustomButtons/Button';
import ButtonSpinner from '../../ButtonSpinner';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomDialog from '../../CustomDialog/index';
import CustomSweetAlert from '../../CustomSweetAlert';
import DateInput from '../../DateInput';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import { Icon } from '@material-ui/core';
import PersonApiInvoker from '../../../api/PersonApiInvoker';
import PropTypes from 'prop-types';
import ScheduleApi from '../../../api/ScheduleApi';
import ScheduleSystemFinished from '../ScheduleSystemFinished';
import SchedulerInvoker from '../../../api/SchedulerInvoker';
import SelectInput from '../../SelectInput';
import StatusIcon from '../StatusIcon';
import TextareaInput from '../../TextareaInput';
import TimePickerGCalendar from '../../TimePickerGCalendar';
import ValidationInput from '../../ValidationInput';
import config from '../../../config/config';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import ClinicalHistory from '../../../components/ClinicalHistory/ClinicalHistory';
import { parseISO, differenceInDays } from 'date-fns'


const dateTimeFormat = config.getDateTimeFormat();
const dateToServer = config.getDateToServer();

class BookingDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      current: null,
      professionals: [],
      errors: [],
      disabled: false,
      status: '',
      hasChanged: false,
      validation: false,
      validationResult: null,
      validating: false,
      busy: false,
      alertColor: 'info',
      alertMessage: '',
      alertOpen: false,
      showOpenCancel: false,
      openSystemFinished: false,
      cancel_motive: '',
      showClinicalHistory: false,
      hasMedicalHistory: false,
      actionHc: ""
    }
  }

  getDetail() {
    const { event } = this.props;
    if (event) {
      if (event.type === 'schedule') {
        AppointmentApiInvoker.getScheduleInCalendar(event.id, details => {
          details.startTime = moment(details.startTime, 'HH:mm:ss').format('hh:mm a');
          details.endTime = moment(details.endTime, 'HH:mm:ss').format('hh:mm a');
          this.setState({ current: details }, () => this.getEmployees())
        }, null);
        return;
      }
      AppointmentApiInvoker.getScheduleBookingInCalendar(event.id, details => {
        details.startTime = moment(details.startTime, 'HH:mm:ss').format('hh:mm a');
        details.endTime = moment(details.endTime, 'HH:mm:ss').format('hh:mm a');
        this.setState({ current: details }, () => this.getEmployees())
      }, null);
    }
  }

  componentDidMount() {
    this.getDetail();
  }

  componentWillReceiveProps(next) {
    if (next.errors) {
      let error = '';
      next.errors.map(e => {
        return error + '\n' + e.detail
      });
      const message = error.message ?? error;
      this.openAlert('danger', message);
      this.setState({ disabled: false, status: next.status, validation: false, busy: false });
    }
  }

   isWithinThreeDays(endDateTime){
          if (!endDateTime) return false
          const endDate = parseISO(endDateTime)
          const currentDate = new Date()
          const daysDifference = differenceInDays(currentDate, endDate)
          return daysDifference <= 3
   }

  openAlert(color, message) {
    this.setState({
      alertColor: color,
      alertMessage: message,
      alertOpen: true,
    });
    setTimeout(() => {
      this.setState({ alertOpen: false });
    }, 5000);
  }

  getEmployees() {
    const { current } = this.state;
    let lender = 0;
    if (current.companyIdPartnership) {
      lender = current.companyIdPartnership.companyId;
    }

    if (current) {
      PersonApiInvoker.getEmployeesByPracticetype(lender, current.practiceTypeId, data => {
        this.setState({ professionals: this.formatEmployees(data) });
      }, null);
    }
  }

  formatEmployees(data) {
    if (!data.length) {
      return [];
    }
    const result = data.map(e => ({
      id: e.personId,
      value: `${e.lastName} ${e.firstName}`,
    })).sort((a, b) => a.value.localeCompare(b.value));
    return result;
  }

  cancel = () => this.setState({ showOpenCancel: true });

  updateBooking(checking) {
    const { event } = this.props;
    if (event) {
      const fetchUrl = event.type === 'schedule'
        ? 'schedules'
        : 'bookings';

      const date = moment(this.state.current.scheduleDate).format(dateToServer);
      const time = moment(this.state.current.startTime, 'hh:mm a').format('HH:mm')

      const body = {
        employeeId: this.state.current.employeeId,
        scheduleDateTime: `${date}T${time}`,
      };

      if (checking) {
        this.setState({ validationResult: null, validation: false, validating: true, busy: false });

        SchedulerInvoker.postDynamicContentOnlyCheck(fetchUrl, event.id, body, data => {
          this.setState({ validationResult: data, validation: data.status !== 'ERROR', validating: false, busy: false });
        }, (error) => {
          this.setState({ validating: false, errors: [{
              statusError: 'ERROR',
              detail: error.message
            }],
          });
        });
      } else {
        this.setState({ busy: true });
        SchedulerInvoker.postDynamicContent(fetchUrl, event.id, body, data => {
          if (this.props.updateAppointment) {
            this.props.updateAppointment(data);
          }
          if (this.props.onUpdate) {
            this.props.onUpdate(data);
          }
        }, () => this.setState({ busy: false }));
      }
    }
  }

  systemFinished = () => this.setState({ openSystemFinished: true });

  handleChange = (value, state) => {
    const { current } = this.state;
    let hasChanged;
    if (state === 'startTime') {
      hasChanged = moment(this.props.event.start).format('hh:mm a') !== value;
      let endTime = current?.endTime;
      endTime = moment(value, 'hh:mm a').add(current?.estimatedDuration, 'minutes').format('hh:mm a');

      this.setState((prevState) => ({ current: { ...prevState.current, endTime } }));
    } else {
      hasChanged = current && current[state] !== value;
    }

    this.setState((prevState) => ({
      current: {
        ...prevState.current,
        [state]: value,
      },
      hasChanged,
      validation: !hasChanged,
      disabled: false,
      errors: [],
    }));
  }

  cancelScheduleConfirm = () => {
    this.setState({ showConfirmCancel: true, showOpenCancel: false });
  }

  cancelSchedule = () => {
    this.setState({ busy: true });
    const { event } = this.props;

    const body = {
      "scheduleIds": [event.id],
      "reasonCancel": this.state.cancel_motive
    }
    AppointmentApiInvoker.cancelSchedule(this.state.current.appointmentId, body, data => {
      this.getDetail();
      this.setState({
        showConfirm: false, showConfirmCancel: false
      })
    }, (error) => {
      const message = error.message ?? error;
      this.openAlert('danger', message);
    });
  }

  onChangeMotive = (value) => {
    if (value.length <= 255) {
      this.setState({ cancel_motive: value });
    }
  }

  closeClinicalHistory() {
    let shouldRefresh = this.state.actionHc === "create"
    this.setState({
        showClinicalHistory: false,
    });
    if(shouldRefresh){
      this.getDetail()
    }
}

  cancelBySystem = (value) => {
    this.setState({ openSystemFinished: false });
    const { event } = this.props;
    const startDateTime = moment(value['startDate']).utcOffset(0, true).format()
    const endDateTime = moment(value['endDate']).utcOffset(0, true).format()

    let presure;
    if (value['arterialTensionMax'] || value['arterialTensionMin']) {
      presure = {"max":value['arterialTensionMax'],"min":value['arterialTensionMin']};
    }
    const row = {
      "timeRegistry": moment().format(dateTimeFormat),
      "temperature":value['temperature'],
      "pulse":value['pulse'],
      "breathing":value['breathing'],
      "pressure": presure
    }
    let rowData = [];

    if (
        !row["temperature"] &&
        !row["pulse"] &&
        !row["breathing"] &&
        !row["pressure"]
      ) {
        rowData = [];
      } else {
        rowData = [ row ];
      }  

    const metadata = {
      "vitalSignsList": rowData,
      "note": (value['note'] ? value['note'] : ""),
      "start_geo_position":null,
      "end_geo_position":null
    }
    const body = {
      "startDateTime": startDateTime,
      "endDateTime": endDateTime,
      "metadata": metadata
    }
    ScheduleApi.finishBySystem(event.id, body, data => {
      this.getDetail();
      this.setState({
        showConfirm: false, showConfirmCancel: false
      })
    }, (error) => {
      const message = error.message ?? error;
      this.openAlert('danger', message);
    });
  }

  getValidations = (current, disabled) => {
    let isDisabled = disabled;
    if (current?.scheduleStatusName === 'CANCELLED') {
      isDisabled = true;
    }
    let validateDateTime = true;
    if (current != null) {
      const now = moment();
      const startDate = moment(current.scheduleDateTime);
      if (startDate.isAfter(now)) {
        validateDateTime = false;
      }

      if (validateDateTime && (current?.scheduleStatusName === 'WITHOUT_ATTENTION' || this.state.current?.scheduleStatusName === 'NOT_ATTENDED')) {
        if (moment().isAfter(current.limitFinishedDateTime)) {
          validateDateTime = false;
        }
      }
    }

    let company = localStorage.getItem("company_partnership_id");
    if (company == null) {
      company = localStorage.getItem("itlg_default_company_id");
    }
    let edit = true;
    if (current?.companyIdPartnership?.companyId != company) {
        edit = false;
        isDisabled = true;
    }
    return { edit, isDisabled, validateDateTime };
  }

  getErrors(errors) {
    if (errors?.length < 1 ) {
      return <div />;
    }
    const errorsList = errors.map((e, index) => ({ ...e, key: index+1 }));
      return errorsList.map((e) => {
      const errorType = e.statusError === 'ERROR' ? 'error' : 'warning';
      return <GridItem key={`key-${e.key}`} xs={12} className={errorType}>
        <Icon color={errorType}>{ errorType }</Icon>{' '}<span>{e.detail}</span>
      </GridItem>
    });
  }

  createHC(){
    this.setState({
      actionHc:"create",
      showClinicalHistory: true,
  })
  }

  viewHC(){
    this.setState({
      actionHc:"view",
      showClinicalHistory: true,
  })
  }

  getCurrentEdit() {
    const { t, action, mode } = this.props;
    const { busy, current, disabled, event, hasChanged, status, validating, validation } = this.state;
    const { edit, isDisabled } = this.getValidations(current, disabled);
    const disabledButton = busy || !hasChanged || !validation || (disabled && status === 'ERROR');
    const validStatusNames = ['PENDING_APPROVAL', 'APPROVED', 'REJECTED', 'NOT_ATTENDED'];
    const showButton = mode != 'view' && !isDisabled && validStatusNames.includes(current.scheduleStatusName);
    const buttonText = (event && event?.type === 'booking') || !event?.action ? t('common.schedule.cancel') : t('common.close');
    const userId = window.localStorage.getItem('user_id')
    const sameUserProf = userId === current?.employeeId.toString()
    
    // COMENTADO POR MANUEL PORQUE SINO EN MODO NO EDICION EL FINANCIANDO NO PUEDE VERLA
    /*
    if (!edit) {
      return <div />
    }*/
    
    return (
      <GridItem xs={action ? 9 : 12} className="booking-detail-actions">
        {edit && !current?.hasMedicalHistory &&  sameUserProf && current.scheduleStatus === "DONE" && this.isWithinThreeDays(current.scheduleEndDateTime) &&
        (  <Button id="button-hc"  color="primary" onClick={() => this.createHC()}>
            Agregar HC
          </Button>)
        }
        {current?.hasMedicalHistory && (
          <Button id="button-hc" color="primary" onClick={() => this.viewHC()}>
            Ver HC
          </Button>
        )
        }
        
        {showButton &&
          <Button id="button-cancel-sched" authority="cancel_schedule" color="danger" onClick={() => this.cancel()}>
            {buttonText}
          </Button>
        }
        {current.scheduleStatusName !== 'CANCELLED' && (
          <>
            <Button
              color="primary"
              onClick={() => this.updateBooking(true)}
              disabled={!hasChanged || validating}
              id="button-update-book"
            >{t('common.check')}</Button>

            <ButtonSpinner
              onClick={() => this.updateBooking()}
              disabled={disabledButton}
              label={t('common.accept')}
              labelLoading={t('common.saving')}
              loading={busy}
              typeButton="submit"
              color="primary"
              id="button-update"
            />
          </>
        )}
      </GridItem>
    );
  }

  getValidationResult() {
    const { t } = this.props;
    const { validationResult } = this.state;
    if (!validationResult) {
      return <div />;
    }
    if (validationResult?.status !== 'OK') {
      const validErrorsList = validationResult.errors.map((e, index) => ({ ...e, key: index+1 }));
      return validErrorsList.map((e) => {
        const errorType = e.statusError === 'ERROR' ? 'error' : 'warning';
        return <GridItem key={`key-${e.key}`} xs={12}>
            <GridContainer>
              <GridItem xs={12} sm={7} className={`text-center ${errorType}`}>{' '}<span>{t(e.detail)}</span></GridItem>
            </GridContainer>
          </GridItem>
      });
    }
  }

  isMeModulePractice = (partnership) => {
    const isLocalStorageEmpty = localStorage.getItem('company_partnership_id') === null;
    return !partnership || isLocalStorageEmpty || partnership.companyId === parseInt(localStorage.getItem('company_partnership_id'));
  }

  getLenderName = (partnership) => {
    const isMe = this.isMeModulePractice(partnership);
    const lenderName = isMe ? (partnership?.name ?? '') : this.props.t('common.other');
    return lenderName;
  }

  renderCurrent() {
    const { t, disabled, event, isTransfer } = this.props;
    const { current, errors, professionals, validating, validationResult } = this.state;
    const { isDisabled, validateDateTime } = this.getValidations(current, disabled);
    const lenderName = this.getLenderName(current.companyIdPartnership);
    const estimatedTime = `${moment.utc(moment.duration(parseInt(current.estimatedDuration), 'minutes').asMilliseconds()).format('HH:mm')} ${t('estimated_minutes')}`;
    const statusName = current.scheduleStatus ? t(`schedule.booking.state.${current.scheduleStatus}`) : t(`schedule.booking.state.${current.scheduleBookingStatus}`);
    const validStatusNames = ['PENDING_APPROVAL', 'APPROVED', 'IN_PROGRESS', 'NOT_ATTENDED', 'WITHOUT_ATTENTION'];
    const showFinishedButton = validateDateTime && validStatusNames.includes(current.scheduleStatusName);
    const gridItemSize = event?.id ? 6 : 12;
    const buttonText = event?.type === 'booking' || !event?.action ? t('schedule.detail.system.finished') : t('common.close');
    const nameProfessional = isTransfer ? "Chofer" :`${t('common.professional')} *`
    return (
      <GridContainer>
        <GridItem xs={12} sm={gridItemSize}>
          <ValidationInput
            id="input-patient-name"
            text={t('common.patient')}
            value={`${current.customerLastName} ${current.customerFirstName}`}
            disabled={true}
          />
        </GridItem>
        {event?.id &&
          <GridItem xs={12} sm={6}>
            <ValidationInput
              id="input-id"
              text={t('common.scheduleNumber')}
              value={event.id}
              disabled={true}
            />
          </GridItem>
        }
        <GridItem xs={12}>
          <SelectInput
            id="select-employee"
            label={nameProfessional}
            elements={professionals}
            value={current.employeeId}
            disabled={isDisabled}
            onSelectedValue={value => this.handleChange(value, 'employeeId')}
            invalid={!current.employeeId}
          />
        </GridItem>
        <GridItem xs={12}>
          <ValidationInput
            id="input-lender"
            text={`${t('common.lender')}`}
            value={lenderName}
            disabled={true}
          />
        </GridItem>

        <GridItem xs={12}>
          <ValidationInput
            id="input-speciality"
            text={t('common.speciality')}
            value={current.practiceTypeName}
            disabled={true}
          />
        </GridItem>
        <GridItem xs={12}>
          {(current.practiceTypeName) &&
            <ValidationInput
              id="estimated-label"
              text={t('estimated_label')}
              value={estimatedTime}
              disabled={true}
            />
          }
        </GridItem>
        <GridItem xs={12}>
          <ValidationInput
            id="input-satatus"
            text={t('schedule.booking.detail.statusname')}
            value={statusName}
            disabled={true}
          />
        </GridItem>
        {current.scheduleStatus === 'REJECTED' &&
          <GridItem xs={12}>
            <ValidationInput
              id="input-rejected-reason"
              text={t('schedule.booking.detail.reasonRejected')}
              value={current.reasonRejected}
              disabled={true}
            />
          </GridItem>
        }
        <GridItem xs={12}>
          <DateInput
            id="datetime-start"
            text={t('common.date')}
            disabled={isDisabled}
            value={current.scheduleDate}
            onChangeValue={value => this.handleChange(value, 'scheduleDate')}
          />
        </GridItem>
        <GridItem xs={6} className="start-time booking-det">
          <TimePickerGCalendar
            id="time-start"
            text={`${t('common.startTime')} *`}
            value={current.startTime}
            onSelectedValue={value => this.handleChange(value, 'startTime')}
            disabled={isDisabled}
            name="booking_detail_start_time"
            invalid={!current.startTime}
            errorText={!current.startTime ? t('error.required') : ''}
          />
        </GridItem>
        <GridItem xs={6} className="end-time booking-det">
          <TimePickerGCalendar
            id="time-end"
            name="booking_detail_end_time"
            text={t('common.endTime')}
            value={current.endTime}
            invalid={!current.endTime}
            disabled={true}
            errorText={!current.endTime ? t("error.required") : ''}
          />
        </GridItem>
        <GridItem xs={12}>
          <ValidationInput
            id="input-address"
            text={t('address.title')}
            value={`${current.addressStreet} ${current.addressNumber}`}
            disabled={true}
          />
        </GridItem>
        {current.observations &&
          <GridItem xs={12}>
            <TextareaInput
              id="textarea-observation"
              text={t('label.observation')}
              value={current.observations}
              disabled={true}
            />
          </GridItem>
        }

        {this.getErrors(errors)}

        <GridItem xs={12}>
          <div>
            {validating &&
              <div style={{ display: 'flex', height: '200px', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </div>}
          </div>
          {validationResult && (<StatusIcon value={validationResult.status} />)}
        </GridItem>
        {current?.scheduleStatusName === 'CANCELLED' &&
          <GridItem xs={12}>
            <div>
              <ValidationInput id="input-reason" text={t('common.reasonCancel')} value={this.state.current.reasonCancel} disabled={isDisabled} />
            </div>
            <div>
              <ValidationInput id="input-employee" value={t('common.cancelEmployeeName') + ": " + this.state.current.cancelEmployeeName} disabled={isDisabled} />
            </div>
          </GridItem>
        }

        {this.getValidationResult()}

        <GridItem xs={3} className="finished-button">
        {this.props.action === 'finished_system' ?
            showFinishedButton &&
              <Button id="button-system-finished" authority="end_schedule_by_system" color="primary" onClick={() => this.systemFinished()}>
                {buttonText}
              </Button>
            : <div />}
        </GridItem>

        {this.getCurrentEdit()}
      </GridContainer>
    )
  }

  render() {
    const user = localStorage.getItem('username');
    const { t, event } = this.props;
    const { cancel_motive, current, openSystemFinished, showConfirmCancel, showOpenCancel, isTransfer, showClinicalHistory, actionHc } = this.state;
    return (
      <div className="booking-detail-form booking-detail">
        {current && this.renderCurrent()}
        <CustomDialog
          title={t('schedule.cancel.title')}
          maxWidth="sm"
          open={showOpenCancel}
          onClose={() => this.setState({ showOpenCancel: false })}
          isTransfer={isTransfer}
        >
          <div>
            <div>
              <ValidationInput
                id="input-motive"
                text={t('schedule.cancel.motive')}
                onChangeValue={(value) => this.onChangeMotive(value)}
                value={cancel_motive}
              />
            </div>
            <p className="style-user">{t('login.user')}: {user}</p>

          </div>
          <div className="content-actions">
            <ButtonSpinner
              onClick={() => this.cancelScheduleConfirm()}
              disabled={cancel_motive == ''}
              label={t('schedule.cancel.acept')}
              typeButton="submit"
              color="primary"
              id="button-cancel"
            />
          </div>
        </CustomDialog>

        {showConfirmCancel &&
          <CustomSweetAlert
            type="warning"
            title={t('schedule.cancel.title')}
            onConfirm={() => this.cancelSchedule()}
            onCancel={() => this.setState({ showOpenCancel: true, showConfirmCancel: false })}
            showCancel={true}
            showConfirm={true}
            confirmBtnCssClass="primary"
            cancelBtnCssClass="danger"
            cancelBtnText={t('common.cancel')}
            confirmBtnText={t('common.confirm')}
            message={
              <div className="contentMessage" dangerouslySetInnerHTML={
                { __html: t('schedule.cancel.confirm.body', { practiceType: current.practiceTypeName }) }} />
            }
          />
        }
        <CustomDialog
          className="booking-detail-component dialog-schedule-system-finished"
          title={t('appointment.scheduleDetail')}
          maxWidth="md"
          open={openSystemFinished}
          onClose={() => this.setState({ openSystemFinished: false })}
        >
          <ScheduleSystemFinished
            current={current}
            scheduleId={event?.id}
            cancelBySystem={this.cancelBySystem}
            onCancel={() => this.setState({ openSystemFinished: false })}
          />
        </CustomDialog>
        <CustomDialog
                    title="Historia Clínica"
                    open={showClinicalHistory}
                    onClose={() => this.closeClinicalHistory()}
                >
                    <ClinicalHistory closeModal={() => this.closeClinicalHistory()} scheduleId={event.id} action={actionHc}/>
                </CustomDialog>
      </div>
    )
  }
}

BookingDetail.propTypes = {
  t: PropTypes.func,
  event: PropTypes.object,
  isTransfer: PropTypes.string,
  updateAppointment: PropTypes.func,
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func,
  action: PropTypes.string,
  mode: PropTypes.string,
}

export default withTranslation()(BookingDetail);

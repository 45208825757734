/* eslint-disable no-unused-expressions */

import '../HospitalizationStyles.css'

import { Add, Assignment } from '@material-ui/icons'
import React, { useEffect, useMemo, useState } from 'react'

import Card from '../../Card/Card.jsx'
import CardBody from '../../Card/CardBody.jsx'
import CardHeader from '../../Card/CardHeader.jsx'
import CardIcon from '../../Card/CardIcon.jsx'
import CustomInput from '../../CustomInput/CustomInput.jsx'
import GridContainer from '../../Grid/GridContainer'
import GridItem from '../../Grid/GridItem'
import HozpitalizationApiInvoker from '../../../api/HospitalizationApiInvoker'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import MedicationsForm from '../MedicationsForm'
import { Modal } from '@material-ui/core'
import PatologiesApiInvoker from '../../../api/RecordsApiInvoker'
import RadioInput from '../../RadioInput'
import RegularButton from '../../CustomButtons/Button.jsx'
import SelectInput from '../../SelectInput/index.js'
import { v4 as uuidv4 } from 'uuid'

const FormDischarge = (props) => {
  const {
    SetDataDischargeForm,
    editDischarge,
    editData,
    onlyView,
    setIsValidatedDischargeForm,
    setFrequencyError,
    save,
  } = props

  const [clinicalHistorySummary, setClinicalHistorySummary] = useState(null)
  const [nursing, setNursing] = useState('no')
  const [nursingFrequency, setNursingFrequency] = useState(null)
  const [kinesiology, setKinesiology] = useState('no')
  const [kinesiologyFrequency, setKinesiologyFrequency] = useState(null)
  const [speechTherapy, setSpeechTherapy] = useState('no')
  const [speechTherapyFrequency, setSpeechTherapyFrequency] = useState(null)
  const [occupationalTherapy, setOccupationalTherapy] = useState('no')
  const [occupationalTherapyFrequency, setOccupationalTherapyFrequency] =
    useState(null)
  const [psychology, setPsychology] = useState('no')
  const [psychologyFrequency, setPsychologyFrequency] = useState(null)
  const [homeAssistant, setHomeAssistant] = useState('no')
  const [homeAssistantFrequency, setHomeAssistantFrequency] = useState(null)
  const [wheelchair, setWheelchair] = useState('no')
  const [orthopedicBed, setOrthopedicBed] = useState('no')
  const [walker, setWalker] = useState('no')
  const [oxygenConcentrator5Liter, setOxygenConcentrator5Liter] = useState('no')
  const [oxygenConcentrator10Liter, setOxygenConcentrator10Liter] =
    useState('no')
  const [healing, setHealing] = useState('no')
  const [curationsFrequency, setCurationsFrequency] = useState(null)
  const [dressingsType, setDressingsType] = useState(null)
  const [dressingsTypeName, setDressingsTypeName] = useState(null)
  const [dressingsTypeOptions, setDressingsTypeOptions] = useState([])
  const [medicationsData, setMedicationsData] = useState([])
  const [medicineTypeOptions, setMedicineTypeOptions] = useState([])
  const [medicineUnitPresentationOptions, setMedicineUnitPresentationOptions] =
    useState([])
  const [medicineUnitTypeOptions, setMedicineUnitTypeOptions] = useState([])
  const [medicineWayTypeOptions, setMedicineWayTypeOptions] = useState([])
  const [frequencyRangesOptions, setFrequencyRangesOptions] = useState([])
  const [isValidatedFrequency, setIsValidatedFrequency] = useState(false)

  const [medication, setMedication] = useState(null)
  const [presentation, setPresentation] = useState(null)
  const [dose, setDose] = useState(null)
  const [unit, setUnit] = useState(null)
  const [way, setWay] = useState(null)
  const [openKatz, setOpenKatz] = useState(false)
  const [barthelIndex, setBarthelIndex] = useState(null)
  const [validateAddMedication, setValidateAddMedication] = useState(true)
  const [showDressingsType, setShowDressingsType] = useState(false)
  const [berthelIndexOptions, setBerthelIndexOptions] = useState([])
  const [katzIndex, setKatzIndex] = useState(null)
  const [katzIndexId, setKatzIndexId] = useState(null)
  const [dressed, setDressed] = useState(null)
  const [bath, setBath] = useState(null)
  const [toilet, setToilet] = useState(null)
  const [mobility, setMobility] = useState(null)
  const [continence, setContinence] = useState(null)
  const [feeding, setFeeding] = useState(null)
  const [calculate, setCalculate] = useState(false)
  const [isValidated, setIsValidated] = useState(false)
  const [katzIndexOptions, setKatzIndexOptions] = useState([
    {
      id: 2,
      value: 'Dependencia',
    },
    {
      id: 1,
      value: 'Independencia',
    },
  ])

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleOpenKatz = () => setOpenKatz(true)
  const handleCloseKatz = () => setOpenKatz(false)

  let medicalRecordLenth = clinicalHistorySummary
    ? clinicalHistorySummary.length
    : 0

  const addMedication = (data) => {
    setMedicationsData([...medicationsData, data])
    handleClose()
    resetValuesForMedication()
  }

  const resetValuesForMedication = () => {
    setMedication(null)
    setPresentation(null)
    setDose(null)
    setWay(null)
  }

  const validateKatzIndex = () => {
    setCalculate(true)
    if (
      bath != null &&
      dressed != null &&
      toilet != null &&
      mobility != null &&
      continence != null &&
      feeding != null
    ) {
      setCalculate(false)
      let katzData = [
        { type: 'bath', point: bath === 2 ? 0 : 1 },
        { type: 'dressed', point: dressed === 2 ? 0 : 1 },
        { type: 'toilet', point: toilet === 2 ? 0 : 1 },
        { type: 'mobility', point: mobility === 2 ? 0 : 1 },
        { type: 'continence', point: continence === 2 ? 0 : 1 },
        { type: 'feeding', point: feeding === 2 ? 0 : 1 },
      ]
      let points = [
        { point: bath === 2 ? 0 : 1 },
        { point: dressed === 2 ? 0 : 1 },
        { point: toilet === 2 ? 0 : 1 },
        { point: mobility === 2 ? 0 : 1 },
        { point: continence === 2 ? 0 : 1 },
        { point: feeding === 2 ? 0 : 1 },
      ]
      validateResultKatzIndex(katzData, points)
    }
  }

  const validateResultKatzIndex = (data, points) => {
    let totalPoints = 0
    points.forEach((point) => {
      totalPoints += point.point
    })

    switch (totalPoints) {
      case 6:
        setKatzIndex('Grado A. Ausencia de incapacidad (0 puntos)')
        break
      case 5:
        setKatzIndex('Grado B. Incapacidad leve (1 punto)')
        break
      case 4:
        if (data[0].point === 0) {
          setKatzIndex('Grado C. Incapacidad moderada (2 puntos)')
        } else {
          setKatzIndex(
            'Grado H. Incapacidad moderada a severa. No calificable como C, D, E ó F. (2 a 6 puntos)'
          )
        }
        break
      case 3:
        if (data[0].point === 0 && data[1].point === 0) {
          setKatzIndex('Grado D. Incapacidad moderada (3 puntos)')
        } else {
          setKatzIndex(
            'Grado H. Incapacidad moderada a severa. No calificable como C, D, E ó F. (2 a 6 puntos)'
          )
        }
        break
      case 2:
        if (data[0].point === 0 && data[1].point === 0 && data[2].point === 0) {
          setKatzIndex('Grado E. Incapacidad severa (4 puntos)')
        } else {
          setKatzIndex(
            'Grado H. Incapacidad moderada a severa. No calificable como C, D, E ó F. (2 a 6 puntos)'
          )
        }
        break
      case 1:
        if (
          data[0].point === 0 &&
          data[1].point === 0 &&
          data[2].point === 0 &&
          data[3].point === 0
        ) {
          setKatzIndex('Grado F. Incapacidad severa (5 puntos)')
        } else {
          setKatzIndex(
            'Grado H. Incapacidad moderada a severa. No calificable como C, D, E ó F. (2 a 6 puntos)'
          )
        }
        break
      case 0:
        setKatzIndex('Grado G. Incapacidad severa (6 puntos)')
        break
      default:
        break
    }
    handleCloseKatz()
  }

  const removeMedication = (id) => {
    const newMedications = medicationsData.filter(
      (medication) =>
        medication.id !== id.id ||
        medication.axaInternmentMedicineId !== id.axaInternmentMedicineId
    )
    setMedicationsData(newMedications)
  }

  const validateForm = () => {
    if (barthelIndex && katzIndex && validateFrequency) {
      setIsValidatedDischargeForm(true)
    } else {
      setIsValidatedDischargeForm(false)
    }
  }

  let data = {
    clinical_history_summary: clinicalHistorySummary,
    nursing: nursing === 'yes' ? true : false,
    nursing_frequency: nursingFrequency
      ? {
          frequencyRangesId: nursingFrequency,
        }
      : null,
    kinesiology: kinesiology === 'yes' ? true : false,
    kinesiology_frequency: kinesiologyFrequency
      ? {
          frequencyRangesId: kinesiologyFrequency,
        }
      : null,
    speechTherapy: speechTherapy === 'yes' ? true : false,
    speechTherapy_frequency: speechTherapyFrequency
      ? {
          frequencyRangesId: speechTherapyFrequency,
        }
      : null,
    occupationalTherapy: occupationalTherapy === 'yes' ? true : false,
    occupationalTherapy_frequency: occupationalTherapyFrequency
      ? {
          frequencyRangesId: occupationalTherapyFrequency,
        }
      : null,
    psychology: psychology === 'yes' ? true : false,
    psychology_frequency: psychologyFrequency
      ? {
          frequencyRangesId: psychologyFrequency,
        }
      : null,
    homeAssistant: homeAssistant === 'yes' ? true : false,
    homeAssistant_frequency: homeAssistantFrequency
      ? {
          frequencyRangesId: homeAssistantFrequency,
        }
      : null,
    curations: healing === 'yes' ? true : false,
    curations_frequency:
      curationsFrequency === null
        ? null
        : {
            frequencyRangesId: curationsFrequency,
          },
    dressings_type: dressingsType,
    wheelchair: wheelchair === 'yes' ? true : false,
    orthopedic_bed: orthopedicBed === 'yes' ? true : false,
    walker: walker === 'yes' ? true : false,
    oxygenConcentrator5Liter: oxygenConcentrator5Liter === 'yes' ? true : false,
    oxygenConcentrator10Liter:
      oxygenConcentrator10Liter === 'yes' ? true : false,
    medicineList: medicationsData,
    barthel_index: barthelIndex,
    internmentKatz: {
      internmentKatzId: katzIndexId,
      bathing: bath === 2 ? 0 : 1,
      dress: dressed === 2 ? 0 : 1,
      usingTheToilet: toilet === 2 ? 0 : 1,
      mobility: mobility === 2 ? 0 : 1,
      continence: continence === 2 ? 0 : 1,
      feeding: feeding === 2 ? 0 : 1,
      indexKatz: katzIndex,
    },
  }

  const formatDressingsType = (index) =>
    index.map((e) => ({
      id: e.dressingsTypeId,
      value: e.name,
    }))

  const SetDressingsType = () => {
    HozpitalizationApiInvoker.getDressingsType((response) => {
      let dressingsType = formatDressingsType(response)
      dressingsType.unshift({
        id: 'otro',
        value: 'Otro... (Agregar)',
      })
      setDressingsTypeOptions(dressingsType)
    })
  }

  const formatMedicineType = (index) =>
    index.map((e) => ({
      id: e.medicineTypeId,
      value: e.name,
    }))

  const SetMedicineType = () => {
    HozpitalizationApiInvoker.getMedicinesType((response) => {
      let medicinesType = formatMedicineType(response)
      setMedicineTypeOptions(medicinesType)
    })
  }

  const formatMedicineUnitType = (index) =>
    index.map((e) => ({
      id: e.medicineUnitTypeId,
      value: e.name,
    }))

  const formatFrequencyRanges = (index) =>
    index.map((e) => ({
      id: e.frequencyRangesId,
      value: e.ranges,
    }))

  const SetMedicineUnitType = () => {
    HozpitalizationApiInvoker.getMedicinesUnitType((response) => {
      let medicinesUnitType = formatMedicineUnitType(response)
      setMedicineUnitTypeOptions(medicinesUnitType)
    })
  }

  const formatMedicineWayType = (index) =>
    index.map((e) => ({
      id: e.medicineViaTypeId,
      value: e.name,
    }))

  const SetMedicineWayType = () => {
    HozpitalizationApiInvoker.getMedicinesWayType((response) => {
      let medicinesWayType = formatMedicineWayType(response)
      setMedicineWayTypeOptions(medicinesWayType)
    })
  }

  const SetFrequencyRanges = () => {
    HozpitalizationApiInvoker.getFrequencyRanges((response) => {
      let frequencyRanges = formatFrequencyRanges(response)
      setFrequencyRangesOptions(frequencyRanges)
    })
  }

  const formatMedicinePresentationType = (index) =>
    index.map((e) => ({
      id: e.medicinePresentationTypeId,
      value: e.name,
    }))

  const SetMedicinePresentationType = () => {
    HozpitalizationApiInvoker.getMedicinesPresentationType((response) => {
      let medicinesPresentationType = formatMedicinePresentationType(response)
      setMedicineUnitPresentationOptions(medicinesPresentationType)
    })
  }

  const SetValidateAddMedication = () => {
    if (!medication || !presentation || !dose || !unit || !way) {
      setValidateAddMedication(true)
    } else {
      setValidateAddMedication(false)
    }
  }

  const addDressingsType = (value) => {
    if (value === 'otro') {
      setShowDressingsType(true)
      setDressingsType(null)
    } else {
      setDressingsType(value)
    }
  }

  const addDressingsTypeOption = () => {
    let data = {
      name: dressingsTypeName,
    }
    HozpitalizationApiInvoker.addDressingsType(data, (response) => {
      SetDressingsType()
      setShowDressingsType(false)
    })
  }

  const resetValues = () => {
    setMedication(null)
    setPresentation(null)
    setDose(null)
    setUnit(null)
    setWay(null)
  }

  const validateFrequency = () => {
    if (
      (nursing === 'yes' && !nursingFrequency) ||
      (kinesiology === 'yes' && !kinesiologyFrequency) ||
      (speechTherapy === 'yes' && !speechTherapyFrequency) ||
      (occupationalTherapy === 'yes' && !occupationalTherapyFrequency) ||
      (psychology === 'yes' && !psychologyFrequency) ||
      (homeAssistant === 'yes' && !homeAssistantFrequency) ||
      (healing === 'yes' && !curationsFrequency)
    ) {
      setFrequencyError(false)
    } else {
      setFrequencyError(true)
    }
  }

  const SetDataEdit = (data) => {
    setDressed(
      data.internmentKatz && data.internmentKatz.dress === 0
        ? 2
        : data.internmentKatz && data.internmentKatz.dress === 1
        ? 1
        : null
    )
    setBath(
      data.internmentKatz && data.internmentKatz.bathing === 0
        ? 2
        : data.internmentKatz && data.internmentKatz.bathing === 1
        ? 1
        : null
    )
    setToilet(
      data.internmentKatz && data.internmentKatz.usingTheToilet === 0
        ? 2
        : data.internmentKatz && data.internmentKatz.usingTheToilet === 1
        ? 1
        : null
    )
    setMobility(
      data.internmentKatz && data.internmentKatz.mobility === 0
        ? 2
        : data.internmentKatz && data.internmentKatz.mobility === 1
        ? 1
        : null
    )
    setContinence(
      data.internmentKatz && data.internmentKatz.continence === 0
        ? 2
        : data.internmentKatz && data.internmentKatz.continence === 1
        ? 1
        : null
    )
    setFeeding(
      data.internmentKatz && data.internmentKatz.feeding === 0
        ? 2
        : data.internmentKatz && data.internmentKatz.feeding === 1
        ? 1
        : null
    )
    setKatzIndex(data.internmentKatz && data.internmentKatz.indexKatz)
    setBarthelIndex(data.barthelIndexId)
    setKatzIndexId(data.internmentKatz && data.internmentKatz.internmentKatzId)
    setClinicalHistorySummary(data.medicalRecord)
    setNursingFrequency(data.nursing_frequency)
    setKinesiologyFrequency(data.kinesiology_frequency)
    setSpeechTherapyFrequency(data.speechTherapy_frequency)
    setOccupationalTherapyFrequency(data.occupationalTherapy_frequency)
    setPsychologyFrequency(data.psychology_frequency)
    setHomeAssistantFrequency(data.homeAssistant_frequency)
    setCurationsFrequency(data.healing_frequency)
    setDressingsType(data.dressingsTypeId)
    setMedicationsData(data.medicineList)

    for (const key in data) {
      switch (key) {
        case 'nursing':
          setNursing(data.nursing === true ? 'yes' : 'no')
          break
        case 'kinesiology':
          setKinesiology(data.kinesiology === true ? 'yes' : 'no')
          break
        case 'speechTherapy':
          setSpeechTherapy(data.speechTherapy === true ? 'yes' : 'no')
          break
        case 'occupationalTherapy':
          setOccupationalTherapy(
            data.occupationalTherapy === true ? 'yes' : 'no'
          )
          break
        case 'psychology':
          setPsychology(data.psychology === true ? 'yes' : 'no')
          break
        case 'homeAssistant':
          setHomeAssistant(data.homeAssistant === true ? 'yes' : 'no')
          break
        case 'healing':
          setHealing(data.healing === true ? 'yes' : 'no')
          break
        case 'wheelchair':
          setWheelchair(data.wheelchair === true ? 'yes' : 'no')
          break
        case 'orthopedic_bed':
          setOrthopedicBed(data.orthopedic_bed === true ? 'yes' : 'no')
          break
        case 'walkerno':
          setWalker(data.walkerno === true ? 'yes' : 'no')
          break
        case 'oxygenConcentrator5Liter':
          setOxygenConcentrator5Liter(
            data.oxygenConcentrator5Liter === true ? 'yes' : 'no'
          )
          break
        case 'oxygenConcentrator10Liter':
          setOxygenConcentrator10Liter(
            data.oxygenConcentrator10Liter === true ? 'yes' : 'no'
          )
          break
        default:
          break
      }
    }
  }

  const formatBerthelIndex = (index) =>
    index.map((e) => ({
      id: e.id,
      value: e.name,
    }))

  const SetBerthelIndexData = () => {
    PatologiesApiInvoker.getAllBarthel((data) => {
      let berthelIndex = formatBerthelIndex(data)
      setBerthelIndexOptions(berthelIndex)
    })
  }

  useEffect(() => {
    SetDressingsType()
    SetMedicineType()
    SetMedicineUnitType()
    SetMedicineWayType()
    SetMedicinePresentationType()
    SetFrequencyRanges()
    SetBerthelIndexData()
    if (editData) {
      SetDataEdit(editData)
    }
  }, [editData])

  useEffect(() => {
    validateFrequency()
    validateForm()
    SetDataDischargeForm(data)
    SetValidateAddMedication()
  }, [
    dressingsType,
    clinicalHistorySummary,
    nursing,
    nursingFrequency,
    kinesiology,
    kinesiologyFrequency,
    speechTherapy,
    speechTherapyFrequency,
    occupationalTherapy,
    occupationalTherapyFrequency,
    psychology,
    psychologyFrequency,
    homeAssistant,
    homeAssistantFrequency,
    curationsFrequency,
    wheelchair,
    orthopedicBed,
    walker,
    oxygenConcentrator5Liter,
    oxygenConcentrator10Liter,
    editDischarge,
    medicationsData,
    medication,
    presentation,
    dose,
    unit,
    way,
    showDressingsType,
    barthelIndex,
    save,
    bath,
    dressed,
    toilet,
    mobility,
    continence,
    feeding,
    calculate,
    katzIndex,
    setFrequencyError,
    isValidated,
  ])

  return (
    <Card>
      <Modal
        open={openKatz}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <>
          <div className="modal-medication">
            <Card>
              <CardHeader color="primary" icon>
                <GridItem md={12}>
                  <CardIcon color="warning">
                    <Assignment />
                  </CardIcon>
                  <h4>Índice de Katz</h4>
                </GridItem>
              </CardHeader>
              <CardBody>
                <form action="">
                  <GridContainer>
                    <GridItem md={4}>
                      <SelectInput
                        label="Bañarse"
                        onSelectedValue={(value) => setBath(value)}
                        elements={katzIndexOptions}
                        value={bath ? bath : ''}
                        invalid={bath == null && calculate}
                      ></SelectInput>
                    </GridItem>
                    <GridItem md={4}>
                      <SelectInput
                        label="Vestirse"
                        onSelectedValue={(value) => setDressed(value)}
                        elements={katzIndexOptions}
                        value={dressed ? dressed : ''}
                        invalid={dressed == null && calculate}
                      ></SelectInput>
                    </GridItem>
                    <GridItem md={4}>
                      <SelectInput
                        label="Usar el retrete"
                        onSelectedValue={(value) => setToilet(value)}
                        elements={katzIndexOptions}
                        value={toilet ? toilet : ''}
                        invalid={toilet == null && calculate}
                      ></SelectInput>
                    </GridItem>
                    <GridItem md={4}>
                      <SelectInput
                        label="Movilidad"
                        onSelectedValue={(value) => setMobility(value)}
                        elements={katzIndexOptions}
                        value={mobility ? mobility : ''}
                        invalid={mobility == null && calculate}
                      ></SelectInput>
                    </GridItem>
                    <GridItem md={4}>
                      <SelectInput
                        label="Continencia"
                        onSelectedValue={(value) => setContinence(value)}
                        elements={katzIndexOptions}
                        value={continence ? continence : ''}
                        invalid={continence == null && calculate}
                      ></SelectInput>
                    </GridItem>
                    <GridItem md={4}>
                      <SelectInput
                        label="Alimentación"
                        onSelectedValue={(value) => setFeeding(value)}
                        elements={katzIndexOptions}
                        value={feeding ? feeding : ''}
                        invalid={feeding == null && calculate}
                      ></SelectInput>
                    </GridItem>
                    <GridItem md={12}>
                      <div className="modal-footer">
                        <RegularButton
                          onClick={handleCloseKatz}
                          size="sm"
                          color="default"
                        >
                          Cancelar
                        </RegularButton>
                        <RegularButton
                          onClick={validateKatzIndex}
                          size="sm"
                          color="primary"
                        >
                          Calcular
                        </RegularButton>
                      </div>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </div>
        </>
      </Modal>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <LocalHospitalIcon />
        </CardIcon>
        <h4>Planilla de egreso</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem md={12}>
            <CustomInput
              value={clinicalHistorySummary ? clinicalHistorySummary : ''}
              labelText="Resumen de historia clínica breve"
              multiline={true}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                maxlength: '2500',
                disabled: onlyView,
              }}
              helpText={`Hasta 2500 caracteres. Te quedan ${
                2500 - medicalRecordLenth
              } `}
              onChange={(e) => setClinicalHistorySummary(e.target.value)}
            />
          </GridItem>
          <GridItem md={12}>
            <h4>Indicaciones</h4>
          </GridItem>
          <GridItem md={12}>
            <div className="radio-contaiter">
              <p className="label-radiobox-container">Enfermería:</p>
              <RadioInput
                value={nursing ? nursing : 'no'}
                elements={[
                  { value: 'Si', id: 'yes' },
                  { value: 'No', id: 'no' },
                ]}
                inputProps={{ disabled: onlyView }}
                onChangeValue={(value) => {
                  if (value === 'no') {
                    setNursingFrequency(0)
                  }
                  setNursing(value)
                }}
              />
              <SelectInput
                label="Frecuencia por semana:"
                onSelectedValue={(value) => setNursingFrequency(value)}
                elements={frequencyRangesOptions}
                value={nursingFrequency}
                disabled={nursing === 'no' || onlyView}
                invalid={nursing === 'yes' && !nursingFrequency}
              ></SelectInput>
            </div>
          </GridItem>
          <GridItem md={12}>
            <div className="radio-contaiter">
              <p className="label-radiobox-container">Kinesiología:</p>
              <RadioInput
                value={kinesiology ? kinesiology : 'no'}
                elements={[
                  { value: 'Si', id: 'yes' },
                  { value: 'No', id: 'no' },
                ]}
                inputProps={{ disabled: onlyView }}
                onChangeValue={(value) => {
                  if (value === 'no') {
                    setKinesiologyFrequency(0)
                  }
                  setKinesiology(value)
                }}
                disabled={onlyView}
              />
              <SelectInput
                label="Frecuencia por semana:"
                onSelectedValue={(value) => setKinesiologyFrequency(value)}
                elements={frequencyRangesOptions}
                value={kinesiologyFrequency}
                disabled={kinesiology === 'no' || onlyView}
                invalid={kinesiology === 'yes' && !kinesiologyFrequency}
              ></SelectInput>
            </div>
          </GridItem>
          <GridItem md={12}>
            <div className="radio-contaiter">
              <p className="label-radiobox-container">Fonoaudiología:</p>
              <RadioInput
                value={speechTherapy ? speechTherapy : 'no'}
                elements={[
                  { value: 'Si', id: 'yes' },
                  { value: 'No', id: 'no' },
                ]}
                inputProps={{ disabled: onlyView }}
                onChangeValue={(value) => {
                  if (value === 'no') {
                    setSpeechTherapyFrequency(0)
                  }
                  setSpeechTherapy(value)
                }}
                disabled={onlyView}
              />
              <SelectInput
                label="Frecuencia por semana:"
                onSelectedValue={(value) => setSpeechTherapyFrequency(value)}
                elements={frequencyRangesOptions}
                value={speechTherapyFrequency}
                disabled={speechTherapy === 'no' || onlyView}
                invalid={speechTherapy === 'yes' && !speechTherapyFrequency}
              ></SelectInput>
            </div>
          </GridItem>
          <GridItem md={12}>
            <div className="radio-contaiter">
              <p className="label-radiobox-container">Terapia ocupacional:</p>
              <RadioInput
                value={occupationalTherapy ? occupationalTherapy : 'no'}
                elements={[
                  { value: 'Si', id: 'yes' },
                  { value: 'No', id: 'no' },
                ]}
                inputProps={{ disabled: onlyView }}
                onChangeValue={(value) => {
                  if (value === 'no') {
                    setOccupationalTherapyFrequency(0)
                  }
                  setOccupationalTherapy(value)
                }}
                disabled={onlyView}
              />
              <SelectInput
                label="Frecuencia por semana:"
                onSelectedValue={(value) =>
                  setOccupationalTherapyFrequency(value)
                }
                elements={frequencyRangesOptions}
                value={occupationalTherapyFrequency}
                disabled={occupationalTherapy === 'no' || onlyView}
                invalid={
                  occupationalTherapy === 'yes' && !occupationalTherapyFrequency
                }
              ></SelectInput>
            </div>
          </GridItem>
          <GridItem md={12}>
            <div className="radio-contaiter">
              <p className="label-radiobox-container">Psicología:</p>
              <RadioInput
                value={psychology ? psychology : 'no'}
                elements={[
                  { value: 'Si', id: 'yes' },
                  { value: 'No', id: 'no' },
                ]}
                inputProps={{ disabled: onlyView }}
                onChangeValue={(value) => {
                  if (value === 'no') {
                    setPsychologyFrequency(0)
                  }
                  setPsychology(value)
                }}
              />
              <SelectInput
                label="Frecuencia por semana:"
                onSelectedValue={(value) => setPsychologyFrequency(value)}
                elements={frequencyRangesOptions}
                value={psychologyFrequency}
                disabled={psychology === 'no' || onlyView}
                invalid={psychology === 'yes' && !psychologyFrequency}
              ></SelectInput>
            </div>
          </GridItem>
          <GridItem md={12}>
            <div className="radio-contaiter">
              <p className="label-radiobox-container">
                Asistente domiciliario:
              </p>
              <RadioInput
                value={homeAssistant ? homeAssistant : 'no'}
                elements={[
                  { value: 'Si', id: 'yes' },
                  { value: 'No', id: 'no' },
                ]}
                inputProps={{ disabled: onlyView }}
                onChangeValue={(value) => {
                  if (value === 'no') {
                    setHomeAssistantFrequency(0)
                  }
                  setHomeAssistant(value)
                }}
                disabled={onlyView}
              />
              <SelectInput
                label="Frecuencia por semana:"
                onSelectedValue={(value) => setHomeAssistantFrequency(value)}
                elements={frequencyRangesOptions}
                value={homeAssistantFrequency}
                disabled={homeAssistant === 'no' || onlyView}
                invalid={homeAssistant === 'yes' && !homeAssistantFrequency}
              ></SelectInput>
            </div>
          </GridItem>
          <GridItem md={12}>
            <div className="container-dressing">
              <div className="radio-contaiter">
                <p className="label-radiobox-container">Curaciones:</p>
                <RadioInput
                  value={healing ? healing : 'no'}
                  elements={[
                    { value: 'Si', id: 'yes' },
                    { value: 'No', id: 'no' },
                  ]}
                  inputProps={{ disabled: onlyView }}
                  onChangeValue={(value) => {
                    if (value === 'no') {
                      setCurationsFrequency(null)
                      setDressingsType(null)
                    }
                    setHealing(value)
                  }}
                  disabled={onlyView}
                />
                <SelectInput
                  label="Frecuencia por semana:"
                  onSelectedValue={(value) => setCurationsFrequency(value)}
                  elements={frequencyRangesOptions}
                  value={curationsFrequency}
                  disabled={healing === 'no' || onlyView}
                  invalid={healing === 'yes' && !curationsFrequency}
                ></SelectInput>
              </div>
              {!showDressingsType ? (
                <GridItem md={5}>
                  <SelectInput
                    label="Tipos de apósitos:"
                    onSelectedValue={(value) => addDressingsType(value)}
                    elements={dressingsTypeOptions}
                    value={dressingsType}
                    disabled={healing === 'no' || onlyView}
                    invalid={healing === 'yes' && !dressingsType}
                  ></SelectInput>
                </GridItem>
              ) : (
                <>
                  <GridItem md={2}>
                    <CustomInput
                      value={dressingsTypeName}
                      labelText="Nombre *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                      }}
                      onChange={(e) => setDressingsTypeName(e.target.value)}
                    />
                  </GridItem>
                  <div className="delete-medication-button">
                    <GridItem md={2}>
                      <div className="button-add-option">
                        <RegularButton
                          className="delete-medication-button"
                          onClick={addDressingsTypeOption}
                          disabled={!dressingsTypeName}
                          color="primary"
                          size="sm"
                        >
                          Agregar
                        </RegularButton>
                        <RegularButton
                          className="delete-medication-button"
                          onClick={() => setShowDressingsType(false)}
                          color="danger"
                          size="sm"
                        >
                          X
                        </RegularButton>
                      </div>
                    </GridItem>
                  </div>
                </>
              )}
            </div>
          </GridItem>
          <GridItem md={12}>
            <h4>Equipamiento</h4>
          </GridItem>
          <GridItem md={12}>
            <div className="radio-contaiter">
              <p className="label-radiobox-container">Silla de ruedas:</p>
              <RadioInput
                value={wheelchair}
                elements={[
                  { value: 'Si', id: 'yes' },
                  { value: 'No', id: 'no' },
                ]}
                inputProps={{
                  disabled: onlyView,
                }}
                onChangeValue={(value) => setWheelchair(value)}
                disabled={onlyView}
              />
            </div>
          </GridItem>
          <GridItem md={12}>
            <div className="radio-contaiter">
              <p className="label-radiobox-container">Cama ortopédica:</p>
              <RadioInput
                value={orthopedicBed}
                elements={[
                  { value: 'Si', id: 'yes' },
                  { value: 'No', id: 'no' },
                ]}
                inputProps={{
                  disabled: onlyView,
                }}
                onChangeValue={(value) => setOrthopedicBed(value)}
                disabled={onlyView}
              />
            </div>
          </GridItem>
          <GridItem md={12}>
            <div className="radio-contaiter">
              <p className="label-radiobox-container">Andador:</p>
              <RadioInput
                value={walker}
                elements={[
                  { value: 'Si', id: 'yes' },
                  { value: 'No', id: 'no' },
                ]}
                inputProps={{
                  disabled: onlyView,
                }}
                onChangeValue={(value) => setWalker(value)}
                disabled={onlyView}
              />
            </div>
          </GridItem>
          <GridItem md={12}>
            <h4>Oxigenoterapia</h4>
          </GridItem>
          <GridItem md={12}>
            <div className="radio-contaiter">
              <p className="label-radiobox-container">
                Concentrador de oxígeno 5 lts:
              </p>
              <RadioInput
                value={oxygenConcentrator5Liter}
                elements={[
                  { value: 'Si', id: 'yes' },
                  { value: 'No', id: 'no' },
                ]}
                inputProps={{
                  disabled: onlyView,
                }}
                onChangeValue={(value) => setOxygenConcentrator5Liter(value)}
                disabled={onlyView}
              />
            </div>
          </GridItem>
          <GridItem md={12}>
            <div className="radio-contaiter">
              <p className="label-radiobox-container">
                Concentrador de oxígeno 10 lts:
              </p>
              <RadioInput
                inputProps={{
                  disabled: onlyView,
                }}
                name="oxigen"
                value={oxygenConcentrator10Liter}
                elements={[
                  { value: 'Si', id: 'yes' },
                  { value: 'No', id: 'no' },
                ]}
                onChangeValue={(value) => setOxygenConcentrator10Liter(value)}
                disabled={onlyView}
              />
            </div>
          </GridItem>
          <GridItem md={12}>
            <h4>Medicación</h4>
          </GridItem>
          {medicationsData && (
            <>
              {medicationsData.map((medication) => (
                <MedicationsForm
                  dataM={medication}
                  removeMedication={removeMedication}
                  withoutRemove={false}
                  onlyView={onlyView}
                ></MedicationsForm>
              ))}
            </>
          )}
          <GridItem md={12}>
            <div>
              <RegularButton
                onClick={() => {
                  handleOpen()
                  resetValues()
                }}
                size="sm"
                color="warning"
                disabled={onlyView}
              >
                <Add className="appointments-button-icon" /> Agregar Medicación
              </RegularButton>
            </div>
          </GridItem>
          <Modal
            open={open}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <>
              <div className="modal-medication">
                <Card>
                  <CardHeader color="primary" icon>
                    <GridItem md={12}>
                      <CardIcon color="warning">
                        <LocalHospitalIcon />
                      </CardIcon>
                      <h4>Medicación</h4>
                    </GridItem>
                  </CardHeader>
                  <CardBody>
                    <form action="">
                      <GridContainer>
                        <GridItem md={4}>
                          <SelectInput
                            id="medication"
                            label="Medicación *"
                            onSelectedValue={(value) => setMedication(value)}
                            elements={medicineTypeOptions}
                            value={medication}
                          ></SelectInput>
                        </GridItem>
                        <GridItem md={4}>
                          <SelectInput
                            id="presentacion"
                            label="Presentación *"
                            onSelectedValue={(value) => setPresentation(value)}
                            elements={medicineUnitPresentationOptions}
                            value={presentation}
                          ></SelectInput>
                        </GridItem>
                        <GridItem md={4}>
                          <CustomInput
                            labelText="Dosis *"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: 'number',
                            }}
                            value={dose}
                            disabled={!presentation}
                            onChange={(e) => setDose(e.target.value)}
                          />
                        </GridItem>
                        <GridItem md={4}>
                          <SelectInput
                            id="unit"
                            label="Unidad *"
                            onSelectedValue={(value) => setUnit(value)}
                            elements={medicineUnitTypeOptions}
                            value={unit}
                          ></SelectInput>
                        </GridItem>
                        <GridItem md={4}>
                          <SelectInput
                            id="via"
                            label="Vía *"
                            onSelectedValue={(value) => setWay(value)}
                            elements={medicineWayTypeOptions}
                            value={way}
                          ></SelectInput>
                        </GridItem>
                        <GridItem md={4}></GridItem>
                        <GridItem md={4}></GridItem>
                        <GridItem xs={12}>
                          <p className="required-text">
                            <small>*</small> {'Campos requeridos'}
                          </p>
                        </GridItem>

                        <GridItem md={12}>
                          <div className="modal-footer">
                            <RegularButton
                              onClick={handleClose}
                              size="sm"
                              color="default"
                            >
                              Cancelar
                            </RegularButton>
                            <RegularButton
                              onClick={() =>
                                addMedication({
                                  id: uuidv4(),
                                  medicineTypeId: {
                                    medicineTypeId: medication,
                                    name: document.getElementById('medication')
                                      ? document.getElementById('medication')
                                          .value
                                      : '',
                                  },
                                  medicinePresentationType: {
                                    medicinePresentationTypeId: presentation,
                                    name: document.getElementById(
                                      'presentacion'
                                    )
                                      ? document.getElementById('presentacion')
                                          .value
                                      : '',
                                  },
                                  medicineDose: dose,
                                  medicineViaTypeId: {
                                    medicineViaTypeId: way,
                                    name: document.getElementById('via')
                                      ? document.getElementById('via').value
                                      : '',
                                  },
                                  medicineUnitType: {
                                    medicineUnitTypeId: unit,
                                    name: document.getElementById('unit')
                                      ? document.getElementById('unit').value
                                      : '',
                                  },
                                })
                              }
                              size="sm"
                              color="success"
                              disabled={validateAddMedication}
                            >
                              Agregar
                            </RegularButton>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </div>
            </>
          </Modal>
          <hr className="separator" />
          <GridItem md={12}>
            <div>
              <h4>Índice de katz *</h4>
            </div>
          </GridItem>
          <GridItem md={12}>
            {katzIndex ? (
              <div>
                <h5>{katzIndex}</h5>
              </div>
            ) : (
              <>
                {!katzIndex && save ? (
                  <p className="hospitalization-detail-message error-text">
                    Calcular en índice de katz es requerido
                  </p>
                ) : (
                  <p>Aún no se ha calculado en índice de katz</p>
                )}
              </>
            )}
          </GridItem>
          <GridItem md={12}>
            <div>
              <RegularButton
                onClick={() => {
                  handleOpenKatz()
                }}
                size="sm"
                color="warning"
                disabled={onlyView}
              >
                <Add className="appointments-button-icon" /> Calcular índice de
                katz
              </RegularButton>
            </div>
          </GridItem>
          <hr className="separator" />
          <GridItem md={4}>
            <SelectInput
              label="Índice de Barthel *"
              onSelectedValue={(value) => setBarthelIndex(parseInt(value))}
              elements={berthelIndexOptions}
              value={barthelIndex ? barthelIndex : ''}
              invalid={!barthelIndex && save}
              disabled={onlyView}
            ></SelectInput>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  )
}

export default FormDischarge

import '../../../DiabeticProgramStyles.css'
import React, { useEffect } from 'react'
import CustomInput from '../../../../CustomInput/CustomInput'
import DateInput from '../../../../DateInput'
import GridItem from '../../../../Grid/GridItem'
import RadioInput from '../../../../RadioInput'
import ValidationInput from '../../../../ValidationInput'

const Respiratory = (props) => {
  const { setData, setValidateRespiratorySection, editData, onlyView } = props

  const [respiratoryRate, setRespiratoryRate] = React.useState(null)
  const [hemoptysis, setHemoptysis] = React.useState('no')
  const [cough, setCough] = React.useState('no')
  const [breathing, setBreathing] = React.useState('no')
  const [smoking, setSmoking] = React.useState('no')
  const [asthma, setAsthma] = React.useState('no')
  const [asthmaDate, setAsthmaDate] = React.useState(null)
  const [pneumonia, setPneumonia] = React.useState('no')
  const [pneumoniaDate, setPneumoniaDate] = React.useState(null)
  const [chronicBronchitis, setChronicBronchitis] = React.useState('no')
  const [chronicBronchitisDate, setChronicBronchitisDate] = React.useState(null)
  const [lungCancer, setLungCancer] = React.useState('no')
  const [lungCancerDate, setLungCancerDate] = React.useState(null)

  const [commentsBreathing, setCommentsBreathing] = React.useState(null)

  let commentsBreathingLength = commentsBreathing ? commentsBreathing.length : 0

  const FormatData = () => {
    let data = {
      respiratoryRate: respiratoryRate,
      hemoptysis: hemoptysis === 'yes' ? true : false,
      cough: cough === 'yes' ? true : false,
      breathing: breathing === 'yes' ? true : false,
      smoking: smoking === 'yes' ? true : false,
      asthma: asthma === 'yes' ? true : false,
      asthmaDate: asthmaDate,
      pneumonia: pneumonia === 'yes' ? true : false,
      pneumoniaDate: pneumoniaDate,
      chronicBronchitis: chronicBronchitis === 'yes' ? true : false,
      chronicBronchitisDate: chronicBronchitisDate,
      lungCancer: lungCancer === 'yes' ? true : false,
      lungCancerDate: lungCancerDate,
      commentsBreathing: commentsBreathing,
    }
    setData(data)
  }

  const validationSection = () => {
    if (
      (asthma === 'yes' && !asthmaDate) ||
      (pneumonia === 'yes' && !pneumoniaDate) ||
      (chronicBronchitis === 'yes' && !chronicBronchitisDate) ||
      (lungCancer === 'yes' && !lungCancerDate) ||
      (respiratoryRate && (respiratoryRate < 0 || respiratoryRate > 300))
    ) {
      setValidateRespiratorySection(false)
    } else {
      setValidateRespiratorySection(true)
    }
  }

  const setEditData = (data) => {
    setRespiratoryRate(data.respiratoryRate)
    setHemoptysis(data.hemoptysis === true ? 'yes' : 'no')
    setCough(data.cough === true ? 'yes' : 'no')
    setBreathing(data.breathing === true ? 'yes' : 'no')
    setSmoking(data.smoking === true ? 'yes' : 'no')
    setAsthma(data.asthma === true ? 'yes' : 'no')
    setAsthmaDate(data.asthmaDate)
    setPneumonia(data.pneumonia === true ? 'yes' : 'no')
    setPneumoniaDate(data.pneumoniaDate)
    setChronicBronchitis(data.chronicBronchitis === true ? 'yes' : 'no')
    setChronicBronchitisDate(data.chronicBronchitisDate)
    setLungCancer(data.lungCancer === true ? 'yes' : 'no')
    setLungCancerDate(data.lungCancerDate)
    setCommentsBreathing(data.commentsBreathing)
  }

  useEffect(() => {
    if (editData) {
      setEditData(editData); 
    }
  }, [editData]); 
  
  useEffect(() => {
    FormatData(); 
    validationSection(); 
  }, [
    respiratoryRate,
    hemoptysis,
    cough,
    breathing,
    smoking,
    asthma,
    asthmaDate,
    pneumonia,
    pneumoniaDate,
    chronicBronchitis,
    chronicBronchitisDate,
    lungCancer,
    lungCancerDate,
    commentsBreathing,
  ]);

  return (
    <>
      <GridItem md={12}>
        <h4>Respiratorio</h4>
      </GridItem>
      <GridItem md={5}>
        <ValidationInput
          value={respiratoryRate ? respiratoryRate : ''}
          text="Frecuencia respiratoria (n° respiraciones por minuto)"
          formControlProps={{
            fullWidth: true,
          }}
          onChangeValue={(value) => setRespiratoryRate(value)}
          type="number"
          invalid={respiratoryRate < 0 || respiratoryRate > 300}
          disabled={onlyView}
        />
      </GridItem>
      <GridItem md={7}></GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Hemoptisis:</p>
          <RadioInput
            value={hemoptysis}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setHemoptysis(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Tos:</p>
          <RadioInput
            value={cough}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setCough(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic">
          <p className="label-radiobox-container">Tabaquismo:</p>
          <RadioInput
            value={smoking}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{ disabled: onlyView }}
            onChangeValue={(value) => setSmoking(value)}
          />
        </div>
      </GridItem>
      <GridItem md={12}>
        <h5>Antecedentes</h5>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Asma:</p>
          <RadioInput
            value={asthma}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setAsthmaDate(null)
                setAsthma(value)
              } else {
                setAsthma(value)
              }
            }}
          />
          {asthma === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setAsthmaDate(value)}
              value={asthmaDate}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
              disabled={onlyView}
              error={asthma === 'yes' && !asthmaDate}
              errorText={
                asthma === 'yes' && !asthmaDate ? 'Debe ingresar una fecha' : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Neumonía:</p>
          <RadioInput
            value={pneumonia}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setPneumoniaDate(null)
                setPneumonia(value)
              } else {
                setPneumonia(value)
              }
            }}
          />
          {pneumonia === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setPneumoniaDate(value)}
              value={pneumoniaDate}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
              disabled={onlyView}
              error={pneumonia === 'yes' && !pneumoniaDate}
              errorText={
                pneumonia === 'yes' && !pneumoniaDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Bronquitis crónica:</p>
          <RadioInput
            value={chronicBronchitis}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setChronicBronchitisDate(null)
                setChronicBronchitis(value)
              } else {
                setChronicBronchitis(value)
              }
            }}
          />
          {chronicBronchitis === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setChronicBronchitisDate(value)}
              value={chronicBronchitisDate}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
              disabled={onlyView}
              error={chronicBronchitis === 'yes' && !chronicBronchitisDate}
              errorText={
                chronicBronchitis === 'yes' && !chronicBronchitisDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <div className="radio-contaiter-diabetic-vaccination">
          <p className="label-radiobox-diabetic">Cáncer de pulmón:</p>
          <RadioInput
            value={lungCancer}
            elements={[
              { value: 'Si', id: 'yes' },
              { value: 'No', id: 'no' },
            ]}
            inputProps={{
              disabled: onlyView,
            }}
            onChangeValue={(value) => {
              if (value === 'no') {
                setLungCancerDate(null)
                setLungCancer(value)
              } else {
                setLungCancer(value)
              }
            }}
          />
          {lungCancer === 'yes' ? (
            <DateInput
              text="Fecha *"
              onChangeValue={(value) => setLungCancerDate(value)}
              value={lungCancerDate}
              isRequired={true}
              inputProps={{ disabled: onlyView }}
              disabled={onlyView}
              error={lungCancer === 'yes' && !lungCancerDate}
              errorText={
                lungCancer === 'yes' && !lungCancerDate
                  ? 'Debe ingresar una fecha'
                  : ''
              }
            />
          ) : (
            <GridItem md={12}></GridItem>
          )}
        </div>
      </GridItem>
      <GridItem md={12}>
        <CustomInput
          value={commentsBreathing ? commentsBreathing : ''}
          labelText="Comentarios:"
          multiline={true}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            maxlength: '500',
            disabled: onlyView,
          }}
          helpText={`Hasta 500 caracteres. Te quedan ${
            500 - commentsBreathingLength
          } `}
          onChange={(e) => setCommentsBreathing(e.target.value)}
        />
      </GridItem>
    </>
  )
}

export default Respiratory

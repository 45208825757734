import React, { useState, useEffect } from 'react';

import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '../../../../../icons/DownloadIcon';
import GridContainer from '../../../../Grid/GridContainer';
import GridItem from '../../../../Grid/GridItem';
import Button from '../../../../CustomButtons/Button';
import CustomSweetAlert from '../../../../CustomSweetAlert';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

const ModalFileUpload = ({ onSelectedValue, t, file, endDate, fullWidth }) => {
  const [fileName, setFileName] = useState('');
  const [fileDate, setFileDate] = useState('');
  const [fileBase64, setFileBase64] = useState('')
  const [fileType, setFileType] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('');
  const [effectiveDate, setEffectiveDate] = useState(true);
  let authorityAgenda= true

  const company_partnership_id = localStorage.getItem('company_partnership_id');
    if (company_partnership_id != null) {
      authorityAgenda = false
    }

  // Si el prop file está disponible, inicializamos los estados
  useEffect(() => {
    const inputDate = moment(endDate, 'YYYY-MM-DD'); // Fecha a comparar
    const today = moment(); // Fecha actual

    if (file) {
      setFileName(file.name);
      setFileDate(file.date || file.dateUpload);
      setFileBase64(file.base64 || file.file);
      setFileType(file.type);
      if (inputDate.isBefore(today, 'day')) {
        setEffectiveDate(false)
      }
      
    }
  }, [file]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      validateFile(selectedFile)
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      validateFile(droppedFile);
    }
  };

  const validateFile = (file) => {
    const acceptedFormats = ['application/pdf', 'image/png', 'image/jpeg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    const maxSizeMB = 4;

    // Verifica si el archivo tiene el formato aceptado
    if (!acceptedFormats.includes(file.type)) {
      setAlertMessage('appointment.file.alert.typeFile');
      setShowAlert(true)
      return;
    }

    // Verifica si el archivo es mayor a 4MB
    if (file.size / 1024 / 1024 > maxSizeMB) {
      setAlertMessage('appointment.file.alert.fileWeight');
      setShowAlert(true);
      return;
    }
    convertToBase64(file); // Si todo está correcto, convierte el archivo
  };

  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64Content = reader.result.split(',')[1]; 
      const extension = file.type.split('/')[1]; 
      const base64WithExtension = `${extension},${base64Content}`; 
      
      const currentDate = new Date();
      
      const fileData = {
        name: file.name,
        type: file.type,
        date: `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`,
        base64: base64WithExtension, // Base64 con la extensión incluida
      };
  
      setFileBase64(base64WithExtension);
      setFileName(file.name);
      setFileType(file.type)
      setFileDate(fileData.date);
  
      // Ejecutar onSelectedValue con el objeto de datos del archivo
      onSelectedValue(fileData)
    };
  };
  

  const handleRemoveFile = () => {
    setFileName('');
    setFileDate('');
    setFileBase64('');
    setFileType('');
    onSelectedValue(null); // Notificar que el archivo ha sido eliminado
    setDeleteAlert(false);
  };

  return (
    <GridContainer className="schedule-plan no-padding">
      {fullWidth ?
            <GridItem className="base-font" xs={12}>
              <h4>{t('new.file.upload')}</h4>
            </GridItem>
          : <></>
          }
      <GridItem xs={12} sm={fullWidth ? 12 :6} className="schedule-plan-group">
        <GridItem xs={12}>
          {authorityAgenda ?
          effectiveDate || fileName ?
            <div
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
              style={{
                border: '2px dashed #ccc',
                textAlign: 'center',
                margin: fullWidth ? '0px' : '0px 18px',
              }}
            >
              {!fileName ? 
              (
                effectiveDate && authorityAgenda ?
                <>
                  <input
                    type="file"
                    accept=".pdf,.png,jpg,.doc,.docx"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    id="file-upload"
                  />
                  <label htmlFor="file-upload">
                    <div style={{ cursor: 'pointer' }}>
                      <span
                        role="img"
                        aria-label="upload"
                        style={{ fontSize: '2rem', color: '#5DC4B9' }}
                      >
                        <DownloadIcon />
                      </span>
                      <p>{t('drag.drop.file')}</p>
                    </div>
                  </label>
                </>
                : <></>
              ) : (
                <GridContainer>
                  <GridItem xs={8} style={{ textAlign: 'left', paddingTop: '10px !important' }}>
                    <p style={{ paddingTop: '10px' }}>
                      <strong>{t('users.view.firstName')}:</strong>{' '}
                      <a
                        href={fileBase64}
                        download={fileName}
                        style={{ textDecoration: 'underline', color: '#5DC4B9', cursor: 'pointer' }}
                      >
                        {fileName}
                      </a>
                    </p>
                    <p>
                      <strong>{t('common.dateTime')}:</strong> {fileDate}
                    </p>
                  </GridItem>
                  <GridItem xs={4}>
                    {effectiveDate && authorityAgenda ?
                      <Button
                      color="danger"
                      onClick={()=>setDeleteAlert(true)}
                      style={{ marginTop: '12px', width: '90%' }}
                    >
                      <DeleteIcon />
                      {t('common.remove')}
                    </Button>
                    :<></>
                    }
                   
                  </GridItem>
                </GridContainer>
              )}
            </div>
            :<></>  
        :
        !fileName ?<></> : 
          <div
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            style={{
              border: '2px dashed #ccc',
              textAlign: 'center',
              margin: '0px 18px',
            }}
          >
            <GridContainer>
              <GridItem xs={8} style={{ textAlign: 'left', paddingTop: '10px !important' }}>
                <p style={{ paddingTop: '10px' }}>
                  <strong>{t('users.view.firstName')}:</strong>{' '}
                  <a
                    href={fileBase64}
                    download={fileName}
                    style={{ textDecoration: 'underline', color: '#5DC4B9', cursor: 'pointer' }}
                  >
                    {fileName}
                  </a>
                </p>
                <p>
                  <strong>{t('common.dateTime')}:</strong> {fileDate}
                </p>
              </GridItem>
              
            </GridContainer>
          </div>
      }
        </GridItem>
      </GridItem>
      {showAlert && (
        <CustomSweetAlert
          type="warning"
          title={t('appointment.file.alert.title')}
          onCancel={() => setShowAlert(false)}
          cancelBtnCssClass="danger"
          cancelBtnText={t('common.cancel')}
          onConfirm={() => setShowAlert(false)}
          confirmBtnCssClass="primary"
          confirmBtnText={t('common.accept')}
          message={<p>{t(alertMessage)}</p>}
        />
      )}
       {deleteAlert && (
        <CustomSweetAlert
          type="warning"
          title={t('appointment.file.alert.delete.title')}
          onCancel={() => setDeleteAlert(false)}
          cancelBtnCssClass="danger"
          cancelBtnText={t('common.cancel')}
          onConfirm={handleRemoveFile}
          confirmBtnCssClass="primary"
          confirmBtnText={t('common.accept')}
          message={<p>{t('appointment.file.alert.delete')}</p>}
        />
      )}
    </GridContainer>
  );
};

ModalFileUpload.propTypes = {
  onSelectedValue: PropTypes.func.isRequired,
  file: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    date: PropTypes.string,
    base64: PropTypes.string,
    dateUpload:PropTypes.string,
  }),
  t: PropTypes.func,
};

export default withTranslation()(ModalFileUpload);

import {
  ActionTypes,
  CapillaryGlucoseUnitType,
  ClinicalHistoryData,
  Medicine,
  MedicineDataResponse,
  MedicinePresentationType,
  MedicineType,
  MedicineUnitType,
  MedicineViaType,
  OptionSelectors,
  RootClinicalHistory,
} from '../interfaces'
import { SnackbarColor, SnackbarProps } from '../../../util/interfaces'
import { initialFormState, initialMedicine } from '../constants'
import { useEffect, useState } from 'react'

import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker'
import DiabeticApiInvoker from '../../../api/DiabeticApiIvoker'
import { HCProps } from '../ClinicalHistory'
import HistoriaClinicaApi from '../../../api/HistoriaClinicaApi'
import PatologiesApiInvoker from '../../../api/RecordsApiInvoker'
import { format } from 'date-fns'
import { initialValueSnackbar } from '../../../util'

export const useClinicalHistory = (
  props?: HCProps,
  shouldFetch: boolean = false
) => {
  const scheduleIdToUse = props?.params?.id || props?.scheduleId
  const actionMode = props?.params?.action || props?.action
  const [currentStep, setCurrentStep] = useState(1)
  const [formState, setFormState] =
    useState<RootClinicalHistory>(initialFormState)
  const [loading, setLoading] = useState(false)
  const [glucemiaOptions, setGlucemiaOptions] = useState<OptionSelectors[]>([])
  const [imcOptions, setImcOptions] = useState<OptionSelectors[]>([])
  const [colesterolOptions, setColesterolOptions] = useState<OptionSelectors[]>(
    []
  )
  const [hemoglobinaGlicolisadaOptions, setHemoglobinaGlicolisadaOptions] =
    useState<OptionSelectors[]>([])
  const [trigliceridosOptions, setTrigliceridosOptions] = useState<
    OptionSelectors[]
  >([])
  const [medicineTypes, setMedicineTypes] = useState<MedicineType[]>([])
  const [medicineViaTypes, setMedicineViaTypes] = useState<MedicineViaType[]>(
    []
  )
  const [medicinePresentationTypes, setMedicinePresentationTypes] = useState<
    MedicinePresentationType[]
  >([])
  const [medicineUnitTypes, setMedicineUnitTypes] = useState<
    MedicineUnitType[]
  >([])
  const [capillaryGlucoseUnitTypes, setCapillaryGlucoseUnitTypes] = useState<
    CapillaryGlucoseUnitType[]
  >([])
  const [currentMedicine, setCurrentMedicine] =
    useState<Medicine>(initialMedicine)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [practiceTypes, setPracticeTypes] = useState<OptionSelectors[]>()
  const [error, setError] = useState({
    error: false,
    title: '',
    description: '',
  })
  const itemsDisabled: boolean = actionMode === ActionTypes.VIEW ? true : false
  const [snackbar, setSnackbar] = useState<SnackbarProps>(initialValueSnackbar)

  const openSnackbar = (color: SnackbarColor, message: string): void => {
    setSnackbar({ color, message, open: true })
    setTimeout(() => {
      setSnackbar(initialValueSnackbar)
    }, 5000)
  }

  const handleError = (description: string, title?: string): void => {
    setError({
      error: true,
      title: title ?? 'Error',
      description,
    })
  }

  useEffect(() => {
    if (
      shouldFetch &&
      scheduleIdToUse !== undefined &&
      actionMode === ActionTypes.VIEW
    ) {
      fetchAndSetClinicalHistory(scheduleIdToUse)
    }
    getIMCOptions()
  }, [])

  useEffect(() => {
    if (shouldFetch) {
      calculateIMC()
      if (
        !formState?.patientClinicalHistory?.heightValue &&
        !formState?.patientClinicalHistory?.weightValue
      ) {
        setFormState((prev) => ({
          ...prev,
          patientClinicalHistory: {
            ...prev.patientClinicalHistory,
            imc: '',
          },
        }))
      }
    }
  }, [
    formState.patientClinicalHistory.heightValue,
    formState.patientClinicalHistory.weightValue,
  ])

  const handleFieldChange = (field: keyof ClinicalHistoryData, value: any) => {
    setFormState((prev) => ({
      ...prev,
      patientClinicalHistory: {
        ...prev.patientClinicalHistory,
        [field]: value,
      },
    }))
  }

  const handleMedicineFieldChange = (
    field: keyof Medicine,
    value: any,
    data: Medicine
  ) => {
    setCurrentMedicine((prevState) => {
      const updatedMedicine = { ...prevState, [field]: value }
      return updatedMedicine
    })
  }

  const fetchAndSetClinicalHistory = (scheduleId: number | string) => {
    setLoading(true)
    HistoriaClinicaApi.getClinicalHistory(
      scheduleId,
      (data: RootClinicalHistory[]) => {
        if (data.length) {
          setFormState(data[0])
        } else {
          handleError('No se ha encontrado información de la Historia Clínica')
        }
        setLoading(false)
      },
      (error: any) => {
        setLoading(false)
        handleError(error)
        console.error('Error fetching clinical history:', error)
      }
    )
  }
  const handleCloseError = () => {
    setError({
      error: false,
      title: 'Error',
      description: '',
    })
  }

  const getIMCOptions = async () => {
    setLoading(true)
    try {
      DiabeticApiInvoker.getAllIMCOptions((data: OptionSelectors) => {
        let imcOptions = formatOptions(data)
        setImcOptions(imcOptions)
      })
    } catch (error) {
      handleError('Error en servicio IMC')
      console.error('Error fetching IMC options:', error)
    } finally {
      setLoading(false)
    }
  }

  const calculateIMC = () => {
    const { heightValue, weightValue } = formState.patientClinicalHistory

    if (heightValue && weightValue && imcOptions.length > 0) {
      const sanitizedHeight = heightValue.toString().replace(/[.,]/g, '')
      const heightMts = parseInt(sanitizedHeight) / 100
      const imc = weightValue / (heightMts * heightMts)

      let imcId = ''
      if (imc < 18.5) imcId = `${imc.toFixed(1)} - ${imcOptions[0].value}`
      else if (imc >= 18.5 && imc <= 24.9)
        imcId = `${imc.toFixed(1)} - ${imcOptions[1].value}`
      else if (imc >= 25 && imc <= 29.9)
        imcId = `${imc.toFixed(1)} - ${imcOptions[2].value}`
      else if (imc >= 30) imcId = `${imc.toFixed(1)} - ${imcOptions[3].value}`

      setFormState((prev) => ({
        ...prev,
        patientClinicalHistory: {
          ...prev.patientClinicalHistory,
          imc: imcId,
        },
      }))
    }
  }

  const updateFormState = (updatedData: Partial<ClinicalHistoryData>) => {
    setFormState((prev) => ({ ...prev, ...updatedData }))
  }

  const goNextStep = () => setCurrentStep((prev) => prev + 1)
  const goPreviousStep = () => setCurrentStep((prev) => Math.max(prev - 1, 1))

  const handleSaveOrNext = () => {
    if (currentStep === 5) setShowModal(true)
    else goNextStep()
  }

  //stepp laboratoryResults
  const getGlucemiaOptions = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      DiabeticApiInvoker.getAllGlucemiaOptions(
        (data: any) => resolve(data),
        (error: any) => reject(error)
      )
    })
  }

  const getColesterolOptions = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      DiabeticApiInvoker.getAllColesterolOptions(
        (data: any) => resolve(data),
        (error: any) => reject(error)
      )
    })
  }

  const getHemoglobinaGlicolisadaOptions = (): Promise<any> => {
    setLoading(true)
    return new Promise((resolve, reject) => {
      DiabeticApiInvoker.getAllHemoglobinaGlicolisadaOptions(
        (data: any) => resolve(data),
        (error: any) => reject(error)
      )
      setLoading(false)
    })
  }

  const getTrigliceridosOptions = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      DiabeticApiInvoker.getAllTriglicéridosOptions(
        (data: any) => resolve(data),
        (error: any) => reject(error)
      )
    })
  }

  const getLaboratoryOptions = async () => {
    setLoading(true)
    try {
      const [
        glucemiaData,
        colesterolData,
        hemoglobinaGlicolisadaData,
        triglicéridosData,
      ] = await Promise.all([
        getGlucemiaOptions(),
        getColesterolOptions(),
        getHemoglobinaGlicolisadaOptions(),
        getTrigliceridosOptions(),
      ])
      setGlucemiaOptions(formatOptions(glucemiaData))
      setColesterolOptions(formatOptions(colesterolData))
      setHemoglobinaGlicolisadaOptions(
        formatOptions(hemoglobinaGlicolisadaData)
      )
      setTrigliceridosOptions(formatOptions(triglicéridosData))
    } catch (error) {
      handleError('Error Servicio Laboratorio')
      console.error('Error al obtener las opciones de laboratorio:', error)
    } finally {
      setLoading(false)
    }
  }

  const formatOptions = (data: any): OptionSelectors[] => {
    return data.map((item: { id: number; label: string }) => ({
      id: item.id,
      value: item.label,
    }))
  }

  //diagnostic

  const formatPathologies = (patologies: any) =>
    patologies.map((e: any) => ({
      id: e.id,
      value: e.name,
    }))
  const [pathologyOptions, setPathologyOptions] = useState([])

  const getPathologiesData = () => {
    setLoading(true)
    PatologiesApiInvoker.getAllPatologies((data: any) => {
      let pathologies = formatPathologies(data)
      setPathologyOptions(pathologies)
      setLoading(false)
    })
  }

  //medicines

  const getMedicineResources = () => {
    setLoading(true)
    HistoriaClinicaApi.getMedicineResources(
      (response: any) => {
        const data = response as MedicineDataResponse
        setMedicineTypes(data.medicineType)
        setMedicineViaTypes(data.medicineViaType)
        setMedicinePresentationTypes(data.medicinePresentationType)
        setMedicineUnitTypes(data.medicineUnitType)
        setCapillaryGlucoseUnitTypes(data.capillaryGlucoseUnitType)
        setLoading(false)
      },
      (error: any) => {
        setLoading(false)
        handleError('Error al obtener la información de Historia Clínica')
        console.error('Error fetching medicine data:', error)
      }
    )
  }

  const createNewMedicine = () => {
    const newMedicine: Medicine = {
      scheduleMedicineId: 0,
      medicineType: { medicineTypeId: null, name: '' },
      medicineUnitType: { medicineUnitTypeId: 0, name: '' },
      medicinePresentationType: { medicinePresentationTypeId: null, name: '' },
      medicineQuantity: null,
      medicineDose: null,
      medicineViaType: { medicineViaTypeId: null, name: '' },
      medicineLotNumber: '',
      medicineExpireDate: '',
      medicineDateTime: new Date().toISOString(),
    }
    setCurrentMedicine(newMedicine)
  }

  const handleSaveMedicine = (medicine: Medicine) => {
    setFormState((prevState) => {
      const medicineExists = prevState.medicineList.some(
        (item) => item.scheduleMedicineId === medicine.scheduleMedicineId
      )

      const updatedList = medicineExists
        ? prevState.medicineList.map((item) =>
            item.scheduleMedicineId === medicine.scheduleMedicineId
              ? { ...medicine }
              : item
          )
        : [
            ...prevState.medicineList,
            {
              ...medicine,
              scheduleMedicineId: prevState.medicineList.length +1,
            },
          ]

      return {
        ...prevState,
        medicineList: updatedList,
      }
    })
  }

  const handleAddMedicine = () => {
    createNewMedicine()
  }
  const handleEditMedicine = (medicine: Medicine) => {
    setCurrentMedicine((prev) => {
      return { ...medicine }
    })
  }

  //follow section

  const getPracticeTypes = () => {
    setLoading(true)
    AppointmentApiInvoker.getPracticeTypes(
      (practices: any) => {
        if (practices?.length) {
          setPracticeTypes(practices)
        }
        setLoading(false)
      },
      (error: any) => {
        setLoading(false)
        handleError('Error en Servicio Prácticas')
        console.error('** error getPracticeTypes', error)
      }
    )
  }

  //save action
  async function postClinicalHistory(scheduleId: string | number, body: any) {
    try {
      setLoading(true)
      const response = await new Promise((resolve, reject) => {
        HistoriaClinicaApi.postClinicalHistory(
          scheduleId,
          body,
          (data: any) => resolve(data),
          (error: any) => reject(error)
        )
      })
      return response
    } catch (error) {
      handleError(
        'Error al guardar la información, por favor vuelva a intenetar en unos minutos'
      )
      throw error
    } finally {
      setLoading(false)
    }
  }

  const formatedMedicinesToSend = () => {
    const formattedMedicineList = formState.medicineList.map(
      (medicine: Medicine) => {
        return {
          ...medicine,
          medicineTypeId: medicine.medicineType?.medicineTypeId ?? null,
          medicineUnitTypeId:
            medicine.medicineUnitType?.medicineUnitTypeId ?? null,
          medicinePresentationTypeId:
            medicine.medicinePresentationType?.medicinePresentationTypeId ??
            null,
          medicineQuantity: medicine.medicineQuantity ?? null,
          medicineDose: medicine.medicineDose ?? null,
          medicineViaTypeId:
            medicine.medicineViaType?.medicineViaTypeId ?? null,
          medicineLotNumber: medicine.medicineLotNumber,
          medicineExpireDate: medicine.medicineExpireDate
            ? format(new Date(medicine.medicineExpireDate), 'yyyy-MM-dd')
            : undefined,
          medicineDateTime: medicine.medicineDateTime
            ? format(
                new Date(medicine.medicineDateTime),
                "yyyy-MM-dd'T'HH:mm:ss"
              )
            : undefined,
        }
      }
    )
    return formattedMedicineList
  }

  const buildBodyToSend = () => {
    const formattedMedicineList = formatedMedicinesToSend()
    const body = {
      glycemiaId:
        formState.patientClinicalHistory.glycemiaId?.laboratoryResultsId ??
        null,
      glycosylatedHemoglobinId:
        formState.patientClinicalHistory.glycosylatedHemoglobinId
          ?.laboratoryResultsId ?? null,
      cholesterolId:
        formState.patientClinicalHistory.cholesterolId?.laboratoryResultsId ??
        null,
      triglyceridesId:
        formState.patientClinicalHistory.triglyceridesId?.laboratoryResultsId ??
        null,
      treatment: formState.patientClinicalHistory.treatment ?? null,
      diagnosticId:
        formState.patientClinicalHistory?.diagnosticId?.recordId ?? null,
      requestedStudies:
        formState.patientClinicalHistory.requestedStudies ?? null,
      nextConsultationDate: formState?.patientClinicalHistory
        ?.nextConsultationDate
        ? format(
            new Date(formState.patientClinicalHistory.nextConsultationDate),
            'yyyy-MM-dd'
          )
        : undefined,
      dischargeDate: formState?.patientClinicalHistory?.dischargeDate
        ? format(
            new Date(formState.patientClinicalHistory.dischargeDate),
            'yyyy-MM-dd'
          )
        : undefined,
      specialty1:
        formState.patientClinicalHistory.specialty1?.practiceType ?? null,
      specialty2:
        formState.patientClinicalHistory.specialty2?.practiceType ?? null,
      specialty3:
        formState.patientClinicalHistory.specialty3?.practiceType ?? null,
      others: formState.patientClinicalHistory.others ?? null,
      observations: formState.patientClinicalHistory.observations ?? null,
      weightUnitPatient: formState.patientClinicalHistory.weightUnit ?? null,
      valueWeightPatient: formState.patientClinicalHistory.weightValue ?? null,
      valueHeightPatient: formState.patientClinicalHistory.heightValue ?? null,
      medicineList: formattedMedicineList || [],
      pulse: formState.patientClinicalHistory.pulse ?? null,
      bloodPressureMax:
        formState.patientClinicalHistory.bloodPressureMax ?? null,
      bloodPressureMin:
        formState.patientClinicalHistory.bloodPressureMin ?? null,
    }
    return body
  }

  const handleSubmit = async () => {
    const body = buildBodyToSend()
    const response = await postClinicalHistory(scheduleIdToUse || 0, body)
    if (response) {
      setCurrentStep(1)
      setShowModal(false)
      openSnackbar(SnackbarColor.success, 'HC creada con éxito')
      if (typeof props?.closeModal === 'function') {
        setTimeout(() => {
          props.closeModal()
        }, 2000)
      }
    }
  }

  return {
    currentStep,
    formState,
    loading,
    glucemiaOptions,
    trigliceridosOptions,
    hemoglobinaGlicolisadaOptions,
    colesterolOptions,
    pathologyOptions,
    medicineTypes,
    medicineViaTypes,
    medicinePresentationTypes,
    medicineUnitTypes,
    currentMedicine,
    isFormVisible,
    practiceTypes,
    showModal,
    error,
    itemsDisabled,
    snackbar,
    updateFormState,
    getMedicineResources,
    goNextStep,
    goPreviousStep,
    handleSaveOrNext,
    calculateIMC,
    handleFieldChange,
    getLaboratoryOptions,
    getPathologiesData,
    handleMedicineFieldChange,
    createNewMedicine,
    setCurrentMedicine,
    handleAddMedicine,
    handleEditMedicine,
    handleSaveMedicine,
    setIsFormVisible,
    getPracticeTypes,
    setShowModal,
    handleSubmit,
    setError,
    handleCloseError,
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Add, Person } from '@material-ui/icons';
import AssignmentIcon from "@material-ui/icons/Assignment";
import PhoneIcon from '@material-ui/icons/Phone';
import ApiInvoker from '../../api/ApiInvoker';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';
import CustomCheckbox from '../../components/CustomCheckbox';
import FullLoading from '../../components/FullLoading';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Snackbar from '../../components/Snackbar/Snackbar';
import Table from '../../components/Table/Table';
import { Address as AddressIcon,Drivers as DriversIcon, Location as GlobeLocation, TextSnippet} from '../../icons';
import { usePersonDataTypes } from '../../hooks/usePersonDataTypes';
import PersonalInfo from './PersonalInfo';
import { Tooltip } from '@material-ui/core';
import Check from '../../icons/Check';
import Close from '../../icons/Close';
import PersonApiInvoker from '../../api/PersonApiInvoker';
import moment from 'moment';
import EmployeesService from '../../containers/EmployeesService';
import { CardFooter } from '../Card';
import CustomDialog from '../CustomDialog';
import Address from '../Address/address';
import AddressService from '../../containers/AddressService';
import Phone from '../Phone';
import PhoneService from '../../containers/PhoneService';
import DownloadIcon from '../../icons/DownloadIcon';
import DriverApiInvoker from '../../api/DriverApiInvoker';
import DownloadCloudIcon from '../../icons/DownloadCloudIcon';

const showSnackbarInitialValue = {
  open: false,
  color: 'danger',
  message: ''
}

const formSettingsInitialValue = {
  mode: 'view',
  save: false,
}

const Driver = ({ location, params, t }) => {

  const { documentTypes, genderTypes, nationalities } = usePersonDataTypes(t);

  const [formSettings, setFormSettings] = useState(formSettingsInitialValue);
  const [driver, setDriver] = useState({});
  const updateFromHandleRef = useRef(false);
  const [vehicles, setVehicles] = useState([]);
  const [geographicZones, setGeographicZones] = useState([]);
  const [phones, setPhones] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [personalDocuments, setPersonalDocuments] = useState([]);
  const [vehicleDocuments, setVehicleDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mode, setMode]= useState(location?.state?.mode || 'default');
  const [personId, setPersonId] = useState('')
  const [refresh, setRefresh] = useState(true)
  const [isAddress, setIsAddress] = useState(false)
  const [isLoadingAddress, setIsLoadingAddress] = useState(true)
  const [openAddress, setOpenAddress] = useState(false)
  const [address, setAddress] = useState(null)
  const [openPhone, setOpenPhone] = useState(false)
  const [openDetailVehicle, setOpenDetailVehicle] = useState(false)
  const [phone, setPhone] = useState(null)
  const [openEmployeeGeographic,setOpenEmployeeGeographic] = useState(false)
  const [employeeGeographic,setEmployeeGeographic] = useState(null)
  const [showSnackbar, setShowSnackbar] = useState(showSnackbarInitialValue);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [vehicleId, setVehicleId] = useState(0)

  const submitDriver = () => {
   
    setFormSettings((prev) => ({ prev, save: true}));
    if (formSettings.mode === 'edit') {
      updateDriver();
    } else {
      saveDriver();
    }
  }

  useEffect(() => {
   
    const timeoutId = setTimeout(() => {
      setPersonId(params.id);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  const saveDriver = () => {
    const formattedBirthDate = driver.birthdate ? moment(driver.birthdate).format("YYYY-MM-DD") : null;
    
    const formattedDriver = {
      ...driver,
      nationalityId: driver.nationalityId.id,
      birthdate: formattedBirthDate,
    };

    
    PersonApiInvoker.putDriver(formattedDriver, (response) => {
      openSnackbar(t('common.successMessage'), 'success');
      goBack();
    }, (error) => {
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    });
    
  }

  const updateDriver = () => {
    console.log("DATOS", driver)

  }

  const openSnackbar = (message, color = 'danger') => {
    setShowSnackbar({
      color,
      message,
      open: true,
    });

    setTimeout(() => {
      setShowSnackbar(showSnackbarInitialValue);
    }, 2000);
  }

  const formatDriver = (data) => ({
    driverId: params.id,
    firstName: data.firstName,
    lastName: data.lastName,
    nationalityId: data.nationality || null,
    idType: data.idType?.idTypeId || null,
    identification: data.idNumber || null,
    sex: data.sex|| null,
    birthdate: data.birthdate|| null,
    email: data.email|| null,
    approvedDocument:data.approvedDocument,
    approvedLicence: data.approvedLicence,
    approvedSelfie: data.approvedSelfie,
  })

  const getDriver = () => {
    setLoading(true);
    ApiInvoker.getDriver(params.id, (data) => {
     
      const d = formatDriver(data[0]);
      
      setDriver(d);
      setLoading(false);
      return;
    }, (error) => {
      setLoading(false);
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  }
  const formatPersonalDocuments = (data) => {
    const allDocuments = data.flatMap(group => group.documents);
  
    const findDocumentValue = (label) => {
      const doc = allDocuments.find(d => d.label === label);
      return doc ? doc.value : '';
    };

  
    const documents = {
      documento: {
        label: 'Documento',
        valor: 
        {
         frente: findDocumentValue("FRENTE"),
         dorso: findDocumentValue("DORSO")
        },
        status: driver.approvedDocument === 2 ? 'Aceptado': driver.approvedDocument === 1 ? 'Rechazado':  (findDocumentValue("FRENTE") && findDocumentValue("DORSO")) ? 'Cargado' : 'Pendiente'
      },
      selfie: {
        label: 'SELFIE',
        valor: findDocumentValue("SELFIE"),
        status: driver.approvedSelfie === 2 ? 'Aceptado': driver.approvedSelfie === 1 ? 'Rechazado':findDocumentValue("SELFIE") ? 'Cargado' : 'Pendiente'
      },
      licencia: {
        label: 'Licencia de conducir',
        valor: findDocumentValue("Licencia de conducir"),
        status: driver.approvedLicence === 2 ? 'Aceptado': driver.approvedLicence === 1 ? 'Rechazado': findDocumentValue("Licencia de conducir") ? 'Cargado' : 'Pendiente'
      }
    };
    return documents;
  };
  
  const getDriverPersonalDocument = () => {
    setLoading(true);
    DriverApiInvoker.getDriverPersonalDocument(params.id, (data) => {
      
      const formattedDocuments = formatPersonalDocuments(data.documents);

      setPersonalDocuments(formattedDocuments);
      setLoading(false);
    }, (error) => {
      setLoading(false);
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    });
  };

  const getApprovalStatus = (value) => {
    switch(value) {
      case 0:
        return 'Pendiente';
      case 2:
        return 'Aceptado';
      case 1:
        return 'Rechazado';
      default:
        return 'Desconocido';
    }
  }
  const getApprovalStatusInv = (value) => {
    switch(value) {
      case 'Pendiente':
        return 0;
      case 'Aceptado':
        return 2;
      case 'Rechazado':
        return 1;
      default:
        return 3;
    }
  }

  const formatVehicles = (data) => data.map((d) =>({
    brand: d.vehicle.vehicleBrand.name,
    model: d.vehicle.vehicleModel.name,
    type: d.vehicle.carType ? t(`label.cardType.${d.vehicle.carType}`) : t('label.undefined'),
    wheelChairSuitable: d.vehicle.wheelChairSuitable ? t('common.yes') : t('common.no'),
    year: d.vehicle.year,
    patent: d.vehicle.identity || d.vehicle.patent,
    id: d.vehicle.vehicleId,
    property:d.companyVehicle.length !== 0 ? t('label.company') : t('label.own'),
    secureFile:d.vehicle.secureFile,
    targetFile:d.vehicle.targetFile,
    titleFile:d.vehicle.titleFile,
    verificationFile:d.vehicle.verificationFile,
    secureFileStatus: getApprovalStatus(d.vehicle?.aproveedSecureFile),
    targetFileStatus: getApprovalStatus(d.vehicle?.aproveedTargetFile),
    titleFileStatus: getApprovalStatus(d.vehicle?.aproveedTitleFile),
    verificationFileStatus: getApprovalStatus(d.vehicle?.aproveedVerificationFile)
  }))

  const getDriverVehicles = () => {
    DriverApiInvoker.getDriverVehicleByIdDriver(params.id, (data) => {
     
      const v = formatVehicles(data);

      setVehicles(v);
      return;
    }, (error) => {
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  }

  const formatGeographicZones = (data) => {
    const geogZonesList =  data && data.length > 0 ? data.map((a) => ({
      province: a.provinceName,
      city: a.locationName,
      zone: a.geoZoneName,
    })) : [];
    return geogZonesList;
  }

  const getDriverGeoZones = () => {
    PersonApiInvoker.getEmployeeGeographics(params.id, (data) => {
      const gz = formatGeographicZones(data);
      setGeographicZones(gz);
      return;
    }, (error) => {
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  }

  const formatPhones = (data) => { // @TODO: traer data del servicio de address
    const addressList =  data && data.length > 0 ? data.map((a) => ({
      detail: a.detail,
      type: a.phoneType.name,
      number: a.number,
      default: <CustomCheckbox checked={a.phoneDefault} />,
    })) : [];
    return addressList;
  }

  const getDriverPhones= () => {
    ApiInvoker.getDriverPhones(params.id, (data) => {
      const p = formatPhones(data);
      setPhones(p);
      return;
    }, (error) => {
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  }
  const getDriverDocuments = () => {
    if (!personalDocuments) {
      console.error('personalDocuments is not defined');
      return;
    }

    const documents = [
      { type: 'Documento', document: personalDocuments.documento?.valor || '', state: personalDocuments.documento?.status, actions: personalDocuments.documento?.value !== 'Pendiente' ? 'aceptar' : '' },
      { type: 'Foto selfie', document: personalDocuments.selfie?.valor || '', state: personalDocuments.selfie?.status, actions: personalDocuments.selfie?.value !== 'Pendiente' ? 'aceptar' : '' },
      { type: 'Licencia', document: personalDocuments.licencia?.valor || '', state: personalDocuments.licencia?.status, actions: personalDocuments.licencia?.value !== 'Pendiente' ? 'aceptar' : '' }
    ];
  
    setDocuments(documents);
  };

  const getVehicleDocuments = ()=>{
    setVehicleDocuments(
      [
        { type: 'Título del vehículo', name:'titleFile', document: 'Descargar', state: 'Pendiente', actions:'aceptar' },
        { type: 'Seguro del vehículo', name:'secureFile', document: 'Descargar', state: 'Pendiente', actions:'aceptar' },
        { type: 'Verificación técnica', name:'verificationFile', document: 'Descargar', state: 'Pendiente', actions:'aceptar' },
        { type: 'Tarjeta azul', name:'targetFile',  document: 'Descargar', state: 'Pendiente', actions:'aceptar' },
      ]
    )
  }

  const loadData = () => {
    
    setFormSettings((prev) => ({ prev, mode: location && location.state && location.state.mode}));
    getDriver();
    getDriverPersonalDocument();
    getDriverVehicles();
    getDriverGeoZones();
    getDriverPhones();
    getDriverDocuments();
    getVehicleDocuments();

  }

  useEffect(() => {
    loadData();
    if (location.state && location.state.mode) {
      setFormSettings(prev => ({ ...prev, mode: location.state.mode }));
    }
    return () => {
      setLoading(false);
      setDriver({});
      setVehicles([]);
      setGeographicZones([]);
      setPhones([]);
      setLoading([]);
      setPersonId(params.id)
    }
  }, []);
  
  useEffect(() => {
    if (driver && Object.keys(driver).length > 0 && !updateFromHandleRef.current) {
      getDriverPersonalDocument();
    }
  }, [driver]);
  
  useEffect(() => {
    getDriverDocuments();
  }, [personalDocuments]);

  const handleDriverValue = (state, value) => {
    updateFromHandleRef.current = true;
    setDriver((prev) => ({ ...prev, [state]: value }));
    setTimeout(() => {
      updateFromHandleRef.current = false;
    }, 0);
  }

  const goBack = () => browserHistory.push('/drivers');

  const createGeographic = ()=> {
    setOpenEmployeeGeographic(true);
    setEmployeeGeographic(null)
  }

  const createAddress = ()=> {
        setOpenAddress(true);
        setAddress(null)
  }
  const editAddress = (address)=> {

        setOpenAddress(true)
        setAddress(address)
  }

  const filledAddress = (address) =>  {
  
    const { isAddress, isLoadingAddress } = address;
        setIsAddress(isAddress)
        setRefresh(false)
        setIsLoadingAddress(isLoadingAddress)
  }
  const editPhone = (phone)=> {
    setOpenPhone(true); 
    setPhone(phone) ;
  }
  const createPhone = ()=> {
    setOpenPhone(true);
    setPhone(null);
  }

  const actionDocuments = (name, action) => {
    let titleFile = getApprovalStatusInv(selectedVehicle?.titleFileStatus);
    let verificationFile = getApprovalStatusInv(selectedVehicle?.verificationFileStatus);
    let secureFile = getApprovalStatusInv(selectedVehicle?.secureFileStatus);
    let targetFile = getApprovalStatusInv(selectedVehicle?.targetFileStatus);

    switch (name) {
      case 'titleFile':
        titleFile = action;
        break;
      case 'verificationFile':
        verificationFile = action;
        break;
      case 'secureFile':
        secureFile = action;
        break;
      case 'targetFile':
        targetFile = action;
        break;
      default:
        console.warn('Nombre de documento no válido:', name);
    }
  
    
    DriverApiInvoker.updateStatusDocumentoToVehicle(selectedVehicle.id, titleFile, secureFile, verificationFile, targetFile,{}, (data) => {

      openDetailDocumentsVehicle(vehicleId, true)
      getDriverVehicles()
      return;
    }, (error) => {
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  };



  const openDetailDocumentsVehicle = async (vehicle, modal) => {
    setVehicleId(vehicle);

    const selectedVehicle = vehicles.find(v => v.id === vehicle);

    if (selectedVehicle) {
        try {
            const data = await new Promise((resolve, reject) => {
                DriverApiInvoker.getDocuments(selectedVehicle.id, resolve, reject);
            });

            const updatedVehicle = {
                ...selectedVehicle,
                secureFileStatus: getApprovalStatusInv(selectedVehicle.secureFileStatus) === data.aproveedSecureFile 
                    ? selectedVehicle.secureFileStatus 
                    : getApprovalStatus(data.aproveedSecureFile),
                targetFileStatus: getApprovalStatusInv(selectedVehicle.targetFileStatus) === data.aproveedTargetFile 
                    ? selectedVehicle.targetFileStatus 
                    : getApprovalStatus(data.aproveedTargetFile),
                titleFileStatus: getApprovalStatusInv(selectedVehicle.titleFileStatus) === data.aproveedTitleFile 
                    ? selectedVehicle.titleFileStatus 
                    : getApprovalStatus(data.aproveedTitleFile),
                verificationFileStatus: getApprovalStatusInv(selectedVehicle.verificationFileStatus) === data.aproveedVerificationFile 
                    ? selectedVehicle.verificationFileStatus 
                    : getApprovalStatus(data.aproveedVerificationFile),
                secureFile: data.secureFile,
                targetFile: data.targetFile,
                titleFile: data.titleFile,
                verificationFile: data.verificationFile,
            };

            setSelectedVehicle(updatedVehicle);

            setVehicleDocuments([
                {
                    type: 'Título del vehículo',
                    name: 'titleFile',
                    document: 'Descargar',
                    property: selectedVehicle.property,
                    ...(selectedVehicle.property !== "Compañía" && { state: updatedVehicle.titleFileStatus })
                },
                {
                    type: 'Seguro del vehículo',
                    name: 'secureFile',
                    document: 'Descargar',
                    property: selectedVehicle.property,
                    ...(selectedVehicle.property !== "Compañía" && { state: updatedVehicle.secureFileStatus })
                },
                {
                    type: 'Verificación técnica',
                    name: 'verificationFile',
                    document: 'Descargar',
                    property: selectedVehicle.property,
                    ...(selectedVehicle.property !== "Compañía" && { state: updatedVehicle.verificationFileStatus })
                },
                {
                    type: 'Tarjeta azul',
                    name: 'targetFile',
                    document: 'Descargar',
                    property: selectedVehicle.property,
                    ...(selectedVehicle.property !== "Compañía" && { state: updatedVehicle.targetFileStatus })
                }
            ]);

            if (!modal) {
                setOpenDetailVehicle(true);
            }
        } catch (error) {
            openSnackbar(t('common.errorMessage'));
            console.error('** error', error);
        }
    }
};

  const actionPersonalDocuments = (type,value)=>{


    const body={
      approvedDocument:type === "Documento" ? value : driver.approvedDocument,
      approvedSelfie:type === "Foto selfie" ? value :driver.approvedSelfie,
      approvedLicence:type === "Licencia" ? value :driver.approvedLicence,
    }
    DriverApiInvoker.putDocumentsDriver( params.id,body,(data) => {
      getDriver()
      getDriverDocuments()
      return;
    }, (error) => {
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  }


  return (
    <>
      <Snackbar
        open={showSnackbar.open}
        color={showSnackbar.color}
        message={showSnackbar.message}
        place="tr"
      ></Snackbar>
      {loading ?
        <FullLoading /> : 
        <GridContainer className="edit-customer employee">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader icon>
                <CardIcon color="primary">
                  <Person />
                </CardIcon>
                <h3 className="card-icon-title">{t('employee.new.profileData')} </h3>
              </CardHeader>
              <CardBody className="edit-customer-form">
                <PersonalInfo
                  data={{
                    documentTypes, driver, nationalities, genderTypes,
                    mode: formSettings.mode, save: true,
                  }}
                  handleDriverValue={handleDriverValue}
                  saveDriver={submitDriver}
                />
              </CardBody>
            </Card>
          </GridItem>
          {/* DOCUMENTS */}
        <GridItem xs={12} className="specialities-practices-view">
          <Card className="employee-phones">
            <CardHeader icon>
                <CardIcon color="secondary">
                    <TextSnippet  />
                </CardIcon>
                <h3 className="card-icon-title">{t('personal.document')}</h3>
            </CardHeader>
            <CardBody>
                <Table
                  striped
                  filterable
                  tableHeaderColor="primary"
                  tableHead={[
                    { Header: t('address.type'), accessor: 'type' },
                    {
                      Header: t('driver.document'),
                      accessor: 'document',
                      Cell: row => (
                        row.value ? (
                          row.original.type === 'Documento' ?
                          (
                            <>
                            <a href={row.value.frente} title={t('view.front')}
                              style={{ color: !row.value.frente ? 'grey': '#5DC4B9', marginRight:'5px', cursor: !row.value.frente ? 'not-allowed':'pointer' }} 
                              target="_blank" 
                              rel="noopener noreferrer" 
                              download>
                              <DownloadCloudIcon/>
                            </a>
                            <a href={row.value.dorso} 
                              title={t('view.back')}
                              style={{ color: !row.value.frente ? 'grey': '#5DC4B9', cursor: !row.value.frente ? 'not-allowed':'pointer'  }} 
                              target="_blank" 
                              rel="noopener noreferrer" 
                              download>
                              <DownloadCloudIcon/>
                            </a>
                          </>
                          ) :(
                          <a href={row.value} style={{ color: '#5DC4B9' }} target="_blank" rel="noopener noreferrer" download>
                            <DownloadCloudIcon/>
                          </a>)
                        ) : (
                          <span style={{ color: 'gray', cursor: 'not-allowed' }}>
                            <DownloadCloudIcon/>
                          </span>
                        )
                      )
                    },
                    { Header: t('status'), accessor: 'state' },
                    { Header: t('actions'), accessor: 'actions', Cell: row => {
                      return(
                      <div className="appointments-actions">
                        {row.original.state !=="Pendiente" && row.original.state !=="Aceptado" && row.original.state !=="Rechazado" && (
                          <>
                            <Tooltip title={t('common.accept')}>
                              <span>
                                <Button
                                  simple
                                  justIcon
                                  color="success"
                                  onClick={()=>actionPersonalDocuments(row.original.type,2)}
                                  id="aceptar"
                                >
                                  <Check />
                                </Button>
                              </span>
                            </Tooltip>
                            <Tooltip title={t('common.reject')}>
                              <span>
                                <Button
                                  simple
                                  justIcon
                                  color="danger"
                                  onClick={()=>actionPersonalDocuments(row.original.type,1)}
                                  id="rechazar"
                                >
                                  <Close />
                                </Button>
                              </span>
                            </Tooltip>
                          </>
                        )}
                      </div>
                    )}},
                  ]}
                  tableData={documents}
                  defaultPageSize={10}
                  colorsColls={['primary']}
                />
            </CardBody>
          </Card>
        </GridItem>

          {/* VEHICLES */}
          <GridItem xs={12} className="specialities-practices-view">
            <Card className="employee-geographic-zones">
              <CardHeader icon>
                <CardIcon color="secondary">
                  <DriversIcon />
                </CardIcon>
                <h3 className="card-icon-title">{t('employee.new.vehicles')}</h3>
              </CardHeader>
              <CardBody>
                  <Table
                      striped
                      filterable
                      tableHeaderColor="primary"
                      defaultPageSize={10}
                      tableHead={[
                          { Header: t('employees.table.marca'), accessor: 'brand' },
                          { Header: t('employees.table.model'), accessor: 'model' },
                          { Header: t('employees.table.type'), accessor: 'type' },
                          { Header: t('employees.table.apto'), accessor: 'wheelChairSuitable' },
                          { Header: t('employees.table.year'), accessor: 'year' },
                          { Header: t('employees.table.vehicleidentification'), accessor: 'patent' },
                          { Header: t('employees.table.property'), accessor: 'property' },
                          { Header: t('actions'), accessor: 'actions', Cell: row => (
                            <div className="appointments-actions">
                              
                              <Tooltip title={t('common.view')}>
                                <span>
                                  <Button
                                    simple
                                    justIcon
                                    color="primary"
                                    onClick={() => openDetailDocumentsVehicle(row.original.id)}
                                    id="view"
                                  ><AssignmentIcon />
                                  </Button>
                                </span>
                              </Tooltip>
                            </div>
                          ) },
                      ]}
                      tableData={vehicles}
                      colorsColls={['primary']}
                  />
              </CardBody>
            </Card>
          </GridItem>

          {/* GEO-ZONES */}
          <GridItem xs={12} className="specialities-practices-view">
          <Card className="employee-geographic-zones">
            <CardHeader icon>
                <CardIcon color="primary">
                    <GlobeLocation />
                </CardIcon>
                <h3 className="card-icon-title">{t('employee.new.GeographicsZones')}</h3>
            </CardHeader>
            <CardBody>
                <EmployeesService.EmployeesDriverGeographicForm componenMode={mode} personId={personId} refresh={refresh}  />
            </CardBody>
            {(mode !== 'view') &&
                <CardFooter className="content-button-add flex-end">
                    <Button id="button-add-zone" className="edit-customer-create" color="primary" onClick={() => createGeographic()}>
                        <Add />
                    </Button>
                </CardFooter>
            }
        </Card>
        </GridItem>

        

        {/* ADDRESS */}
        <GridItem xs={12} className="specialities-practices-view">
            <Card className="employee-addresses">
                <CardHeader icon>
                    <CardIcon color="secondary">
                        <AddressIcon />
                    </CardIcon>
                    <h3 className="card-icon-title">{t('employee.new.Addresses')}</h3>
                </CardHeader>
                <CardBody>
                    <Address
                        componenMode={mode}
                        personId={personId}
                        refresh={refresh}
                        onEditAddress={(address) => editAddress(address)}
                        onFilledAddress={(address) => filledAddress(address)}
                    />
                </CardBody>
                {(mode !== 'view') &&
                    <CardFooter className="content-button-add flex-end">
                        <Button id="button-add-address" className="edit-customer-create" color="primary" disabled={isAddress || isLoadingAddress} onClick={() => createAddress()}>
                            <Add /> {t('employee.new.Address')}
                        </Button>
                    </CardFooter>
                }
            </Card>
        </GridItem>
        

        {/* PHONES */}
        <GridItem xs={12} className="specialities-practices-view">
            <Card className="employee-phones">
                <CardHeader icon>
                    <CardIcon color="primary">
                        <PhoneIcon />
                    </CardIcon>
                    <h3 className="card-icon-title">{t('employee.new.Phones')}</h3>
                </CardHeader>
                <CardBody>
                    <Phone
                        componenMode={mode}
                        personId={personId}
                        refresh={refresh}
                        onEditPhone={(phone) => editPhone(phone)}
                        personType="employee"
                    />
                </CardBody>
                {(mode !== 'view') &&
                    <CardFooter className="content-button-add flex-end">
                        <Button id="button-add-phone" className="edit-customer-create" color="primary" onClick={() => createPhone()}>
                            <Add /> {t('employee.new.Phone')}
                        </Button>
                    </CardFooter>
                }
            </Card>

        </GridItem>

            
        

          <GridItem className="actions-buttons text-center">
            <Button onClick={goBack}>
              {t('appointment.new.back')}
            </Button>
          </GridItem>
          {personId &&
                <CustomDialog
                    title={t('employee.new.GeographicsZones')}
                    open={openEmployeeGeographic}
                    onClose={() => {
                        setEmployeeGeographic(null);
                        setOpenEmployeeGeographic(false);
                      }}
                >
                    <EmployeesService.EmployeesGeographicModal  personId={personId} edit={employeeGeographic} type="driver" 
                        onGeographicSubmited={() => {setEmployeeGeographic(null);
                            setOpenEmployeeGeographic(false); 
                            setRefresh(true) 
                        }}/>
                </CustomDialog>
            }
            {personId &&
                <CustomDialog
                    title={t('employee.new.modal.address')}
                    open={openAddress}
                    onClose={() => {
                        setAddress(null); 
                        setOpenAddress(false);
                    } }
                >
                    <AddressService edit={address} personId={personId} 
                        onAddressSubmited={() => {
                            setAddress(null); 
                            setOpenAddress(false); 
                            setRefresh(true) 
                        }} />
                </CustomDialog>
            }
            {personId &&
                <CustomDialog
                    title={t('employee.new.modal.phone')}
                    open={openPhone}
                    onClose={() => {
                        setPhone(null); 
                        setOpenPhone(false);
                    } }
                >
                    <PhoneService
                        edit={phone}
                        personId={personId}
                        onPhoneSubmited={() => {
                            setPhone(null); 
                            setOpenPhone(false); 
                            setRefresh(true) 
                        }}
                        personType={'employee'}
                    />
                </CustomDialog>
            }

                  <CustomDialog
                    title={t('driver.view.modal.vehicleDocuments')}
                    open={openDetailVehicle}
                    onClose={() => {
                        //setPhone(null); 
                        setOpenDetailVehicle(false);
                    } }
                  >
                     <Table
                    striped
                    filterable
                    tableHeaderColor="primary"
                    tableHead={[
                      { Header: 'Tipo', accessor: 'type' },
                      {
                        Header: 'Documento',
                        accessor: 'document',
                        Cell: row => {
                          const downloadUrl = selectedVehicle[row?.original?.name];
                          const isDisabled = !downloadUrl;
                          const fileName = typeof downloadUrl === 'string' ? downloadUrl.split('/').pop() : '';
                          return (
                            <div className="appointments-actions">
                              <Tooltip title={isDisabled ? t('common.download.notAvailable') : t('common.download')}>
                                <span>
                                  <a
                                    href={downloadUrl}
                                    download={fileName}
                                    style={{ textDecoration: 'none' }}
                                  >
                                    <Button
                                      simple
                                      justIcon
                                      color="primary"
                                      id="download"
                                      disabled={isDisabled}
                                    >
                                      <DownloadIcon />
                                    </Button>
                                  </a>
                                </span>
                              </Tooltip>
                            </div>
                          );
                        }
                      },
                      ...(
                        selectedVehicle && selectedVehicle?.property !== "Compañía" ? 
                        [{ Header: 'Estado', accessor: 'state' }] : 
                        []
                      ),
                      { Header: 'Acciones', accessor: 'actions', 
                        Cell: row => {
                          const downloadUrl = selectedVehicle[row?.original?.name];
                          const isDisabled = downloadUrl;
                          return (
                          <div className="appointments-actions">
                            {isDisabled && row.original.state === "Pendiente" && row.original.property !== "Compañía" ? 
                            <>
                            <Tooltip title={t('common.accept')}>
                            <span>
                            <Button
                              simple
                              justIcon
                              color="success"
                              onClick={()=>actionDocuments(row?.original?.name,2)}
                              id="aceptar"
                            ><Check />
                            </Button>
                            </span>
                            </Tooltip>
                            <Tooltip title={t('common.reject')}>
                            <span>
                            <Button
                              simple
                              justIcon
                              color="danger"
                              onClick={()=>actionDocuments(row?.original?.name,1)}
                              id="rechazar"
                            ><Close />
                            </Button>
                            </span>
                            </Tooltip>
                            </>
                            : <></>
                            }
                            
                          </div>
                      ) }},
                  ]}
                  tableData={vehicleDocuments}
                  defaultPageSize={10}
                  colorsColls={['primary']}
                />
                    
                </CustomDialog>
        </GridContainer>
      }
    </>
  )
}

Driver.propTypes = {
  onGetEmployee: PropTypes.object,
  location: PropTypes.object, 
  params: PropTypes.object,   
  t: PropTypes.func, 
}

export default withTranslation()(Driver);
